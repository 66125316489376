import React from 'react';
import cn from 'classnames';

import { Header } from 'components/Header';

import styles from './BeforeStartLayout.styles.scss';

const BeforeStartLayout = ({ className, children }) => (
  <div className={cn(styles.layout, className)}>
    <Header withLogo className={styles.header} />
    <div className={styles.inner}>{children}</div>
  </div>
);

export default React.memo(BeforeStartLayout);
