import * as Yup from 'yup';

export const initialValues = {
  rating: 0,
  tags: [],
};

export const validationSchema = Yup.object().shape({
  rating: Yup.number().min(1, 'Rating must be greater than 0'),
});
