import React from 'react';
import cn from 'classnames';

import { ROUTES } from 'constants/routes';

import { SOCIAL_ICONS } from './Footer.constants';

import LogoIcon from 'assets/img/logo.svg';

import { Icon } from 'components/shared/Icon';
import { Link } from 'components/shared/Link';
import { Typography } from 'components/shared/Typography';

import styles from './Footer.styles.scss';

const Footer = ({ className }) => (
  <div className={cn(styles.footer, className)}>
    <div className={styles.inner}>
      <div className={styles.company}>
        <LogoIcon className={styles.logo} />
        <Typography className={styles.text}>Befront, Inc.</Typography>
      </div>
      <div className={styles.info}>
        <div className={styles.links}>
          <Link className={styles.link} to={ROUTES.TERMS_PAGE}>
            Terms of Use
          </Link>
          <Link className={styles.link} to={ROUTES.PRIVACY_POLICY_PAGE}>
            Privacy Policy
          </Link>
          <Link className={styles.link} to={ROUTES.PRICING_PAGE}>
            Pricing
          </Link>
        </div>
        <div className={styles.social}>
          <Typography
            className={styles.email}
            component="a"
            target="_blank"
            href="mailto:info@befront.io"
          >
            info@befront.io
          </Typography>
          <div className={styles.icons}>
            {SOCIAL_ICONS.map((socialIcon) => (
              <div
                className={styles.iconWrapper}
                onClick={() => {
                  window.open(socialIcon.link, '_blank');
                }}
              >
                <Icon name={socialIcon.icon} className={styles.socialIcon} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default React.memo(Footer);
