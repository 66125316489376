import React, { useEffect } from 'react';

import { logEvent } from 'helpers/logEvent';

import SetupAccount from './SetupAccount';

const SetupAccountContainer = ({ open, onClose }) => {
  useEffect(() => {
    if (open) {
      logEvent({ eventType: 'Landing_Page - Setup_Profile_Viewed' });
    }
  }, [open]);

  return <SetupAccount open={open} onClose={onClose} />;
};

export default React.memo(SetupAccountContainer);
