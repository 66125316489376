import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from 'api/methods';

import * as types from './types';

export const getLessonById = createAsyncThunk(
  types.GET_LESSON_BY_ID,
  async ({ courseId, lessonId }) => {
    const lesson = await api.getCourseLessonById({ courseId, lessonId });

    return lesson;
  },
);

export const completeLesson = createAsyncThunk(
  types.COMPLETE_LESSON,
  api.completeCourseLesson,
);
