import React, { useEffect } from 'react';

import { logEvent } from 'helpers/logEvent';

import { useAuthModal } from 'context/authModals';

import { useResponsive } from 'hooks/useResponsive';

import HomePage from './HomePage';

const HomePageContainer = () => {
  const { dispatch } = useAuthModal();

  useResponsive();

  useEffect(() => {
    logEvent({ eventType: 'Landing_Page - Viewed' });
  }, []);

  const handleStartClick = () => {
    logEvent({ eventType: 'SignUp_Clicked' });

    dispatch({
      type: 'OPEN_MODAL',
      payload: { currentModal: 'SIGNUP_MODAL', currentModalData: null },
    });
  };

  return (
    <>
      <HomePage onStartClick={handleStartClick} />
    </>
  );
};

export default React.memo(HomePageContainer);
