import _last from 'lodash/last';
import _flattenDeep from 'lodash/flattenDeep';
import _flatten from 'lodash/flatten';

import { COURSE_STRUCTURES } from 'constants/common';

const _getCoursePreviousLessonWithModules = ({ course, lessonId }) => {
  const lessons = _flattenDeep(course.modules.map((module) => module.lessons));

  const currentModule = course.modules.find((module) =>
    module.lessons.some((lesson) => lesson.id === lessonId),
  );

  const currentLesson = lessons.find((lesson) => lesson.id === lessonId);

  if (currentLesson.order > 1) {
    return currentModule.lessons[currentLesson.order - 2];
  }

  if (currentLesson.order === 1) {
    const prevModule = course.modules[currentModule.order - 2];

    if (!prevModule) {
      return null;
    }

    const nextLesson = prevModule.lessons[prevModule.lessons.length - 1];

    return nextLesson;
  }

  return null;
};

const _getCoursePreviousLesson = ({ course, lessonId }) => {
  const lessons = course.lessons;

  const currentLesson = lessons.find((lesson) => lesson.id === lessonId);

  if (currentLesson.order > 1) {
    return lessons[currentLesson.order - 2];
  }

  return null;
};

export const getCoursePreviousLesson = ({ course, lessonId }) => {
  if (course.structure === COURSE_STRUCTURES.MODULES) {
    return _getCoursePreviousLessonWithModules({ course, lessonId });
  } else {
    return _getCoursePreviousLesson({ course, lessonId });
  }
};

export const checkPrevDisabled = ({ course, currentLesson }) => {
  if (course.structure === COURSE_STRUCTURES.MODULES) {
    const currentModule = course.modules.find((module) =>
      module.lessons.some((lesson) => lesson.id === currentLesson.id),
    );

    return currentModule.order === 1 && currentLesson.order === 1;
  }

  if (course.structure === COURSE_STRUCTURES.LESSONS) {
    return currentLesson.order === 1;
  }
};

export const checkNextDisabled = ({ course, currentLesson }) => {
  if (course.structure === COURSE_STRUCTURES.MODULES) {
    const currentModule = course.modules.find((module) =>
      module.lessons.some((lesson) => lesson.id === currentLesson.id),
    );
    const lastModule = _last(course.modules);

    const currentModuleLessonsCount = currentModule.lessons.length;

    return (
      currentLesson.order === currentModuleLessonsCount &&
      currentModule.order === lastModule.order
    );
  }

  if (course.structure === COURSE_STRUCTURES.LESSONS) {
    const lastLesson = _last(course.lessons);

    return currentLesson.order === lastLesson.order;
  }
};

export const getLessonOrder = ({ course, currentLessonId }) => {
  let lessons = [];

  if (course.structure === COURSE_STRUCTURES.MODULES) {
    lessons = _flatten(course.modules.map((module) => module.lessons)).map(
      (lesson, lessonIndex) => ({ ...lesson, order: lessonIndex + 1 }),
    );
  }

  if (course.structure === COURSE_STRUCTURES.LESSONS) {
    lessons = course.lessons;
  }

  const currentLesson = lessons.find((lesson) => lesson.id === currentLessonId);

  return currentLesson.order;
};
