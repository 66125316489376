import React from 'react';
import AvatarEditor from 'react-avatar-editor';

import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import { RangeSlider } from '../RangeSlider';

import {
  MAX_CROP_ZOOM,
  MIN_CROP_ZOOM,
  CROP_ZOOM_STEP,
} from './AvatarCrop.constants';

import styles from './AvatarCrop.styles.scss';

const AvatarCrop = ({
  open,
  loading,
  preview,
  cropperRef,
  currentZoom,
  onZoomChange,
  onClose,
  onSaveClick,
}) => (
  <Modal className={styles.modal} open={open} centered onClose={onClose}>
    <Typography className={styles.title} variant="subtitle" weight="semiBold">
      Edit your profile photo
    </Typography>
    <div className={styles.inner}>
      <AvatarEditor
        ref={cropperRef}
        image={preview}
        width={272}
        height={272}
        border={0}
        color={[25, 25, 56, 0.8]}
        scale={currentZoom}
        rotate={0}
        borderRadius={136}
        className={styles.cropper}
      />

      <RangeSlider
        className={styles.slider}
        value={currentZoom}
        minValue={MIN_CROP_ZOOM}
        maxValue={MAX_CROP_ZOOM}
        step={CROP_ZOOM_STEP}
        onChange={onZoomChange}
      />
    </div>
    <div className={styles.actions}>
      <Button
        className={styles.action}
        size="md"
        variant="outlined"
        onClick={onClose}
      >
        Cancel
      </Button>
      <Button
        className={styles.action}
        size="md"
        startIcon={loading && <Loading color="var(--color-white-primary)" />}
        onClick={onSaveClick}
      >
        Save
      </Button>
    </div>
  </Modal>
);

export default React.memo(AvatarCrop);
