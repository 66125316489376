import { createAsyncThunk } from '@reduxjs/toolkit';
import _orderBy from 'lodash/orderBy';

import { COURSE_STRUCTURES } from 'constants/common';

import * as api from 'api/methods';

import * as types from './types';

const _getCourseModules = async (courseId) => {
  const promises = [
    api.getCourseModules(courseId),
    api.getCourseLessons(courseId),
  ];

  const [modules, lessons] = await Promise.all(promises);

  return _orderBy(modules, ['order'], ['asc']).map((module) => ({
    ...module,
    lessons: lessons
      .filter((lesson) => lesson.moduleId === module.id)
      .map((lesson, lessonIndex) => ({
        ...lesson,
        order: lessonIndex + 1,
      })),
  }));
};

export const getCourseById = createAsyncThunk(
  types.GET_COURSE_BY_ID,
  async (courseId) => {
    const course = await api.getCourseById(courseId);

    if (course.structure === COURSE_STRUCTURES.MODULES) {
      const modules = await _getCourseModules(courseId);

      return {
        ...course,
        modules: modules.filter((module) => module.lessons.length > 0),
      };
    }

    if (course.structure === COURSE_STRUCTURES.LESSONS) {
      const lessons = await api.getCourseLessons(courseId);

      return {
        ...course,
        lessons: lessons.map((lesson, lessonIndex) => ({
          ...lesson,
          order: lessonIndex + 1,
        })),
      };
    }

    return course;
  },
);

export const startCourseById = createAsyncThunk(
  types.START_COURSE_BY_ID,
  async (courseId) => {
    const course = await api.startCourseById(courseId);

    return course;
  },
);
