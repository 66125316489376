import _orderBy from 'lodash/orderBy';
import parseISO from 'date-fns/parseISO';

export const orderCourses = (courses) =>
  _orderBy(
    courses.map((course) => ({
      ...course,
      lastLessonCompletedAt: course.lastLessonCompletedAt
        ? parseISO(course.lastLessonCompletedAt)
        : '',
    })),
    ['completed', 'lastLessonCompletedAt'],
    ['asc', 'desc'],
  );
