import React from 'react';
import { Formik, Form } from 'formik';

import SuccessIcon from 'assets/img/success.svg';

import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';
import { Modal } from 'components/shared/Modal';
import { Loading } from 'components/shared/Loading';

import { FormikField } from 'components/FormikField';
import { InputField } from 'components/FormikFields';

import { initialValues, validationSchema } from './SubmitIdea.formConfig';

import styles from './SubmitIdea.styles.scss';

const SubmitIdea = ({ open, loading, currentStep, onClose, onSendClick }) => (
  <Modal className={styles.modal} open={open} onClose={onClose}>
    {currentStep === 1 && (
      <>
        <Typography className={styles.title} variant="subtitle">
          Add review
        </Typography>
        <Formik
          validateOnMount
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSendClick}
        >
          <Form className={styles.form}>
            <FormikField
              name="text"
              component={InputField}
              controlProps={{
                required: true,
                label: 'Message',
              }}
              componentProps={{
                multiline: true,
              }}
            />
            <div className={styles.actions}>
              <Button
                className={styles.action}
                size="md"
                variant="outlined"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                className={styles.action}
                size="md"
                type="submit"
                startIcon={loading && <Loading />}
              >
                Add review
              </Button>
            </div>
          </Form>
        </Formik>
      </>
    )}
    {currentStep === 2 && (
      <div className={styles.success}>
        <SuccessIcon className={styles.successIcon} />
        <Typography className={styles.successTitle} variant="heading2">
          Your review has been sent
        </Typography>
        <Typography className={styles.successText} variant="body1">
          Thank you for making our product better. If necessary, we will contact
          you
        </Typography>
      </div>
    )}
  </Modal>
);

export default React.memo(SubmitIdea);
