import React from 'react';

import { COURSE_STRUCTURES } from 'constants/common';

import { Loading } from 'components/shared/Loading';

import { MainLayout } from 'components/Layout';

import { MainSection } from './components/MainSection';
import { PortfolioSection } from './components/PortfolioSection';
import { ModulesSection } from './components/ModulesSection';
import { UnlockLessonsSection } from './components/UnlockLessonsSection';
import { PopularCoursesSection } from './components/PopularCoursesSection';
import { Lessons } from './components/Lessons';

import styles from './CoursePage.styles.scss';

const CoursePage = ({
  loading,
  startLoading,
  isProSubscription,
  course,
  onStartClick,
  onContinueClick,
}) => (
  <MainLayout>
    {loading ? (
      <Loading className={styles.loading} />
    ) : (
      <div className={styles.page}>
        <div className={styles.inner}>
          <div className={styles.main}>
            <MainSection
              {...course}
              isProSubscription={isProSubscription}
              startLoading={startLoading}
              onStartClick={onStartClick}
              onContinueClick={onContinueClick}
            />
            {!isProSubscription && (
              <UnlockLessonsSection className={styles.unlockSection} />
            )}
            {course.designPreview && (
              <PortfolioSection preview={course.designPreview} />
            )}
          </div>
          {course.structure === COURSE_STRUCTURES.MODULES && (
            <ModulesSection
              courseAvailable={course.available}
              modules={course.modules}
              completedLessons={course.completedLessons}
              completedModules={course.completedModules}
              courseType={course.type}
            />
          )}
          {course.structure === COURSE_STRUCTURES.LESSONS && (
            <Lessons
              courseAvailable={course.available}
              lessons={course.lessons}
              completedLessons={course.completedLessons}
              courseId={course.id}
              courseTitle={course.title}
              courseType={course.type}
            />
          )}
        </div>
        <PopularCoursesSection />
      </div>
    )}
  </MainLayout>
);

export default React.memo(CoursePage);
