import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { AUTH_CODE_LENGTH } from 'constants/common';
import { TOKEN_KEY } from 'constants/token';

import * as api from 'api/methods';

import { useTimer } from 'hooks/useTimer';

import EnterCodeForm from './EnterCodeForm';
import { setCurrentUser } from '../../store/auth/actions';

const EnterCodeFormContainer = ({
  currentEmail,
  onRegistered,
  onCloseModal,
  ...restProps
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const [timerSecondsLeft, { startTimer }] = useTimer({ defaultSeconds: 0 });

  const [codeError, setCodeError] = useState('');
  const [resetTrigger, setResetTrigger] = useState(new Date());

  const handleCodeChange = async (code) => {
    try {
      if (code.length === AUTH_CODE_LENGTH) {
        const { user, token } = await api.verifyCode({
          email: currentEmail,
          code,
        });

        localStorage.setItem(TOKEN_KEY, token);

        const userActivity = await api.getUserActivity();

        dispatch(setCurrentUser({ user, userActivity }));

        onCloseModal();

        if (location.pathname === '/') {
          navigate('/dashboard');
        }

        if (onRegistered) {
          onRegistered();
        }
      }
    } catch (error) {
      setCodeError(error.message);

      setTimeout(() => {
        setCodeError('');
        setResetTrigger(new Date());
      }, 1500);
    }
  };

  const handleResendCodeClick = async (email) => {
    if (timerSecondsLeft > 0) {
      return;
    }

    startTimer(30);

    await api.resendCode({ email: currentEmail });
  };

  return (
    <EnterCodeForm
      {...restProps}
      codeError={codeError}
      resetTrigger={resetTrigger}
      timerSecondsLeft={timerSecondsLeft}
      onCodeChange={handleCodeChange}
      onResendCodeClick={handleResendCodeClick}
    />
  );
};

export default React.memo(EnterCodeFormContainer);
