import React from 'react';

import { FAQ_QUESTIONS } from './FaqPage.constants';

import { Typography } from 'components/shared/Typography';

import { HomeLayout } from 'components/Layout';

// import { ListSection } from 'pages/LessonPage/components/TheoryLesson/components/ListSection';

import { Question } from './components/Question';

import FaqImage from 'assets/img/faq.svg';

import styles from './FaqPage.styles.scss';

const FaqPage = () => (
  <HomeLayout>
    <div className="container">
      <div className={styles.page}>
        <FaqImage className={styles.img} />
        <Typography variant="heading2" className={styles.title}>
          Frequently Asked Questions
        </Typography>
        <div className={styles.questions}>
          {FAQ_QUESTIONS.map((question, index) => (
            <Question
              key={question.id}
              className={styles.question}
              title={question.title}
            >
              {typeof question.children === 'string' ? (
                <Typography variant="body1">{question.children}</Typography>
              ) : (
                <></>
                // <ListSection items={question.children} />
              )}
            </Question>
          ))}
        </div>
      </div>
    </div>
  </HomeLayout>
);
export default React.memo(FaqPage);
