import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _flatten from 'lodash/flatten';

import { COURSE_STRUCTURES, RATE_MODAL_TYPES } from 'constants/common';

import { completeLesson } from 'store/lesson/actions';

import { getCourseNextLessonById } from 'helpers/getCourseNextLessonById';

import { courseSelector } from 'store/course/selectors';
import { lessonSelector } from 'store/lesson/selectors';

const _getCompletedModalType = ({
  isLastUncompletedLessonInCourse,
  isLastUncompletedLessonInModule,
}) => {
  if (isLastUncompletedLessonInCourse) {
    return RATE_MODAL_TYPES.COMPLETED_COURSE;
  }

  if (isLastUncompletedLessonInModule) {
    return RATE_MODAL_TYPES.COMPLETED_MODULE;
  }

  return null;
};

const _checkLastLessonInCourse = ({
  isLastUncompletedLessonInCourse,
  haveModules,
  course,
  lessonId,
}) => {
  const lessons = haveModules
    ? _flatten(course.modules.map((module) => module.lessons))
    : course.lessons;

  const lesson = lessons[lessons.length - 1];

  const isLastCourseLessonByOrder = lesson.id === lessonId;

  return (
    isLastUncompletedLessonInCourse ||
    (isLastCourseLessonByOrder && course.completed)
  );
};

const _checkLastUncompletedLessonInCourse = ({
  haveModules,
  course,
  lessonId,
}) => {
  if (course.completedLessons.indexOf(lessonId) > -1) {
    return false;
  }

  const lessons = haveModules
    ? _flatten(course.modules.map((module) => module.lessons))
    : course.lessons;

  return lessons.length - course.completedLessons.length === 1;
};

const _checkLastUncompletedLessonInModule = ({
  haveModules,
  course,
  lessonId,
  moduleId,
}) => {
  if (!haveModules) {
    return false;
  }

  if (
    course.completedModules.indexOf(moduleId) > -1 ||
    course.completedLessons.indexOf(lessonId) > -1
  ) {
    return false;
  }

  const currentModule = course.modules.find((module) => module.id === moduleId);

  const completedLessonsCount = course.completedLessons.filter(
    (completedLessonId) =>
      currentModule.lessons.some((lesson) => lesson.id === completedLessonId),
  ).length;

  return currentModule.lessons.length - completedLessonsCount === 1;
};

export const useCourseNextLesson = ({
  lessonId,
  openUnlockLessonsModal,
  openRateModal,
  closeRateModal,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const course = useSelector(courseSelector);
  const lesson = useSelector(lessonSelector);

  const [loading, setLoading] = useState(false);

  if (!lesson || !course || (lesson && lesson.id !== lessonId)) {
    return [];
  }

  const haveModules = course.structure === COURSE_STRUCTURES.MODULES;
  const isLessonCompleted = course.completedLessons.indexOf(lesson.id) > -1;
  const isLastUncompletedLessonInCourse = _checkLastUncompletedLessonInCourse({
    haveModules,
    course,
    lessonId: lesson.id,
  });
  const isLastUncompletedLessonInModule = _checkLastUncompletedLessonInModule({
    haveModules,
    course,
    lessonId: lesson.id,
    moduleId: lesson.moduleId,
  });
  const isLastLessonInCourse = _checkLastLessonInCourse({
    haveModules,
    isLastUncompletedLessonInCourse,
    course,
    lessonId: lesson.id,
  });
  const nextLesson = getCourseNextLessonById({
    course,
    currentLessonId: lesson.id,
  });

  const _handleModalSkipClick = (confirmModalType) => {
    closeRateModal();

    if (confirmModalType === RATE_MODAL_TYPES.COMPLETED_MODULE) {
      navigate(`/courses/${course.id}/lessons/${nextLesson.id}`);
    }

    if (confirmModalType === RATE_MODAL_TYPES.COMPLETED_COURSE) {
      navigate('/dashboard');
    }
  };

  const _handleModalContinueClick = (confirmModalType) => {
    closeRateModal();

    if (confirmModalType === RATE_MODAL_TYPES.COMPLETED_MODULE) {
      navigate(`/courses/${course.id}/lessons/${nextLesson.id}`);
    }

    if (confirmModalType === RATE_MODAL_TYPES.COMPLETED_COURSE) {
      navigate('/dashboard');
    }
  };

  const _completeLesson = async () => {
    try {
      setLoading(true);

      const completedModalType = _getCompletedModalType({
        isLastUncompletedLessonInCourse,
        isLastUncompletedLessonInModule,
      });

      await dispatch(
        completeLesson({
          courseId: course.id,
          lessonId: lesson.id,
        }),
      );

      if (completedModalType) {
        setLoading(false);

        openRateModal({
          onSkipClick: () => _handleModalSkipClick(completedModalType),
          onContinueClick: () => _handleModalContinueClick(completedModalType),
          modalType: completedModalType,
        });

        return;
      }

      navigate(`/courses/${course.id}/lessons/${nextLesson.id}`);
    } catch (error) {
      console.warn(error);
    }

    setLoading(false);
  };

  const handleNextLessonClick = async () => {
    if (!course.available && !nextLesson.free) {
      setLoading(true);

      await dispatch(
        completeLesson({
          courseId: course.id,
          lessonId: lesson.id,
        }),
      );

      setLoading(false);

      openUnlockLessonsModal();

      return [];
    }

    if (isLessonCompleted && !isLastLessonInCourse) {
      navigate(`/courses/${course.id}/lessons/${nextLesson.id}`);

      return [];
    }

    if (isLessonCompleted && isLastLessonInCourse) {
      openRateModal({
        onSkipClick: () =>
          _handleModalSkipClick(RATE_MODAL_TYPES.COMPLETED_COURSE),
        onContinueClick: () =>
          _handleModalContinueClick(RATE_MODAL_TYPES.COMPLETED_COURSE),
        modalType: RATE_MODAL_TYPES.COMPLETED_COURSE,
      });

      return [];
    }

    _completeLesson();
  };

  return [loading, isLastLessonInCourse, handleNextLessonClick];
};
