import React from 'react';

import { Typography } from 'components/shared/Typography';
import { Modal } from 'components/shared/Modal';

import { Form } from './components/Form';

import styles from './SetupAccount.styles.scss';

const SetupAccount = ({ open, onClose }) => (
  <Modal
    className={styles.modal}
    withCloseIcon={false}
    centered
    open={open}
    onClose={onClose}
  >
    <Typography className={styles.title} variant="subtitle" weight="semiBold">
      Setup your account
    </Typography>
    <Typography className={styles.text} variant="body1">
      Let's get acquainted and start working together
    </Typography>
    <Form onCloseModal={onClose} />
  </Modal>
);

export default React.memo(SetupAccount);
