import React from 'react';

import { Typography } from 'components/shared/Typography';

import styles from './NumberListBlock.styles.scss';

const NumberListBlock = ({ className, data }) => (
  <div className={className}>
    {data.map((item, itemIndex) => (
      <div className={styles.item} key={item}>
        <Typography className={styles.itemOrder} variant="body1">
          {itemIndex + 1}.
        </Typography>
        <Typography className={styles.itemText} variant="body1">
          {item}
        </Typography>
      </div>
    ))}
  </div>
);

export default React.memo(NumberListBlock);
