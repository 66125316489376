import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';

import { STARS_COUNT } from './Rating.constants';

import styles from './Rating.styles.scss';

const Rating = ({
  selectedValue,
  displayValue,
  size,
  className,
  onMouseLeave,
  onStarMouseMove,
  onChange,
  ...restProps
}) => (
  <div
    className={cn(styles.rating, className)}
    onMouseLeave={onMouseLeave}
    style={{ width: `${size * STARS_COUNT}px` }}
  >
    <div className={styles.stars}>
      {Array.from(Array(STARS_COUNT)).map((_, index) => (
        <div
          key={index}
          className={cn(styles.starContainer, {
            [styles.activeStarContainer]:
              index < selectedValue && !displayValue,
            [styles.displayStarContainer]: index < displayValue && displayValue,
          })}
          style={{ width: size, height: size }}
          onClick={() => onChange(index + 1)}
          onMouseMove={(event) => onStarMouseMove(event, index)}
        >
          <Icon key={index} name="starFilled" className={styles.star} />
        </div>
      ))}
    </div>
    <div className={styles.emptyStars}>
      {Array.from(Array(STARS_COUNT)).map((_, index) => (
        <div
          key={index}
          className={styles.emptyStarContainer}
          style={{ width: size, height: size }}
          onMouseMove={(event) => onStarMouseMove(event, index)}
        >
          <Icon name="starFilled" className={styles.emptyStar} />
        </div>
      ))}
    </div>
  </div>
);

Rating.defaultProps = {
  value: 0,
  size: 16,
};

export default React.memo(Rating);
