import * as Yup from 'yup';

export const initialValues = {
  name: '',
  schoolName: '',
  email: '',
  phoneNumber: '',
  companySize: '',
};

export const validationSchema = Yup.object().shape({
  text: Yup.string().max(300, 'Message cannot be longer than 300 characters.'),
  name: Yup.string().required('Name is required.'),
  schoolName: Yup.string().required('School name is required.'),
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required.'),
  phoneNumber: Yup.string().ensure(),
  companySize: Yup.string().ensure(),
});
