import { ROUTES } from 'constants/routes';

export const SIDEBAR_MENU = [
  {
    key: 'dashboard',
    icon: 'dashboard',
    text: 'Dashboard',
    to: ROUTES.DASHBOARD_PAGE,
  },
  {
    key: 'courses',
    icon: 'courses',
    text: 'Courses',
    to: ROUTES.COURSES_PAGE,
  },
];
