import React, { useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { courseSelector } from 'store/course/selectors';
import { lessonSelector } from 'store/lesson/selectors';

import { getCourseNextLessonById } from 'helpers/getCourseNextLessonById';

import { useModal } from 'hooks/useModal';

import { UnlockLessonsModal } from 'components/UnlockLessonsModal';

import LessonMenu from './LessonMenu';

import { getCoursePreviousLesson } from './LessonMenu.helpers';

const LessonMenuContainer = (props) => {
  const scrollbarRef = useRef();
  const currentLessonRef = useRef();

  const navigate = useNavigate();

  const { courseId } = useParams();

  const course = useSelector(courseSelector);
  const lesson = useSelector(lessonSelector);

  const [
    unlockLessonsModalOpen,
    openUnlockLessonsModal,
    closeUnlockLessonsModal,
  ] = useModal({ defaultOpen: false });

  // const _scrollToCurrentLesson = () => {
  //   scrollbarRef.current.scrollTo(
  //     0,
  //     currentLessonRef.current.offsetTop - 500 / 2, //TODO Get height value from ref
  //   );
  // };

  // useEffect(() => {
  //   if (open && currentLessonRef.current) {
  //     _scrollToCurrentLesson();
  //   }
  // }, [open, currentLessonRef]);

  const handleBackClick = () => {
    navigate(`/courses/${courseId}`);
  };

  const handleLessonClick = (nextLessonId) => {
    navigate(`/courses/${courseId}/lessons/${nextLessonId}`);
  };

  const handlePreviousLessonClick = (event) => {
    event.stopPropagation();

    const prevLesson = getCoursePreviousLesson({
      course,
      lessonId: lesson.id,
    });

    if (!course.available && !prevLesson.free) {
      // OPEN UNLOCK LESSONS MODAL SHEET
      openUnlockLessonsModal();

      return;
    }

    navigate(`/courses/${course.id}/lessons/${prevLesson.id}`);
  };

  const handleNextLessonClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const nextLesson = getCourseNextLessonById({
      course,
      currentLessonId: lesson.id,
    });

    if (!course.available && !nextLesson.free) {
      // OPEN UNLOCK LESSONS MODAL SHEET
      openUnlockLessonsModal();

      return;
    }

    navigate(`/courses/${course.id}/lessons/${nextLesson.id}`);
  };

  return (
    <>
      <LessonMenu
        {...props}
        scrollbarRef={scrollbarRef}
        currentLessonRef={currentLessonRef}
        currentLesson={lesson}
        course={course}
        onBackClick={handleBackClick}
        onLessonClick={handleLessonClick}
        onNextLessonClick={handleNextLessonClick}
        onPreviousLessonClick={handlePreviousLessonClick}
      />
      <UnlockLessonsModal
        open={unlockLessonsModalOpen}
        onClose={closeUnlockLessonsModal}
      />
    </>
  );
};

export default React.memo(LessonMenuContainer);
