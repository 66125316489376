import React from 'react';

import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';

import styles from './ConfirmModal.styles.scss';

const ConfirmModal = ({
  open,
  title,
  text,
  confirmText,
  cancelText,
  onConfirmClick,
  onCancelClick,
  onClose,
}) => (
  <Modal open={open} centered onClose={onClose} className={styles.modal}>
    <Typography variant="heading2" className={styles.title}>
      {title}
    </Typography>
    {text && (
      <Typography variant="body1" weight="medium" className={styles.text}>
        {text}
      </Typography>
    )}
    <div className={styles.actions}>
      <Button
        variant="outlined"
        className={styles.cancelButton}
        onClick={onCancelClick}
      >
        {cancelText}
      </Button>
      <Button className={styles.confirmButton} onClick={onConfirmClick}>
        {confirmText}
      </Button>
    </div>
  </Modal>
);

ConfirmModal.defaultProps = {
  confirmText: 'Save',
  cancelText: 'Cancel',
};

export default React.memo(ConfirmModal);
