import React from 'react';

import { COURSE_STRUCTURES } from 'constants/common';

import { ScrollWrapper } from 'components/shared/ScrollWrapper';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';

import { Modules } from '../Modules';
import { Lessons } from '../Lessons';

import { prepareModules, prepareLessons } from './MenuDropdown.helpers';

import styles from './MenuDropdown.styles.scss';

const MenuDropdown = ({
  scrollbarRef,
  course,
  currentLesson,
  onBackClick,
  onLessonClick,
}) => (
  <div className={styles.dropdown}>
    <ScrollWrapper
      scrollbarRef={scrollbarRef}
      translateContentSizeYToHolder
      className={styles.scrollbar}
    >
      <div className={styles.header}>
        <Button
          className={styles.back}
          variant="link"
          size="sm"
          startIcon={<Icon name="arrowLeft" />}
          onClick={onBackClick}
        >
          Back to course
        </Button>
      </div>
      {course.structure === COURSE_STRUCTURES.MODULES && (
        <Modules
          modules={prepareModules({
            modules: course.modules,
            currentLesson,
            course,
          })}
          onLessonClick={onLessonClick}
        />
      )}
      {course.structure === COURSE_STRUCTURES.LESSONS && (
        <Lessons
          lessons={prepareLessons({
            lessons: course.lessons,
            currentLesson,
            course,
          })}
          onLessonClick={onLessonClick}
        />
      )}
    </ScrollWrapper>
  </div>
);

export default React.memo(MenuDropdown);
