import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { getAnswersImageSize, isAnswersMultiple } from './QuizPage.helpers';

import { Typography } from 'components/shared/Typography';

import { TheoryBlock } from 'components/TheoryBlock';
import { LessonLayout } from 'components/Layout';
import { LessonFooter } from 'components/LessonFooter';

import { Action } from './components/Action';
import { AnswerText } from './components/AnswerText';
import { AnswerImage } from './components/AnswerImage';

import styles from './QuizPage.styles.scss';

const QuizPage = ({
  nextLoading,
  isLastLessonInCourse,
  correct,
  submitted,
  questions,
  currentAnswers,
  currentQuestion: currentQuestionIndex,
  onQuestionsSwiper,
  onCheckClick,
  onAnswerClick,
  onNextQuestionClick,
  onTryAgainClick,
  onNextLessonClick,
  openReportLessonModal,
}) => {
  return (
    <LessonLayout>
      <div className={styles.page}>
        <div className={styles.inner}>
          <Typography
            className={styles.title}
            variant="body1"
            weight="medium"
            mode="compact"
          >
            Question ({currentQuestionIndex + 1}/{questions.length})
          </Typography>
          <div className={styles.main}>
            <Swiper
              autoHeight={true}
              className={styles.swiper}
              allowTouchMove={false}
              spaceBetween={16}
              slidesPerView={1}
              observer={true}
              onSwiper={onQuestionsSwiper}
            >
              {questions.map((question) => {
                const answersMultiple = isAnswersMultiple(question);

                return (
                  <SwiperSlide key={question.id}>
                    <div key={question.id} className={styles.question}>
                      <div className={styles.blocks}>
                        {question.blocks.map((block) => (
                          <TheoryBlock
                            key={block.id}
                            type={block.type}
                            data={block.data}
                          />
                        ))}
                      </div>

                      <div className={styles.answers}>
                        {question.answers.map((answer, _, answers) => {
                          switch (question.answersType) {
                            case 'TEXT': {
                              return (
                                <AnswerText
                                  key={answer.id}
                                  {...answer}
                                  checked={currentAnswers.includes(answer.id)}
                                  submitted={submitted}
                                  answersMultiple={answersMultiple}
                                  type={question.answersType}
                                  onClick={() =>
                                    onAnswerClick({
                                      answer,
                                      answersMultiple,
                                    })
                                  }
                                />
                              );
                            }
                            case 'IMAGE': {
                              return (
                                <AnswerImage
                                  key={answer.id}
                                  {...answer}
                                  size={getAnswersImageSize(answers)}
                                  checked={currentAnswers.includes(answer.id)}
                                  submitted={submitted}
                                  answersMultiple={answersMultiple}
                                  type={question.answersType}
                                  onClick={() =>
                                    onAnswerClick({
                                      answer,
                                      answersMultiple,
                                    })
                                  }
                                />
                              );
                            }
                          }
                        })}
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
        <LessonFooter
          openReportLessonModal={openReportLessonModal}
          action={
            <Action
              nextLoading={nextLoading}
              isLastLessonInCourse={isLastLessonInCourse}
              submitted={submitted}
              correct={correct}
              questions={questions}
              currentAnswers={currentAnswers}
              currentQuestion={currentQuestionIndex}
              onNextQuestionClick={onNextQuestionClick}
              onTryAgainClick={onTryAgainClick}
              onCheckClick={onCheckClick}
              onNextLessonClick={onNextLessonClick}
            />
          }
        />
      </div>
    </LessonLayout>
  );
};

export default React.memo(QuizPage);
