import _orderBy from 'lodash/orderBy';
import _flatten from 'lodash/flatten';

import { COURSE_STRUCTURES } from 'constants/common';

import * as api from 'api/methods';

const _getCourseModules = async (courseId) => {
  const promises = [
    api.getCourseModules(courseId),
    api.getCourseLessons(courseId),
  ];

  const [modules, lessons] = await Promise.all(promises);

  return _orderBy(modules, ['order'], ['asc']).map((module) => ({
    ...module,
    lessons: lessons
      .filter((lesson) => lesson.moduleId === module.id)
      .map((lesson, lessonIndex) => ({
        ...lesson,
        order: lessonIndex + 1,
      })),
  }));
};

export const getNextCourse = async (course) => {
  if (course.structure === COURSE_STRUCTURES.MODULES) {
    const modules = await _getCourseModules(course.id);

    return {
      ...course,
      modules,
    };
  }

  if (course.structure === COURSE_STRUCTURES.LESSONS) {
    const lessons = await api.getCourseLessons(course.id);

    return {
      ...course,
      lessons: lessons.map((lesson, lessonIndex) => ({
        ...lesson,
        order: lessonIndex + 1,
      })),
    };
  }
};

export const getFirstLesson = ({ canStartCourse, course }) => {
  if (course.structure === COURSE_STRUCTURES.MODULES) {
    const firstModule = !canStartCourse
      ? course.modules.find((module) =>
          module.lessons.some((lesson) => lesson.free),
        )
      : course.modules[0];

    const firstLesson = !canStartCourse
      ? firstModule.lessons.find((lesson) => lesson.free)
      : firstModule.lessons[0];

    return firstLesson;
  }

  if (course.structure === COURSE_STRUCTURES.LESSONS) {
    const firstLesson = !canStartCourse
      ? course.lessons.find((lesson) => lesson.free)
      : course.lessons[0];

    return firstLesson;
  }
};

export const getNextLesson = ({ canStartCourse, course }) => {
  if (course.structure === COURSE_STRUCTURES.MODULES) {
    const nextModule = course.modules.find(
      (module) => course.completedModules.indexOf(module.id) === -1,
    );

    if (!nextModule) {
      return getFirstLesson({ canStartCourse, course });
    }

    const lessons = nextModule.lessons;

    const nextLesson = lessons.find(
      (lesson) => course.completedLessons.indexOf(lesson.id) === -1,
    );

    if (!nextLesson) {
      return getFirstLesson({ canStartCourse, course });
    }

    return nextLesson;
  }

  if (course.structure === COURSE_STRUCTURES.LESSONS) {
    const nextLesson = course.lessons.find(
      (lesson) => course.completedLessons.indexOf(lesson.id) === -1,
    );

    if (!nextLesson) {
      return getFirstLesson({ canStartCourse, course });
    }

    return nextLesson;
  }
};

export const canStartCourse = (course) => {
  if (course.available) {
    return true;
  }

  if (course.structure === COURSE_STRUCTURES.LESSONS) {
    return course.lessons.some((lesson) => lesson.free);
  }

  if (course.structure === COURSE_STRUCTURES.MODULES) {
    const lessons = _flatten(course.modules.map((module) => module.lessons));

    return lessons.some((lesson) => lesson.free);
  }
};
