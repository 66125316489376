import React from 'react';
import cn from 'classnames';

import { RoundProgressBar } from 'components/shared/RoundProgressBar';
import { Typography } from 'components/shared/Typography';

import styles from './Progress.styles.scss';

const Progress = ({ className, progress, showCompletedText }) => (
  <div className={cn(styles.progress, className)}>
    <RoundProgressBar
      size={24}
      progress={progress}
      progressColor="var(--color-green-primary)"
      backLineColor="var(--color-green-primary)"
      backLineOpacity={0.15}
      lineSize={16}
    />
    <Typography variant="body3" weight="medium" className={styles.text}>
      {progress}% {showCompletedText && 'completed'}
    </Typography>
  </div>
);

Progress.defaultProps = {
  showCompletedText: true,
};

export default React.memo(Progress);
