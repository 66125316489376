import React from 'react';
import { Formik, Form } from 'formik';

import { MODAL_TYPES, RATE_MODAL_TAGS } from './RateModal.constants';

import { Modal } from 'components/shared/Modal';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import { SelectTagField, RatingField } from 'components/FormikFields';

import { initialValues, validationSchema } from './RateModal.formConfig';

import styles from './RateModal.styles.scss';

const renderBackgroundIcon = (preview) => {
  const BackgroundIcon = require.context(
    'assets/img',
    true,
    /\.svg$/,
  )(`./${preview}.svg`).default;

  return <BackgroundIcon className={styles.preview} />;
};

const RateModal = ({
  open,
  modalType,
  onClose,
  onSkipClick,
  onContinueClick,
}) => {
  const data = MODAL_TYPES[modalType];

  if (!data) {
    return null;
  }

  return (
    <Modal
      className={styles.modal}
      withCloseIcon={false}
      centered
      open={open}
      onClose={onClose}
    >
      <Formik
        validateOnMount
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onContinueClick}
      >
        {({ values }) => (
          <Form className={styles.form}>
            {data.preview && renderBackgroundIcon(data.preview)}
            <Typography
              className={styles.title}
              variant="heading2"
              weight="semiBold"
            >
              {data.title}
            </Typography>
            <Typography className={styles.text} variant="body1">
              How would you rate your experience?
            </Typography>
            <FormikField
              name="rating"
              component={RatingField}
              componentProps={{
                size: 56,
                className: styles.rating,
                value: values.rating,
              }}
            />

            {!!values.rating && (
              <>
                <Typography className={styles.tagsText} variant="body1">
                  Thank you for rating!
                </Typography>
                {values.rating < 4 && (
                  <>
                    <Typography className={styles.tagsText} variant="body1">
                      Choose what you didn't like about the lesson
                    </Typography>
                    <div className={styles.tags}>
                      {RATE_MODAL_TAGS.map((tag) => (
                        <FormikField
                          key={tag}
                          name="tags"
                          withControl={false}
                          component={SelectTagField}
                          componentProps={{
                            className: styles.tag,
                            type: 'checkbox',
                            text: tag,
                            value: tag,
                          }}
                        />
                      ))}
                    </div>
                  </>
                )}
              </>
            )}

            <div className={styles.actions}>
              <Button
                variant="outlined"
                className={styles.action}
                onClick={onSkipClick}
              >
                Skip
              </Button>
              <Button className={styles.action} type="submit">
                Continue
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default React.memo(RateModal);
