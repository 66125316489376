import React from 'react';
import cn from 'classnames';

import { MENU_ITEMS } from 'constants/common';

import { Icon } from 'components/shared/Icon';
import { Avatar } from 'components/shared/Avatar';
import { Typography } from 'components/shared/Typography';
import { Popover } from 'components/shared/Popover';

import styles from './UserMenu.styles.scss';

const _renderMenuItem = ({ key, icon, title, onClick }) => (
  <div key={key} className={styles.item} onClick={onClick}>
    <Icon
      name={icon}
      className={cn(styles.itemIcon, {
        [styles.itemLogoutIcon]: key === 'logout',
      })}
    />
    <Typography
      variant="body2"
      weight="medium"
      className={cn(styles.itemText, {
        [styles.itemLogoutText]: key === 'logout',
      })}
    >
      {title}
    </Typography>
  </div>
);

const UserMenu = ({ user, onMenuItemClick, className }) => (
  <Popover
    placement="bottom-end"
    triggerElement={({ isMounted }) => (
      <div className={cn(styles.menu, { [styles.menuActive]: isMounted })}>
        <Avatar size="sm" text={user.name} url={user.avatar} />
        <Icon className={styles.icon} name="chevronDown" />
      </div>
    )}
  >
    <div className={styles.dropdown}>
      <div className={styles.items}>
        {MENU_ITEMS.map((menuItem) =>
          _renderMenuItem({
            ...menuItem,
            onClick: () => onMenuItemClick(menuItem.key),
          }),
        )}
      </div>
    </div>
  </Popover>
);

export default React.memo(UserMenu);
