import React from 'react';

import { Module } from '../Module';

import styles from './Modules.styles.scss';

const Modules = ({ modules, onLessonClick }) => (
  <div className={styles.modules}>
    {modules.map((module) => (
      <Module
        key={module.id}
        title={module.title}
        lessons={module.lessons}
        onLessonClick={onLessonClick}
      />
    ))}
  </div>
);

export default React.memo(Modules);
