import React from 'react';

import { Typography } from 'components/shared/Typography';

import { LessonItem } from '../LessonItem';

import { prepareLesson } from './Lessons.helpers';

import styles from './Lessons.styles.scss';

const Lessons = ({
  courseAvailable,
  lessons,
  completedLessons,
  startLoadingId,
  onLessonClick,
}) => (
  <div className={styles.lessons}>
    <Typography className={styles.title} variant="subtitle">
      Course Lessons
    </Typography>

    <div className={styles.inner}>
      {lessons.map((lesson) => (
        <LessonItem
          className={styles.lesson}
          key={lesson.id}
          startLoading={startLoadingId === lesson.id}
          {...prepareLesson({
            courseAvailable,
            lesson,
            completedLessons,
          })}
          onClick={onLessonClick}
        />
      ))}
    </div>
  </div>
);

export default React.memo(Lessons);
