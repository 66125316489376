import React from 'react';
import { Formik, Form } from 'formik';

import { REQUEST_DEMO_MODAL_TAGS } from './RequestDemoModal.constants';

import { initialValues, validationSchema } from './RequestDemoModal.formConfig';

import { Modal } from 'components/shared/Modal';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import { InputField, SelectTagField } from 'components/FormikFields';

import styles from './RequestDemoModal.styles.scss';

const RequestDemoModal = ({ open, onClose, loading, onSubmitClick }) => (
  <Modal className={styles.modal} open={open} onClose={onClose}>
    <Typography variant="subtitle" className={styles.subtitleForDesktop}>
      Let’s connect for getting more information
    </Typography>
    <Typography variant="subtitle" className={styles.subtitleForMobile}>
      Request a demo
    </Typography>
    <Typography variant="body1" className={styles.description}>
      Submit your contact details and we will provide you with personalized demo
      of what Befront can offer for your school, bootcamp.
    </Typography>
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitClick}
    >
      {() => (
        <Form>
          <FormikField
            name="name"
            component={InputField}
            controlProps={{
              required: true,
              label: 'Name',
              className: styles.field,
            }}
          />
          <FormikField
            name="schoolName"
            component={InputField}
            controlProps={{
              required: true,
              label: 'School name',
              className: styles.field,
            }}
          />
          <FormikField
            name="email"
            component={InputField}
            controlProps={{
              required: true,
              label: 'Email',
              className: styles.field,
            }}
          />
          <FormikField
            name="phoneNumber"
            component={InputField}
            controlProps={{
              required: false,
              label: 'Phone',
              className: styles.field,
            }}
          />
          <Typography variant="body1" className={styles.text}>
            Students per year
          </Typography>
          <div className={styles.tags}>
            {REQUEST_DEMO_MODAL_TAGS.map((tag) => (
              <FormikField
                key={tag}
                name="companySize"
                withControl={false}
                component={SelectTagField}
                componentProps={{
                  className: styles.tag,
                  type: 'radio',
                  text: tag,
                  value: tag,
                }}
              />
            ))}
          </div>
          <Button
            className={styles.action}
            size="md"
            type="submit"
            startIcon={
              loading && (
                <Loading size={18} color="var(--color-white-primary)" />
              )
            }
          >
            Send request
          </Button>
        </Form>
      )}
    </Formik>
  </Modal>
);

export default React.memo(RequestDemoModal);
