import { useState } from 'react';
import { v4 as uuid } from 'uuid';

import * as api from 'api/methods';

export const useUpload = ({ multiple, field, form, onUploaded }) => {
  const [removeLoading, setRemoveLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const handleDropAccepted = (files) => {
    if (!files.length) {
      return;
    }

    const currentFile = files[0];

    const fileId = uuid();

    if (multiple) {
      form.setFieldValue(field.name, [
        ...field.value,
        {
          id: fileId,
          name: currentFile.name,
          size: currentFile.size,
          type: currentFile.type,
          percentage: 0.1,
        },
      ]);
    } else {
      setPercentage(0.1);
    }

    const data = new FormData();
    data.append('upload', currentFile);

    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(progressEvent.progress * 100);

        if (multiple) {
          const haveFile = field.value.some((file) => file.id === fileId);

          const nextFiles = haveFile
            ? field.value.map((file) => {
                if (file.id === fileId) {
                  return {
                    ...file,
                    percentage: percentCompleted,
                  };
                }

                return file;
              })
            : [
                ...field.value,
                {
                  id: fileId,
                  name: currentFile.name,
                  size: currentFile.size,
                  type: currentFile.type,
                  percentage: percentCompleted,
                },
              ];

          form.setFieldValue(field.name, nextFiles);
        } else {
          setPercentage(percentCompleted);
        }
      },
    };

    api
      .uploadFile({ data, config })
      .then((response) => {
        if (multiple) {
          const haveFile = field.value.some((file) => file.id === fileId);

          const nextFiles = haveFile
            ? field.value.map((file) => {
                if (file.id === fileId) {
                  return {
                    ...file,
                    url: response.url,
                  };
                }

                return file;
              })
            : [
                ...field.value,
                {
                  id: fileId,
                  name: response.name,
                  size: response.size,
                  type: response.type,
                  percentage: 100,
                },
              ];

          form.setFieldValue(field.name, nextFiles);
        } else {
          setPercentage(0);

          form.setFieldValue(field.name, response.url);
        }

        if (onUploaded) {
          onUploaded(response);
        }
      })
      .catch(() => {});
  };

  const handleDropRejected = (event) => {
    const eventError = event[0].errors[0];

    // TODO: check errors
    switch (eventError.code) {
      // case EVENT_ERROR_CODE.INVALID_TYPE: {
      //   form.setFieldError(
      //     field.name,
      //     'The specified file could not be uploaded. Allowed type jpeg, png, gif, avi, mp4, mov, mkv, mpg, wmv, mts, 3gp',
      //   );

      //   break;
      // }
      // case EVENT_ERROR_CODE.LARGE_SIZE: {
      //   form.setFieldError(field.name, eventError.message);

      //   break;
      // }
      // case EVENT_ERROR_CODE.MANY_FILES: {
      //   form.setFieldError(field.name, 'No more than 3 files can be attached');

      //   break;
      // }

      default: {
        form.setFieldError(field.name, eventError.message);

        break;
      }
    }
  };

  const handleRemoveClick = () => {
    const fileKey = field.value.substring(1);

    setRemoveLoading(true);

    api
      .removeFile({
        key: fileKey,
      })
      .then(() => {
        setRemoveLoading(false);

        // TODO: for multiple files
        form.setFieldValue(field.name, '');
      })
      .catch(() => {
        setRemoveLoading(false);
      });
  };

  return [
    removeLoading,
    percentage,
    handleDropAccepted,
    handleDropRejected,
    handleRemoveClick,
  ];
};
