import React from 'react';

import { Button } from 'components/shared//Button';
import { Icon } from 'components/shared/Icon';
import { Loading } from 'components/shared/Loading';

const Action = ({
  nextLoading,
  isLastLessonInCourse,
  linesValidated,
  correctLinesIds,
  lines,
  onTryAgainClick,
  onNextLessonClick,
}) => {
  const correct = correctLinesIds.length === lines.length;

  if (linesValidated && !correct) {
    return <Button onClick={onTryAgainClick}>Try Again</Button>;
  }

  if (linesValidated && correct && !isLastLessonInCourse) {
    return (
      <Button
        startIcon={
          nextLoading && <Loading color="var(--color-white-primary)" />
        }
        endIcon={<Icon name="arrowRight" />}
        onClick={onNextLessonClick}
      >
        Next Lesson
      </Button>
    );
  }

  if (linesValidated && correct && isLastLessonInCourse) {
    return (
      <Button
        startIcon={
          nextLoading && <Loading color="var(--color-white-primary)" />
        }
        endIcon={<Icon name="arrowRight" />}
        onClick={onNextLessonClick}
      >
        Finish Course
      </Button>
    );
  }

  return (
    <Button
      disabled={!linesValidated}
      startIcon={nextLoading && <Loading color="var(--color-white-primary)" />}
      endIcon={<Icon name="arrowRight" />}
      onClick={onNextLessonClick}
    >
      Next Lesson
    </Button>
  );
};

export default React.memo(Action);
