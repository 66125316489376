import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import Logo from 'assets/img/logo.svg';

import { Menu } from './components/Menu';
import { ShareReview } from './components/ShareReview';

import styles from './Sidebar.styles.scss';

const Sidebar = ({ className, haveReviewSection, menuItems }) => (
  <div className={cn(styles.sidebar, className)}>
    <div className={styles.inner}>
      <NavLink className={styles.logo} to={'/dashboard'}>
        <Logo className={styles.logoImg} />
      </NavLink>
      <Menu menuItems={menuItems} />
    </div>

    {haveReviewSection && <ShareReview className={styles.shareReview} />}
  </div>
);

export default React.memo(Sidebar);
