import { createSlice } from '@reduxjs/toolkit';

import { getCourseById, startCourseById } from './actions';

const courseSlice = createSlice({
  name: 'currentCourse',
  initialState: null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCourseById.fulfilled, (_, action) => {
      return action.payload;
    });

    builder.addCase(startCourseById.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    });
  },
});

export default courseSlice.reducer;
