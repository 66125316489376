import { createSlice } from '@reduxjs/toolkit';

import { getLessonById } from './actions';

const lessonSlice = createSlice({
  name: 'currentLesson',
  initialState: null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLessonById.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});

export default lessonSlice.reducer;
