import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';
import { RoundProgressBar } from 'components/shared/RoundProgressBar';
import { Link } from 'components/shared/Link';
import { Loading } from 'components/shared/Loading';

import styles from './UploadPreview.styles.scss';

const UploadPreview = ({
  className,
  loading,
  haveError,
  value,
  text,
  percentage,
  getInputProps,
  getRootProps,
  onBrowseClick,
  onRemoveClick,
}) => (
  <div
    {...getRootProps()}
    className={cn(
      styles.upload,
      { [styles.uploadWithValue]: !!value },
      { [styles.uploadHaveError]: haveError },
      className,
    )}
  >
    <input {...getInputProps()} className={styles.field} />
    {value && !loading && (
      <div className={styles.actionOverlay} onClick={onRemoveClick}>
        <Icon className={styles.actionIcon} name="trash" />
      </div>
    )}
    {loading && (
      <div className={styles.loadingOverlay}>
        <Loading color="var(--color-white-primary)" />
      </div>
    )}
    {value ? (
      <img src={value} className={styles.preview} />
    ) : (
      <>
        {percentage > 0 && percentage <= 100 ? (
          <RoundProgressBar
            lineSize={12}
            progress={percentage}
            size={120}
            backLineColor="var(--color-blue-15)"
            progressColor="var(--color-blue-primary)"
          />
        ) : (
          <>
            <Icon name="upload" className={styles.icon} />
            <Typography className={styles.text} variant="body2" mode="compact">
              {text}{' '}
              <Link className={styles.textLink} onClick={onBrowseClick}>
                browse
              </Link>
            </Typography>
          </>
        )}
      </>
    )}
  </div>
);

export default React.memo(UploadPreview);
