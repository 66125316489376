import React from 'react';

import { useUpload } from 'hooks/useUpload';

import { Upload } from 'components/shared/Upload';

const UploadField = ({ multiple, field, form, onUploaded, ...restProps }) => {
  const [
    removeLoading,
    percentage,
    onDropAccepted,
    onDropRejected,
    onRemoveClick,
  ] = useUpload({
    multiple,
    field,
    form,
    onUploaded,
  });

  return (
    <Upload
      {...restProps}
      removeLoading={removeLoading}
      name={field.name}
      value={field.value}
      percentage={percentage}
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      onRemoveClick={onRemoveClick}
    />
  );
};

export default React.memo(UploadField);
