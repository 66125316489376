import { useEffect, useState } from 'react';

import * as api from 'api/methods';

export const useFavorite = ({ defaultFavorite, courseId, onChange }) => {
  const [favorite, setFavorite] = useState(defaultFavorite);

  useEffect(() => {
    setFavorite(defaultFavorite);
  }, [defaultFavorite]);

  const handleFavoriteClick = () => {
    const prevFavorite = favorite;

    if (onChange) {
      onChange(!favorite);
    }

    setFavorite(!favorite);

    api
      .setCourseFavorite({
        courseId,
        data: { favorite: !favorite },
      })
      .catch(() => {
        setFavorite(prevFavorite);
      });
  };

  return [favorite, handleFavoriteClick];
};
