import React from 'react';

import { Typography } from 'components/shared/Typography';

import { LessonLayout } from 'components/Layout';
import { LessonFooter } from 'components/LessonFooter';

import { Item } from './components/Item';
import { Lines } from './components/Lines';
import { Action } from './components/Action';

import styles from './ConnectionQuizPage.styles.scss';

const ConnectionQuizPage = ({
  rootRef,
  containerRef,
  leftItemsRef,
  rightItemsRef,
  nextLoading,
  isLastLessonInCourse,
  lineDrawing,
  linesValidated,
  leftItems,
  rightItems,
  lines,
  correctLinesIds,
  startLinePosition,
  lesson,
  onProcessDrawing,
  onItemClick,
  onTryAgainClick,
  onNextLessonClick,
  openReportLessonModal,
}) => (
  <LessonLayout>
    <div className={styles.page} ref={rootRef}>
      <div
        className={styles.inner}
        onMouseMove={lineDrawing ? onProcessDrawing : () => {}}
      >
        <Typography variant="subtitle" weight="semiBold">
          {lesson.text}
        </Typography>
        <div className={styles.main}>
          <div className={styles.leftColumn}>
            {leftItems.map((leftItem, index) => {
              const pointUsed = lines.some(
                (line) =>
                  (line.startId === leftItem.id &&
                    line.startType === leftItem.type) ||
                  (line.finishId === leftItem.id &&
                    line.finishType === leftItem.type),
              );

              return (
                <Item
                  key={leftItem.id + 'question'}
                  id={leftItem.id}
                  {...leftItem}
                  pointUsed={pointUsed}
                  lineDrawing={lineDrawing}
                  linesValidated={linesValidated}
                  correctLine={correctLinesIds.some(
                    (lineId) => lineId === leftItem.id,
                  )}
                  pointRef={leftItemsRef.current[index]}
                  startLinePosition={startLinePosition}
                  answersType={lesson.answersType}
                  onClick={onItemClick}
                />
              );
            })}
          </div>
          <Lines
            lineDrawing={lineDrawing}
            lines={lines}
            containerRef={containerRef}
          />
          <div className={styles.rightColumn}>
            {rightItems.map((rightItem, index) => {
              const pointUsed = lines.some(
                (line) =>
                  (line.startId === rightItem.id &&
                    line.startType === rightItem.type) ||
                  (line.finishId === rightItem.id &&
                    line.finishType === rightItem.type),
              );

              return (
                <Item
                  key={rightItem.id + 'answer'}
                  className={styles.answerItem}
                  {...rightItem}
                  id={rightItem.id}
                  pointUsed={pointUsed}
                  lineDrawing={lineDrawing}
                  linesValidated={linesValidated}
                  correctLine={correctLinesIds.some(
                    (lineId) => lineId === rightItem.id,
                  )}
                  pointRef={rightItemsRef.current[index]}
                  startLinePosition={startLinePosition}
                  answersType={lesson.answersType}
                  onClick={onItemClick}
                />
              );
            })}
          </div>
        </div>
        <LessonFooter
          openReportLessonModal={openReportLessonModal}
          action={
            <Action
              nextLoading={nextLoading}
              isLastLessonInCourse={isLastLessonInCourse}
              linesValidated={linesValidated}
              correctLinesIds={correctLinesIds}
              lines={lines}
              onTryAgainClick={onTryAgainClick}
              onNextLessonClick={onNextLessonClick}
            />
          }
        />
      </div>
    </div>
  </LessonLayout>
);

export default React.memo(ConnectionQuizPage);
