import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as amplitude from '@amplitude/analytics-browser';

import { AMPLITUDE_API_KEY } from 'constants/env';

import { userSelector } from 'store/auth/selectors';

const AnalyticsProvider = ({ children }) => {
  const [initialized, setInitialized] = useState(false);

  const user = useSelector(userSelector);

  useEffect(() => {
    amplitude.init(AMPLITUDE_API_KEY, { defaultTracking: false });

    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized && user) {
      amplitude.setUserId(user.email);
    }
  }, [user]);

  if (!initialized) {
    return null;
  }

  return children;
};

export default React.memo(AnalyticsProvider);
