import React from 'react';

import { Typography } from 'components/shared/Typography';

import styles from './TextBlock.styles.scss';

const TextBlock = ({ className, type, data }) => {
  const _getVariant = () => {
    switch (type) {
      case 'heading1':
      case 'heading2': {
        return type;
      }
      case 'heading3': {
        return 'subtitle';
      }
      case 'text': {
        return 'body1';
      }
    }
  };

  return (
    <div className={className}>
      <Typography className={styles.block} variant={_getVariant(type)}>
        {data}
      </Typography>
    </div>
  );
};

export default React.memo(TextBlock);
