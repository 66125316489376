import React from 'react';

import { LESSON_TYPES } from 'constants/common';

import { Loading } from 'components/shared/Loading';

import { TheoryLessonPage } from 'pages/TheoryLessonPage';
import { QuizPage } from 'pages/QuizPage';
import { ConnectionQuizPage } from 'pages/ConnectionQuizPage';
import { TwoColumnsLessonPage } from 'pages/TwoColumnsLessonPage';
import { DifferenceLessonPage } from 'pages/DifferenceLessonPage';

const LessonProvider = ({
  loading,
  nextLoading,
  isLastLessonInCourse,
  lessonType,
  onNextLessonClick,
  openReportLessonModal,
}) => {
  if (loading) {
    return <Loading />;
  }

  switch (lessonType) {
    case LESSON_TYPES.THEORY_LESSON: {
      return (
        <TheoryLessonPage
          nextLoading={nextLoading}
          isLastLessonInCourse={isLastLessonInCourse}
          onNextLessonClick={onNextLessonClick}
          openReportLessonModal={openReportLessonModal}
        />
      );
    }
    case LESSON_TYPES.QUIZ: {
      return (
        <QuizPage
          nextLoading={nextLoading}
          isLastLessonInCourse={isLastLessonInCourse}
          onNextLessonClick={onNextLessonClick}
          openReportLessonModal={openReportLessonModal}
        />
      );
    }
    case LESSON_TYPES.CONNECTION_QUIZ: {
      return (
        <ConnectionQuizPage
          nextLoading={nextLoading}
          isLastLessonInCourse={isLastLessonInCourse}
          onNextLessonClick={onNextLessonClick}
          openReportLessonModal={openReportLessonModal}
        />
      );
    }
    case LESSON_TYPES.TWO_COLUMNS_LESSON: {
      return (
        <TwoColumnsLessonPage
          nextLoading={nextLoading}
          isLastLessonInCourse={isLastLessonInCourse}
          onNextLessonClick={onNextLessonClick}
          openReportLessonModal={openReportLessonModal}
        />
      );
    }
    case LESSON_TYPES.DIFFERENCE_LESSON: {
      return (
        <DifferenceLessonPage
          nextLoading={nextLoading}
          isLastLessonInCourse={isLastLessonInCourse}
          onNextLessonClick={onNextLessonClick}
          openReportLessonModal={openReportLessonModal}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default React.memo(LessonProvider);
