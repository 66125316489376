import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from 'components/shared/Typography';

import { EmptyState } from 'components/EmptyState';

import { CourseCard } from '../CourseCard';

import { orderCourses } from './MyCoursesSection.helpers';

import styles from './MyCoursesSection.styles.scss';

const MyCoursesSection = ({ courses, onCourseCardClick }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.section}>
      <Typography className={styles.title} variant="heading2">
        My Courses
      </Typography>
      <div className={styles.inner}>
        {courses && courses.length ? (
          orderCourses(courses).map((course) => (
            <CourseCard
              className={styles.course}
              key={course.id}
              {...course}
              withFavorite={false}
              onClick={onCourseCardClick}
            />
          ))
        ) : (
          <EmptyState
            className={styles.emptyState}
            withButton
            buttonText="Go to courses"
            icon="courses"
            title="Add courses"
            text="Provide your career preferences to get matched with job opportunities"
            onClick={() => {
              navigate('/courses');
            }}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(MyCoursesSection);
