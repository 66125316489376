import React from 'react';
import cn from 'classnames';

import { Header } from 'components/Header';

import styles from './ProfileLayout.styles.scss';

const ProfileLayout = ({ className, children }) => (
  <div className={cn(styles.layout, className)}>
    <Header backActionText="Back to Dashboard" onBackActionClick={() => {}} />
    <div className={styles.inner}>{children}</div>
  </div>
);

export default React.memo(ProfileLayout);
