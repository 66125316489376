export const UNSUBSCRIBE_PAGE_TAGS = [
  'Content not relevant',
  'Too many emails',
  'Study on another platform/bought another course',
  'Too much promotional content',
  'Completed desired courses',
  'Other',
];
export const UNSUBSCRIBE_STEPS = {
  CHOOSE_REASON: 'CHOOSE_REASON',
  REASON_SUBMITTED: 'REASON_SUBMITTED',
  RESUBSCRIBED: 'RESUBSCRIBED',
};
