import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import * as api from 'api/methods';

import { convertDataToApi } from './ReportLessonModal.helpers';

import ReportLessonModal from './ReportLessonModal';

const ReportLessonModalContainer = ({ onClose, ...restProps }) => {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const { courseId, lessonId } = useParams();

  const handleSubmitClick = async (values) => {
    try {
      setLoading(true);

      await api.reportLesson({
        courseId,
        lessonId,
        data: convertDataToApi(values),
      });
    } catch (error) {
      console.warn(error);
    }

    setLoading(false);

    setCurrentStep(2);
  };

  return (
    <ReportLessonModal
      {...restProps}
      loading={loading}
      currentStep={currentStep}
      onClose={onClose}
      onSubmitClick={handleSubmitClick}
    />
  );
};

export default React.memo(ReportLessonModalContainer);
