import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logEvent } from 'helpers/logEvent';
import { getSchoolNames } from 'helpers/getSchoolNames';

import { userActivitySelector, userSelector } from 'store/auth/selectors';
import { courseSelector } from 'store/course/selectors';

import UnlockLessonsSection from './UnlockLessonsSection';

const UnlockLessonsSectionContainer = (props) => {
  const navigate = useNavigate();

  const user = useSelector(userSelector);
  const userActivity = useSelector(userActivitySelector);
  const course = useSelector(courseSelector);

  const handleGoToProClick = () => {
    logEvent({
      eventType: 'Course_Page - Go_Pro_Clicked',
      eventProps: {
        course_title: course?.title,
      },
      userProps: {
        user_email: user.email,
        user_is_pro: userActivity.isProSubscription,
        user_school: getSchoolNames(user.schools),
      },
    });

    navigate('/pricing');
  };

  return (
    <UnlockLessonsSection {...props} onGoToProClick={handleGoToProClick} />
  );
};

export default React.memo(UnlockLessonsSectionContainer);
