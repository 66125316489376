export const getFileTypeIcon = (type) => {
  switch (type) {
    case 'pdf': {
      return 'pdfFile';
    }
    case 'image/jpeg':
    case 'image/png': {
      return 'imageFile';
    }
    case 'video/avi':
    case 'video/mov': {
      return 'videoFile';
    }
  }
};
