import React from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './Section.styles.scss';

const Section = ({ className, withLink, linkTo, children, title }) => {
  const navigate = useNavigate();

  return (
    <div className={cn(styles.section, className)}>
      <div className={styles.header}>
        <Typography variant="heading2" className={styles.title}>
          {title}
        </Typography>
        {linkTo && withLink && (
          <Button
            size="sm"
            variant="link"
            endIcon={<Icon name="arrowRight" />}
            onClick={() => navigate(linkTo)}
          >
            View all
          </Button>
        )}
      </div>
      <div className={styles.inner}>{children}</div>
    </div>
  );
};

Section.defaultProps = {
  withLink: false,
};

export default React.memo(Section);
