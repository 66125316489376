import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { logEvent } from 'helpers/logEvent';
import { getSchoolNames } from 'helpers/getSchoolNames';

import { userActivitySelector, userSelector } from 'store/auth/selectors';
import { courseSelector } from 'store/course/selectors';
import { lessonSelector } from 'store/lesson/selectors';

import { isAnswersCorrect, randomizeLessonAnswers } from './QuizPage.helpers';

import QuizPage from './QuizPage';

const QuizPageContainer = (props) => {
  const questionsSwiperRef = useRef();

  const user = useSelector(userSelector);
  const userActivity = useSelector(userActivitySelector);
  const course = useSelector(courseSelector);
  const lesson = useSelector(lessonSelector);

  const [submitted, setSubmitted] = useState(false);
  const [currentAnswers, setCurrentAnswers] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [shuffledLesson, setShuffledLesson] = useState(
    randomizeLessonAnswers(lesson),
  );

  useEffect(() => {
    if (questionsSwiperRef.current) {
      questionsSwiperRef.current.updateAutoHeight(0);
    }
  }, [currentAnswers]);

  useEffect(() => {
    logEvent({
      eventType: 'Lesson_Page - Viewed',
      eventProps: {
        course_title: course.title,
        lesson_title: lesson?.title,
      },
      userProps: {
        user_email: user.email,
        user_is_pro: userActivity.isProSubscription,
        user_school: getSchoolNames(user.schools),
      },
    });
  }, []);

  const handleQuestionsSwiper = (swiper) => {
    questionsSwiperRef.current = swiper;
  };

  const handleCheckClick = () => {
    setSubmitted(true);
  };

  const handleAnswerClick = ({ answer, answersMultiple }) => {
    if (!answersMultiple) {
      setSubmitted(true);

      setCurrentAnswers([answer.id]);

      return;
    }

    if (currentAnswers.indexOf(answer.id) > -1) {
      setCurrentAnswers(
        currentAnswers.filter((answerId) => answerId !== answer.id),
      );
    } else {
      setCurrentAnswers([...currentAnswers, answer.id]);
    }
  };

  const handleTryAgainClick = () => {
    setCurrentAnswers([]);
    setSubmitted(false);
    setShuffledLesson(randomizeLessonAnswers(lesson));
  };

  const handleNextQuestionClick = () => {
    if (currentQuestion + 1 < lesson.questions.length) {
      setCurrentAnswers([]);
      setCurrentQuestion(currentQuestion + 1);
      setSubmitted(false);

      if (questionsSwiperRef.current) {
        questionsSwiperRef.current.slideNext();
      }
    }
  };

  const correct = isAnswersCorrect({
    questions: lesson.questions,
    currentAnswers,
    currentQuestionIndex: currentQuestion,
  });

  return (
    <QuizPage
      {...props}
      correct={correct}
      submitted={submitted}
      questions={shuffledLesson.questions}
      currentQuestion={currentQuestion}
      currentAnswers={currentAnswers}
      onQuestionsSwiper={handleQuestionsSwiper}
      onCheckClick={handleCheckClick}
      onAnswerClick={handleAnswerClick}
      onTryAgainClick={handleTryAgainClick}
      onNextQuestionClick={handleNextQuestionClick}
    />
  );
};

export default React.memo(QuizPageContainer);
