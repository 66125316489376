import React from 'react';
import ReactPlayer from 'react-player';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import { Popover } from 'components/shared/Popover';

import { ValueBar } from './components/ValueBar';

import { PLAYBACK_RATES } from './VideoBlock.constants';

import styles from './VideoBlock.styles.scss';

const VideoBlock = ({
  className,
  playerRef,
  playerContainerRef,
  url,
  caption,
  playerSettings,
  progress,
  onProgress,
  onLoopVideo,
  onSettingsChange,
}) => (
  <div className={className}>
    <div className={styles.playerContainer} ref={playerContainerRef}>
      <ReactPlayer
        ref={playerRef}
        className={styles.video}
        width="100%"
        height="auto"
        url={url}
        progressInterval={1}
        onEnded={onLoopVideo}
        onProgress={onProgress}
        {...playerSettings}
      />
      <div className={styles.progress}>
        <ValueBar
          className={styles.progressBar}
          trackClassName={styles.track}
          thumbClassName={styles.thumb}
          value={progress.percent}
          onChange={(value) => onSettingsChange('CHANGE_TIMING', value)}
        />
        <div className={styles.controls}>
          <div className={styles.leftControls}>
            <Icon
              name={playerSettings.playing ? 'pause' : 'play'}
              className={styles.icon}
              onClick={() => onSettingsChange('PLAY_PAUSE')}
            />
            <Typography
              variant="body3"
              weight="medium"
              mode="compact"
              className={styles.timing}
            >
              {progress.current} / {progress.total}
            </Typography>
          </div>
          <div className={styles.rightControls}>
            <Icon
              name={playerSettings.muted ? 'volumeOff' : 'volumeUp'}
              className={styles.icon}
              onClick={() => onSettingsChange('MUTE_UNMUTE')}
            />
            <ValueBar
              className={styles.volume}
              value={playerSettings.volume}
              onChange={(value) => onSettingsChange('CHANGE_VOLUME', value)}
            />
            <Popover
              placement="top-end"
              triggerElement={() => (
                <div className={styles.playbackTrigger}>
                  <Icon name="playSpeed" className={styles.icon} />
                  <Typography
                    variant="body3"
                    weight="medium"
                    mode="compact"
                    className={styles.playbackRate}
                  >
                    {playerSettings.playbackRate === 1
                      ? 'Normal'
                      : playerSettings.playbackRate}
                  </Typography>
                </div>
              )}
            >
              <div className={styles.popover}>
                {PLAYBACK_RATES.map((rate) => (
                  <div
                    key={rate}
                    className={styles.rate}
                    onClick={() => onSettingsChange('CHANGE_RATE', rate)}
                  >
                    <Typography
                      variant="body3"
                      weight="medium"
                      mode="compact"
                      className={styles.rateText}
                    >
                      {rate === 1 ? 'Normal' : rate}
                    </Typography>
                    {playerSettings.playbackRate === rate && (
                      <Icon name="check" className={styles.rateIcon} />
                    )}
                  </div>
                ))}
              </div>
            </Popover>
            <Icon
              name={playerSettings.fullScreen ? 'fullscreenExit' : 'fullscreen'}
              className={styles.icon}
              onClick={() => onSettingsChange('FULL_SCREEN')}
            />
          </div>
        </div>
      </div>
    </div>
    {caption && (
      <Typography className={styles.caption} variant="body2">
        {caption}
      </Typography>
    )}
  </div>
);

export default React.memo(VideoBlock);
