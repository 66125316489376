import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import { getStatusIcon, getStatusText } from './StatusTag.helpers.js';

import styles from './StatusTag.styles.scss';

const StatusTag = ({ progress, completed, className }) => (
  <div
    className={cn(
      styles.status,
      {
        [styles.notStarted]: progress === 0,
        [styles.inProgress]: progress > 0 && !completed,
        [styles.completed]: completed,
      },
      className,
    )}
  >
    <Icon
      name={getStatusIcon({ progress, completed })}
      className={styles.icon}
    />
    <Typography className={styles.statusText} variant="body3">
      {getStatusText({ progress, completed })}
    </Typography>
  </div>
);

StatusTag.defaultProps = {
  completed: false,
  progress: 0,
};

export default React.memo(StatusTag);
