import React from 'react';
import cn from 'classnames';

import { ROUTES } from 'constants/routes';

import CookiesIcon from 'assets/img/cookies.svg';

import { Link } from 'components/shared/Link';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import styles from './CookieBanner.styles.scss';

const CookieBanner = ({
  className,
  onAcceptCookieClick,
  onDeclineCookieClick,
}) => (
  <div className={cn(styles.banner, className)}>
    <div className={styles.inner}>
      <CookiesIcon className={styles.icon} />
      <div className={styles.main}>
        <Typography variant="body1" mode="compact" className={styles.title}>
          We use cookies
        </Typography>
        <Typography
          variant="body2"
          mode="compact"
          className={styles.description}
        >
          to give you a better experience. By using our website you agree to{' '}
          <Link
            variant="body2"
            mode="compact"
            className={styles.descriptionLink}
            to={ROUTES.PRIVACY_POLICY_PAGE}
          >
            our policies.
          </Link>
        </Typography>
      </div>
    </div>
    <div className={styles.controls}>
      <Button
        variant="ghost"
        className={styles.control}
        onClick={() => onAcceptCookieClick('disabled')}
      >
        Decline cookies
      </Button>
      <Button
        className={styles.control}
        onClick={() => onAcceptCookieClick('enabled')}
      >
        Accept & continue
      </Button>
    </div>
  </div>
);

export default React.memo(CookieBanner);
