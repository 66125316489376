import { RATE_MODAL_TYPES } from 'constants/common';

export const MODAL_TYPES = {
  [RATE_MODAL_TYPES.COMPLETED_COURSE]: {
    preview: 'course-completed',
    title: 'Course Completed!',
  },
  [RATE_MODAL_TYPES.COMPLETED_MODULE]: {
    preview: 'module-completed',
    title: 'Module passed!',
  },
};

export const RATE_MODAL_TAGS = [
  'Conflicting information & mistakes',
  'No real-life examples',
  'Too hard',
  'Too easy',
  'No structure',
  'Unclear instructions',
];
