import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import styles from './Menu.styles.scss';

const Menu = ({ expanded, onMenuClick, onSignInClick, onSignUpClick }) => (
  <div className={styles.menu}>
    <div className={styles.inner}>
      <Icon
        name={expanded ? 'cross' : 'burger'}
        className={styles.icon}
        onClick={onMenuClick}
      />
      <div className={styles.actions}>
        <Button
          variant="outlined"
          size="sm"
          className={styles.action}
          onClick={onSignInClick}
        >
          Log In
        </Button>
        <Button size="sm" className={styles.action} onClick={onSignUpClick}>
          Sign Up
        </Button>
      </div>
    </div>
    <div
      className={cn(styles.dropdown, {
        [styles.dropdownExpanded]: expanded,
      })}
    >
      <Typography
        className={styles.dropdownItem}
        component={NavLink}
        variant="body1"
        weight="medium"
        to="/pricing"
      >
        Pro access
      </Typography>
    </div>
  </div>
);

export default React.memo(Menu);
