import React from 'react';

import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';
import { Link } from 'components/shared/Link';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';

import { EnterEmailForm } from 'components/EnterEmailForm';
import { EnterCodeForm } from 'components/EnterCodeForm';

import { EnterPromoForm } from './components/EnterPromoForm';

import styles from './SignUpModal.styles.scss';

const SignUpModal = ({
  open,
  submitLoading,
  currentStep,
  currentEmail,
  setCurrentStep,
  setCurrentEmail,
  onClose,
  onRegistered,
  onFormSubmit,
  onBackClick,
  onHavePromocodeClick,
  onSignInClick,
}) => (
  <Modal className={styles.modal} centered open={open} onClose={onClose}>
    {currentStep === 'ENTER_EMAIL' && (
      <>
        <Typography
          className={styles.title}
          variant="subtitle"
          weight="semiBold"
        >
          Join Befront
        </Typography>
        <EnterEmailForm
          submitLoading={submitLoading}
          onSubmit={onFormSubmit}
          onHavePromocodeClick={onHavePromocodeClick}
        />
        <div className={styles.redirect}>
          <Typography variant="body2" className={styles.redirectText}>
            Already have an account?
          </Typography>
          <Link
            variant="body2"
            weight="medium"
            mode="compact"
            onClick={onSignInClick}
          >
            Login
          </Link>
        </div>
      </>
    )}
    {currentStep === 'ENTER_PROMOCODE' && (
      <>
        <Typography
          className={styles.title}
          variant="subtitle"
          weight="semiBold"
        >
          Join Befront
        </Typography>
        <EnterPromoForm
          currentEmail={currentEmail}
          setCurrentStep={setCurrentStep}
          setCurrentEmail={setCurrentEmail}
        />
      </>
    )}
    {currentStep === 'ENTER_CODE' && (
      <>
        <Button
          className={styles.backBtn}
          variant="link"
          size="sm"
          startIcon={<Icon name="arrowLeft" />}
          onClick={onBackClick}
        >
          Back
        </Button>
        <Typography
          variant="subtitle"
          weight="semiBold"
          className={styles.title}
        >
          Enter verification code
        </Typography>
        <Typography variant="body2" className={styles.text}>
          The verification code has been sent to{' '}
          <Typography
            variant="body2"
            weight="semiBold"
            component="span"
            className={styles.highlited}
          >
            {currentEmail}
          </Typography>
        </Typography>
        <EnterCodeForm
          currentEmail={currentEmail}
          onCloseModal={onClose}
          onRegistered={onRegistered}
        />
      </>
    )}
    <Typography className={styles.agreementText} variant="body3">
      By signing up, you agree to BeFront's{' '}
      <Link
        className={styles.agreementLink}
        variant="body3"
        target="_blank"
        href="https://befront.io/terms-of-use"
      >
        Terms of Service
      </Link>{' '}
      and{' '}
      <Link
        className={styles.agreementLink}
        variant="body3"
        target="_blank"
        href="https://befront.io/privacy-policy"
      >
        Privacy Policy
      </Link>
    </Typography>
  </Modal>
);

export default React.memo(SignUpModal);
