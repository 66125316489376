import React from 'react';
import { useDropzone } from 'react-dropzone';

import { useModal } from 'hooks/useModal';

import { DefaultModal } from 'components/DefaultModal';

import { getValidator } from './Upload.helpers';

import Upload from './Upload';

const UploadContainer = ({
  removeLoading,
  onDropAccepted,
  onDropRejected,
  onRemoveClick,
  ...restProps
}) => {
  const [modalOpen, openModal, closeModal] = useModal({ defaultOpen: false });

  const { getRootProps, getInputProps, open } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    noClick: true,
    noKeyboard: true,
    multiple: false,
    validator: getValidator,
    onDropAccepted: onDropAccepted,
    onDropRejected: onDropRejected,
  });

  const handleRemoveClick = () => {
    openModal();
  };

  const handleConfirmRemoveClick = async () => {
    try {
      await onRemoveClick();

      closeModal();
    } catch (error) {
      // TODO: add notification
    }
  };

  return (
    <>
      <Upload
        {...restProps}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        onBrowseClick={open}
        onRemoveClick={handleRemoveClick}
      />
      <DefaultModal
        open={modalOpen}
        loading={removeLoading}
        title="Delete Course Preview?"
        text="Do you really want to delete course preview?"
        cancelText="Cancel"
        confirmText="Delete"
        onClose={closeModal}
        onCancelClick={closeModal}
        onConfirmClick={handleConfirmRemoveClick}
      />
    </>
  );
};

export default React.memo(UploadContainer);
