import React, { useState, useRef, useEffect } from 'react';
import _flatten from 'lodash/flatten';
import axios from 'axios';

import { categories } from './FaqDrawer.config';

import FaqDrawer from './FaqDrawer';

const FaqDrawerContainer = (props) => {
  const swiperRef = useRef();

  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentCategoryItemIndex, setCurrentCategoryItemIndex] = useState(0);

  useEffect(() => {
    const imagesToLoad = _flatten(
      categories.map((category) =>
        category.items
          .filter((item) => !!item.imageUrl)
          .map((item) => item.imageUrl),
      ),
    );
    const videosToLoad = _flatten(
      categories.map((category) =>
        category.items
          .filter((item) => !!item.videoUrl)
          .map((item) => item.videoUrl),
      ),
    );

    const promises = [];

    promises.push(...imagesToLoad.map((imageToLoad) => axios.get(imageToLoad)));
    promises.push(...videosToLoad.map((videoToLoad) => axios.get(videoToLoad)));

    Promise.all(promises);
  }, []);

  const handleSwiper = (swiper) => {
    swiperRef.current = swiper;
  };

  const handleDownloadPluginClick = () => {
    window.open(
      'https://www.figma.com/community/plugin/946462257210461125/Befront',
      '_blank',
    );
  };

  const handleCategoryClick = (nextCategory) => {
    setCurrentCategory(nextCategory);
  };

  const handleCategoryBackClick = () => {
    setCurrentCategory(null);
    setCurrentCategoryItemIndex(0);
  };

  const handleCategoryItemBackClick = () => {
    setCurrentCategoryItemIndex((categoryItemIndex) => categoryItemIndex - 1);

    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleCategoryItemNextClick = () => {
    setCurrentCategoryItemIndex((categoryItemIndex) => categoryItemIndex + 1);

    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  return (
    <FaqDrawer
      {...props}
      currentCategory={currentCategory}
      currentCategoryItemIndex={currentCategoryItemIndex}
      onSwiper={handleSwiper}
      onCategoryClick={handleCategoryClick}
      onCategoryBackClick={handleCategoryBackClick}
      onCategoryItemBackClick={handleCategoryItemBackClick}
      onCategoryItemNextClick={handleCategoryItemNextClick}
      onDownloadPluginClick={handleDownloadPluginClick}
    />
  );
};

export default React.memo(FaqDrawerContainer);
