import React from 'react';
import { useLocation } from 'react-router-dom';

import Sidebar from './Sidebar';

const SidebarContainer = (props) => {
  const location = useLocation();

  const haveReviewSection = location.pathname.indexOf('/account') === -1;

  return <Sidebar {...props} haveReviewSection={haveReviewSection} />;
};

export default React.memo(SidebarContainer);
