import React from 'react';

import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';
import { Loading } from 'components/shared/Loading';

const Action = ({ nextLoading, isLastLessonInCourse, onClick }) => {
  if (isLastLessonInCourse) {
    return <Button onClick={onClick}>Finish Course</Button>;
  }

  return (
    <Button
      startIcon={nextLoading && <Loading color="var(--color-white-primary)" />}
      endIcon={<Icon name="arrowRight" />}
      onClick={onClick}
    >
      Next Lesson
    </Button>
  );
};

export default React.memo(Action);
