import React from 'react';

import { Loading } from 'components/shared/Loading';

import { ProfileLayout } from 'components/Layout';

import { UserSection } from './components/UserSection';
import { MyCoursesSection } from './components/MyCoursesSection';
import { MyFavoritesSection } from './components/MyFavoritesSection';

import styles from './ProfilePage.styles.scss';

const ProfilePage = ({
  loading,
  myCourses,
  favoriteCourses,
  onCourseCardClick,
}) => (
  <ProfileLayout>
    <div className={styles.page}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <UserSection />
          <MyCoursesSection
            courses={myCourses}
            onCourseCardClick={onCourseCardClick}
          />
          <MyFavoritesSection
            courses={favoriteCourses}
            onCourseCardClick={onCourseCardClick}
          />
        </>
      )}
    </div>
  </ProfileLayout>
);

export default React.memo(ProfilePage);
