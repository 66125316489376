import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { userActivitySelector, userSelector } from 'store/auth/selectors';
import { getCourseById, startCourseById } from 'store/course/actions';
import { courseSelector } from 'store/course/selectors';

import { logEvent } from 'helpers/logEvent';
import { getSchoolNames } from 'helpers/getSchoolNames';

import { useModal } from 'hooks/useModal';

import { UnlockLessonsModal } from 'components/UnlockLessonsModal';

import {
  canStartCourse,
  getFirstLesson,
  getNextLesson,
} from './CoursePage.helpers';

import CoursePage from './CoursePage';
import { COURSE_TYPES } from '../../constants/common';

const CoursePageContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id: courseId } = useParams();

  const user = useSelector(userSelector);
  const userActivity = useSelector(userActivitySelector);
  const course = useSelector(courseSelector);

  const [loading, setLoading] = useState(true);
  const [startLoading, setStartLoading] = useState(false);

  const [unlockLessonsOpen, openUnlockLessons, closeUnlockLessons] = useModal({
    defaultOpen: false,
  });

  useEffect(() => {
    setLoading(true);

    const _getCourseById = async () => {
      try {
        const nextCourse = await dispatch(getCourseById(courseId)).unwrap();

        logEvent({
          eventType: 'Course_Page - Viewed',
          eventProps: {
            course_title: nextCourse.title,
          },
          userProps: {
            user_email: user.email,
            user_is_pro: userActivity.isProSubscription,
            user_school: getSchoolNames(user.schools),
          },
        });
      } catch (error) {
        console.warn(error);
      }

      setLoading(false);
    };

    _getCourseById();
  }, [courseId]);

  const handleStartClick = async () => {
    setStartLoading(true);

    logEvent({
      eventType: 'Course_Page - Start_Course_Clicked',
      eventProps: {
        course_title: course?.title,
      },
      userProps: {
        user_email: user.email,
        user_is_pro: userActivity.isProSubscription,
        user_school: getSchoolNames(user.schools),
      },
    });

    if (!isProSubscription && course.premium && !canStartCourse(course)) {
      setStartLoading(false);

      navigate('/pricing');

      return;
    }

    const nextLesson = getFirstLesson({
      canStartCourse: canStartCourse(course),
      course,
    });

    setStartLoading(false);

    if (course.type === COURSE_TYPES.FIGMA_PLUGIN) {
      navigate(`/courses/${course.id}/before-start`);

      return;
    }

    await dispatch(startCourseById(courseId));

    navigate(`/courses/${course.id}/lessons/${nextLesson.id}`);
  };

  const handleContinueClick = () => {
    logEvent({
      eventType: 'Course_Page - Continue_Course_Clicked',
      eventProps: {
        course_title: course?.title,
      },
      userProps: {
        user_email: user.email,
        user_is_pro: userActivity.isProSubscription,
        user_school: getSchoolNames(user.schools),
      },
    });

    const nextLesson = getNextLesson({
      canStartCourse: canStartCourse(course),
      course,
    });

    if (!course.available && !nextLesson.free) {
      openUnlockLessons();

      return;
    }

    if (course.type === COURSE_TYPES.FIGMA_PLUGIN) {
      navigate(`/courses/${course.id}/before-start`);

      return;
    }

    navigate(`/courses/${course.id}/lessons/${nextLesson.id}`);
  };

  const isProSubscription = userActivity
    ? userActivity.isProSubscription
    : false;
  const haveModules = !loading
    ? course.modules && course.modules.length
    : false;

  return (
    <>
      <CoursePage
        loading={loading}
        startLoading={startLoading}
        isProSubscription={isProSubscription}
        haveModules={haveModules}
        course={course}
        onStartClick={handleStartClick}
        onContinueClick={handleContinueClick}
      />
      <UnlockLessonsModal
        open={unlockLessonsOpen}
        onClose={closeUnlockLessons}
      />
    </>
  );
};

export default React.memo(CoursePageContainer);
