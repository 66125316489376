import React from 'react';
import cn from 'classnames';

import { COURSE_TYPES } from 'constants/course';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';
import { RoundProgressBar } from 'components/shared/RoundProgressBar';

import { CoursePreview } from 'components/CoursePreview';
import { FavoriteIcon } from 'components/FavoriteIcon';
import { CompletedIcon } from 'components/CompletedIcon';

import styles from './CourseCard.styles.scss';

const CourseCard = ({
  className,
  withFavorite,
  favorite,
  id,
  type,
  preview,
  title,
  progress,
  lessonsLeft,
  onClick,
}) => (
  <div className={cn(styles.course, className)} onClick={() => onClick(id)}>
    <CoursePreview className={styles.preview} preview={preview} />
    <div className={styles.inner}>
      <Typography
        className={styles.title}
        variant="body2"
        mode="compact"
        weight="medium"
      >
        {title}
      </Typography>
    </div>
    {withFavorite ? (
      <FavoriteIcon courseId={id} favorite={favorite} />
    ) : (
      <div className={styles.progress}>
        <div className={styles.item}>
          <Icon
            className={styles.itemIcon}
            name={type === COURSE_TYPES.WEB ? 'web' : 'figma'}
          />
          <Typography
            className={styles.itemText}
            variant="body3"
            weight="medium"
          >
            {type === COURSE_TYPES.WEB ? 'Web' : 'Plugin'}
          </Typography>
        </div>
        {progress === 100 ? (
          <div className={styles.completedItem}>
            <CompletedIcon
              className={styles.completedItemIconWrapper}
              iconClassName={styles.completedItemIcon}
            />
            <Typography
              className={styles.completedItemText}
              variant="body3"
              weight="medium"
            >
              Completed
            </Typography>
          </div>
        ) : (
          <>
            <div className={styles.item}>
              <RoundProgressBar
                lineSize={12}
                progress={progress}
                size={20}
                withProgressText={false}
                backLineColor="var(--color-green-10)"
                progressColor="var(--color-green-primary)"
              />
              <Typography
                className={styles.itemText}
                variant="body3"
                weight="medium"
              >
                {progress}% completed
              </Typography>
            </div>
            <div className={styles.item}>
              <Typography
                className={styles.itemText}
                variant="body3"
                weight="medium"
              >
                {lessonsLeft} lessons left
              </Typography>
            </div>
          </>
        )}
      </div>
    )}
  </div>
);

export default React.memo(CourseCard);
