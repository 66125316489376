import { ROUTES } from 'constants/routes';

export const checkRoutePublic = (routePath) => {
  if (routePath === ROUTES.HOME_PAGE) {
    return true;
  }

  if (routePath === ROUTES.PRIVACY_POLICY_PAGE) {
    return true;
  }

  if (routePath === ROUTES.TERMS_PAGE) {
    return true;
  }

  if (routePath === ROUTES.FAQ_PAGE) {
    return true;
  }

  if (routePath === ROUTES.PRICING_PAGE) {
    return true;
  }

  if (routePath === ROUTES.UNSUBSCRIBE_PAGE) {
    return true;
  }

  return false;
};
