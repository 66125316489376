import * as Yup from 'yup';

export const initialValues = {
  avatar: '',
  firstName: '',
  lastName: '',
};

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required.'),
  lastName: Yup.string().required('Last Name is required.'),
});
