import React from 'react';

import { Typography } from 'components/shared/Typography';

import styles from './FaqList.styles.scss';

const FaqList = ({ items }) => (
  <div className={styles.list}>
    {items.map((item, itemIndex) => (
      <div key={itemIndex} className={styles.item}>
        <div className={styles.itemNumberWrapper}>
          <Typography
            className={styles.itemNumber}
            variant="body3"
            weight="medium"
          >
            {itemIndex + 1}
          </Typography>
        </div>
        <Typography className={styles.itemText} variant="body2">
          {item}
        </Typography>
      </div>
    ))}
  </div>
);

export default React.memo(FaqList);
