import React from 'react';
import { Field, getIn } from 'formik';

import { FormControl } from 'components/shared/FormControl';

const FormikField = ({
  withControl,
  name,
  component: Component,
  controlProps,
  componentProps,
}) => (
  <Field name={name}>
    {({ field, form }) => {
      const error = getIn(form.errors, field.name);
      const touched = getIn(form.touched, field.name);

      const haveError = touched && !!error;

      if (!withControl) {
        return (
          <Component
            haveError={haveError}
            name={name}
            error={error}
            field={field}
            form={form}
            {...componentProps}
          />
        );
      }

      return (
        <FormControl haveError={haveError} error={error} {...controlProps}>
          <Component
            haveError={haveError}
            name={name}
            field={field}
            form={form}
            {...componentProps}
          />
        </FormControl>
      );
    }}
  </Field>
);

FormikField.defaultProps = {
  withControl: true,
};

export default FormikField;
