import { configureStore } from '@reduxjs/toolkit';

import authReducer from './auth';
import courseReducer from './course';
import lessonReducer from './lesson';

export default configureStore({
  reducer: {
    auth: authReducer,
    currentCourse: courseReducer,
    currentLesson: lessonReducer,
  },
});
