import React from 'react';

import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';
import { Loading } from 'components/shared/Loading';

import { isAnswersMultiple } from '../../QuizPage.helpers';

const Action = ({
  nextLoading,
  isLastLessonInCourse,
  submitted,
  correct,
  questions,
  currentAnswers,
  currentQuestion,
  onNextQuestionClick,
  onCheckClick,
  onNextLessonClick,
  onTryAgainClick,
}) => {
  const question = questions[currentQuestion];

  if (isAnswersMultiple(question) && !submitted) {
    return (
      <Button
        disabled={currentAnswers.length < 1}
        endIcon={<Icon name="arrowRight" />}
        onClick={onCheckClick}
      >
        Check
      </Button>
    );
  }

  if (!correct && submitted) {
    return <Button onClick={onTryAgainClick}>Try Again</Button>;
  }

  if (
    correct &&
    questions.length - 1 === currentQuestion &&
    !isLastLessonInCourse
  ) {
    return (
      <Button
        disabled={!submitted}
        startIcon={
          nextLoading && <Loading color="var(--color-white-primary)" />
        }
        endIcon={<Icon name="arrowRight" />}
        onClick={onNextLessonClick}
      >
        Next Lesson
      </Button>
    );
  }

  if (
    correct &&
    questions.length - 1 === currentQuestion &&
    isLastLessonInCourse
  ) {
    return (
      <Button
        disabled={!submitted}
        startIcon={
          nextLoading && <Loading color="var(--color-white-primary)" />
        }
        endIcon={<Icon name="arrowRight" />}
        onClick={onNextLessonClick}
      >
        Finish Course
      </Button>
    );
  }

  return (
    <Button
      disabled={!submitted}
      endIcon={<Icon name="arrowRight" />}
      onClick={onNextQuestionClick}
    >
      Next Question
    </Button>
  );
};

export default React.memo(Action);
