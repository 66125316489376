import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import Logo from 'assets/img/logo.svg';

import { Button } from 'components/shared/Button';

import { UserMenu } from 'components/UserMenu';
import { LessonMenu } from 'components/LessonMenu';

import styles from './Header.styles.scss';

const Header = ({
  className,
  isProSubscription,
  withLogo,
  withMenu,
  onGoProClick,
  onFaqClick,
}) => (
  <div
    className={cn(
      styles.header,
      { [styles.headerWithLogo]: withLogo },
      className,
    )}
  >
    {withLogo && (
      <NavLink to="/dashboard" className={styles.logoLink}>
        <Logo className={styles.logo} />
      </NavLink>
    )}
    {withMenu && <LessonMenu />}

    <div className={styles.inner}>
      {!isProSubscription && (
        <Button size="sm" className={styles.proButton} onClick={onGoProClick}>
          Go Pro
        </Button>
      )}

      <Button
        className={styles.faqBtn}
        variant="ghost"
        mode="icon"
        size="md"
        iconName="question"
        onClick={onFaqClick}
      />

      <UserMenu className={styles.userMenu} />
    </div>
  </div>
);

Header.defaultProps = {
  withLogo: true,
  withMenu: false,
};

export default React.memo(Header);
