import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactPlayer from 'react-player';
import cn from 'classnames';

import Faq1Icon from 'assets/img/faq-1.svg';
import Faq2Icon from 'assets/img/faq-2.svg';
import Faq3Icon from 'assets/img/faq-3.svg';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';
import { Drawer } from 'components/shared/Drawer';

import { FaqList } from 'components/FaqList';

import { categories } from './FaqDrawer.config';

import styles from './FaqDrawer.styles.scss';

const _renderCategoryIcon = (categoryKey) => {
  switch (categoryKey) {
    case 'what-is-befront': {
      return (
        <Faq1Icon
          className={cn(styles.categoryIcon, styles.faq1CategoryIcon)}
        />
      );
    }
    case 'how-to-install-plugin': {
      return (
        <Faq2Icon
          className={cn(styles.categoryIcon, styles.faq2CategoryIcon)}
        />
      );
    }
    case 'how-to-run-plugin': {
      return (
        <Faq3Icon
          className={cn(styles.categoryIcon, styles.faq3CategoryIcon)}
        />
      );
    }
    default: {
      return null;
    }
  }
};

const FaqDrawer = ({
  open,
  currentCategory,
  currentCategoryItemIndex,
  onClose,
  onSwiper,
  onCategoryClick,
  onCategoryBackClick,
  onCategoryItemBackClick,
  onCategoryItemNextClick,
  onDownloadPluginClick,
}) => (
  <Drawer open={open} headerContent="FAQ" onClose={onClose}>
    <div className={styles.inner}>
      {currentCategory === null ? (
        <>
          <div className={styles.section}>
            <Typography
              className={styles.sectionTitle}
              variant="body1"
              mode="compact"
              weight="medium"
            >
              Getting started
            </Typography>
            <div className={styles.sectionInner}>
              <div className={styles.categories}>
                {categories.map((category, index) => (
                  <div
                    key={category.key}
                    className={cn(styles.category, {
                      [styles.expanded]: index === 2,
                    })}
                    onClick={() => onCategoryClick(category)}
                  >
                    {_renderCategoryIcon(category.key)}
                    <Typography variant="body1" mode="compact" weight="medium">
                      {category.title}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Button
            variant="outlined"
            className={styles.button}
            startIcon={<Icon name="download" />}
            onClick={onDownloadPluginClick}
          >
            Download Figma plugin
          </Button>
        </>
      ) : (
        <>
          <Button
            variant="link"
            size="sm"
            startIcon={<Icon name="arrowLeft" />}
            onClick={onCategoryBackClick}
          >
            Back
          </Button>
          <Typography className={styles.categoryTitle} variant="heading2">
            {currentCategory.title}
          </Typography>

          <div className={styles.categoryInner}>
            {currentCategory.items.length === 1 ? (
              <div className={styles.item}>
                <ReactPlayer
                  className={styles.itemVideo}
                  width="346px"
                  height="248px"
                  url={currentCategory.items[0].videoUrl}
                  playing
                  loop
                />
                {currentCategory.items[0].title && (
                  <Typography
                    className={styles.itemTitle}
                    variant="body1"
                    weight="semiBold"
                  >
                    {currentCategory.items[0].title}
                  </Typography>
                )}
                {currentCategory.items[0].description && (
                  <Typography
                    className={styles.itemDescription}
                    variant="body2"
                  >
                    {currentCategory.items[0].description}
                  </Typography>
                )}
                {currentCategory.items[0].list && (
                  <FaqList items={currentCategory.items[0].list} />
                )}
              </div>
            ) : (
              <>
                <Swiper
                  className={styles.swiper}
                  allowTouchMove={false}
                  spaceBetween={16}
                  slidesPerView={1}
                  onSwiper={onSwiper}
                >
                  {currentCategory.items.map((item, itemIndex) => (
                    <SwiperSlide key={item.key}>
                      <div className={styles.item}>
                        {item.videoUrl && (
                          <ReactPlayer
                            className={styles.itemVideo}
                            width="346px"
                            height="248px"
                            url={item.videoUrl}
                            playing={currentCategoryItemIndex === itemIndex}
                            loop
                          />
                        )}
                        {item.imageUrl && (
                          <img
                            className={styles.itemImage}
                            src={item.imageUrl}
                            playing={currentCategoryItemIndex === itemIndex}
                          />
                        )}
                        {item.title && (
                          <Typography
                            className={styles.itemTitle}
                            variant="body1"
                            weight="semiBold"
                          >
                            {item.title}
                          </Typography>
                        )}
                        <Typography
                          className={styles.itemDescription}
                          variant="body2"
                        >
                          {item.description}
                        </Typography>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className={styles.footer}>
                  <div className={styles.dots}>
                    {currentCategory.items.map((item, itemIndex) => (
                      <div
                        key={item.key}
                        className={cn(styles.dot, {
                          [styles.dotActive]:
                            currentCategoryItemIndex === itemIndex,
                        })}
                      />
                    ))}
                  </div>
                  <div className={styles.actions}>
                    {currentCategoryItemIndex > 0 && (
                      <Button
                        className={styles.action}
                        variant="outlined"
                        size="sm"
                        startIcon={<Icon name="arrowLeft" />}
                        onClick={onCategoryItemBackClick}
                      >
                        Previous
                      </Button>
                    )}

                    <Button
                      className={styles.action}
                      disabled={
                        currentCategoryItemIndex ===
                        currentCategory.items.length - 1
                      }
                      size="sm"
                      endIcon={<Icon name="arrowRight" />}
                      onClick={onCategoryItemNextClick}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  </Drawer>
);

export default React.memo(FaqDrawer);
