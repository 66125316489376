export const items = [
  {
    order: 1,
    title: 'Real-life tasks in Figma',
    items: [
      {
        iconKey: '1-1',
        text: 'Learn by doing with our interactive Figma Plugin',
      },
      {
        iconKey: '1-2',
        text: 'Accomplish bite-size design tasks in Figma and get instant feedback on your design from the system',
      },
      {
        iconKey: '1-3',
        text: 'See your mistakes and correct them in real-time. Rapid and clear feedback ensures fast learning',
      },
    ],
  },
  {
    order: 2,
    title: 'Comprehensive and structured theory',
    items: [
      {
        iconKey: '2-1',
        text: 'Acquire design foundations through our step-by-step guided tutorials created by world renowed professionals',
      },
      {
        iconKey: '2-2',
        text: 'From the very basics to advanced level in bite-size chunks',
      },
    ],
  },
  {
    order: 3,
    title: 'Interactive quizzes',
    items: [
      {
        iconKey: '3-1',
        text: 'Strengthen your knowledge through repetition',
      },
      {
        iconKey: '3-2',
        text: 'Solidify learned materials through interactive tests and quizzes',
      },
      {
        iconKey: '3-3',
        text: 'Gamified mechanics keep it simple and fun',
      },
    ],
  },
  {
    order: 4,
    title: 'Eye-trainings exercises based on the best market practices',
    items: [
      {
        iconKey: '4-1',
        text: 'Train your eye to make the best design decisions',
      },
      {
        iconKey: '4-2',
        text: 'Learn from the best market practices and acquire eagle eye skills to notice even slightest design errors',
      },
    ],
  },
];
