import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import { Item } from './components/Item';

import { items } from './PracticeSection.config';

import styles from './PracticeSection.styles.scss';

const PracticeSection = () => (
  <div className={cn(styles.section, 'section')}>
    <div className={styles.headerMobile}>
      <Typography className={styles.mainTitle} family="prompt">
        Practice
      </Typography>
      <Typography className={styles.title} family="prompt">
        makes perfect
      </Typography>
      <Typography className={styles.captionTitle} family="prompt">
        JUST KEEP LEARNING
      </Typography>
    </div>
    <div className={styles.header}>
      <div className={styles.headerRow}>
        <Typography className={styles.mainTitle} family="prompt">
          Practice
        </Typography>
        <Typography className={styles.title} family="prompt">
          makes
        </Typography>
      </div>
      <div className={styles.headerRow}>
        <Typography className={styles.title} family="prompt">
          perfect
        </Typography>
        <Typography className={styles.captionTitle} family="prompt">
          JUST KEEP LEARNING
        </Typography>
      </div>
    </div>
    <div className={styles.inner}>
      {items.map((item) => (
        <Item key={item.order} {...item} />
      ))}
    </div>
    {/* <button className={styles.button}>Start learning for free</button> */}
  </div>
);

export default React.memo(PracticeSection);
