import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { TOKEN_KEY } from 'constants/token';
import { ROUTES } from 'constants/routes';

import { getUserInfo } from 'store/auth/actions';

import { checkRoutePublic } from './SessionProvider.helpers';

import SessionProvider from './SessionProvider';

const SessionProviderContainer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const authToken = localStorage.getItem(TOKEN_KEY);

  const ignoreSession = false;

  const [loading, setLoading] = useState(ignoreSession ? false : !!authToken);

  useEffect(() => {
    if (!authToken && !checkRoutePublic(location.pathname)) {
      navigate(ROUTES.HOME_PAGE, { replace: true });

      return;
    }

    if (!authToken && location.pathname === '/') {
      navigate(
        { pathname: ROUTES.HOME_PAGE, search: location.search },
        { replace: true },
      );

      return;
    }

    const _checkAuthUser = async () => {
      try {
        await dispatch(getUserInfo());

        setLoading(false);

        if (location.pathname === '/') {
          navigate(ROUTES.DASHBOARD_PAGE, { replace: true });
        }
      } catch (error) {
        console.warn(error);

        setLoading(false);

        navigate('/', { replace: true });
      }
    };

    _checkAuthUser();
  }, []);

  return <SessionProvider {...props} loading={loading} />;
};

export default React.memo(SessionProviderContainer);
