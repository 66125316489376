import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as api from 'api/methods';

import { logEvent } from 'helpers/logEvent';
import { getSchoolNames } from 'helpers/getSchoolNames';

import { userSelector, userActivitySelector } from 'store/auth/selectors';

import { useFetch } from 'hooks/useFetch';

import { orderCourses } from './CoursesPage.helpers';

import CoursesPage from './CoursesPage';

const CoursesPageContainer = () => {
  const user = useSelector(userSelector);
  const userActivity = useSelector(userActivitySelector);

  const { loading, data: courses } = useFetch(
    {
      defaultData: [],
      fetcher: api.getCourses,
      immediately: true,
    },
    null,
    [],
  );

  useEffect(() => {
    logEvent({
      eventType: 'Courses_Page - Viewed',
      userProps: {
        user_email: user.email,
        user_is_pro: userActivity.isProSubscription,
        user_school: getSchoolNames(user.schools),
      },
    });
  }, []);

  return <CoursesPage loading={loading} courses={orderCourses(courses)} />;
};

export default React.memo(CoursesPageContainer);
