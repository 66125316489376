import { useEffect } from 'react';

export const useResponsive = () => {
  useEffect(() => {
    const appElement = document.querySelector('#bef-app');

    appElement.classList.add('responsive');

    return () => {
      appElement.classList.remove('responsive');
    };
  }, []);
};
