import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { TOKEN_KEY } from 'constants/token';

import * as api from 'api/methods';

import * as types from './types';

export const getUserInfo = createAsyncThunk(types.GET_USER_INFO, async () => {
  const user = await api.getUserInfo();
  const userActivity = await api.getUserActivity();

  return { user, userActivity };
});

export const updateUserInfo = createAsyncThunk(
  types.UPDATE_USER_INFO,
  async (data) => {
    const user = await api.updateUserInfo(data);

    return user;
  },
);

export const setCurrentUser = createAction(types.SET_CURRENT_USER);

export const logoutUser = createAction(types.LOGOUT_USER, () => {
  localStorage.removeItem(TOKEN_KEY);

  return Promise.resolve();
});
