import React, { useState } from 'react';

import * as api from 'api/methods';

import EnterPromoForm from './EnterPromoForm';

const EnterPromoFormContainer = ({
  currentEmail,
  setCurrentStep,
  setCurrentEmail,
}) => {
  const [continueLoading, setContinueLoading] = useState(false);

  const handleContinueClick = async (values, { setFieldError }) => {
    try {
      setContinueLoading(true);

      const submittedEmail = values.email.trim().toLowerCase();

      await api.sendCode({
        email: submittedEmail,
        code: values.code,
      });

      setCurrentEmail(submittedEmail);
      setCurrentStep('ENTER_CODE');
    } catch (error) {
      console.warn(error);

      setFieldError('code', error.message);
    }

    setContinueLoading(false);
  };

  return (
    <EnterPromoForm
      continueLoading={continueLoading}
      currentEmail={currentEmail}
      onContinueClick={handleContinueClick}
    />
  );
};

export default React.memo(EnterPromoFormContainer);
