import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormikContext } from 'formik';

import * as api from 'api/methods';

import { useModal } from 'hooks/useModal';

import { ConfirmModal } from 'components/ConfirmModal';

import { AvatarCrop } from './components/AvatarCrop';

import AvatarUpload from './AvatarUpload';

const AvatarUploadContainer = (props) => {
  const { values, setFieldValue } = useFormikContext();

  const [loading, setLoading] = useState(false);
  const [confirmRemoveLoading, setConfirmRemoveLoading] = useState(false);
  const [cropOpen, openCrop, closeCrop, cropData] = useModal({
    defaultOpen: false,
  });
  const [confirmRemoveOpen, openConfirmRemove, closeConfirmRemove] = useModal({
    defaultOpen: false,
  });

  const _onDrop = (files) => {
    const file = files[0];

    openCrop({ preview: URL.createObjectURL(file) });
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: _onDrop,
  });

  const handleSaveClick = async (blobFile) => {
    try {
      const data = new FormData();
      data.append('file', blobFile);

      setLoading(true);

      const response = await api.uploadFile({ data });

      setFieldValue('avatar', response.path);

      setLoading(false);

      closeCrop();
    } catch (error) {
      console.warn(error);

      setLoading(false);
    }
  };

  const handleConfirmRemoveClick = async () => {
    try {
      setConfirmRemoveLoading(true);

      await api.removeFile({ path: values.avatar });

      setFieldValue('avatar', '');

      closeConfirmRemove();
    } catch (error) {
      console.warn(error);
    }

    setConfirmRemoveLoading(false);
  };

  return (
    <>
      <AvatarUpload
        {...props}
        value={values.avatar}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        onAddClick={open}
        onRemoveClick={openConfirmRemove}
      />
      <AvatarCrop
        {...cropData}
        open={cropOpen}
        loading={loading}
        onClose={closeCrop}
        onSaveClick={handleSaveClick}
      />
      <ConfirmModal
        open={confirmRemoveOpen}
        confirmLoading={confirmRemoveLoading}
        title="Delete photo"
        text="Are you sure you want to delete the photo?"
        cancelText="Cancel"
        confirmText="Yes, delete"
        onClose={closeConfirmRemove}
        onCancelClick={closeConfirmRemove}
        onConfirmClick={handleConfirmRemoveClick}
      />
    </>
  );
};

export default React.memo(AvatarUploadContainer);
