import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { COURSE_TYPES } from '../../constants/common';

import { userActivitySelector, userSelector } from 'store/auth/selectors';

import { logEvent } from 'helpers/logEvent';
import { getSchoolNames } from 'helpers/getSchoolNames';

import { useModal } from 'hooks/useModal';

import {
  canStartCourse,
  getNextLesson,
  getNextCourse,
} from './MyCourseCard.helpers';

import MyCourseCard from './MyCourseCard';

const MyCourseCardContainer = ({ course, onCourseCardClick }) => {
  const navigate = useNavigate();

  const user = useSelector(userSelector);
  const userActivity = useSelector(userActivitySelector);

  const [openUnlockLessons] = useModal({
    defaultOpen: false,
  });

  const handleCourseCardClick = () => {
    if (onCourseCardClick) {
      onCourseCardClick({ id: course.id, title: course.title });
    }

    navigate(`/courses/${course.id}`);
  };

  const handleContinueClick = async () => {
    logEvent({
      eventType: 'Course_Page - Continue_Course_Clicked',
      eventProps: {
        course_title: course.title,
      },
      userProps: {
        user_email: user.email,
        user_is_pro: userActivity.isProSubscription,
        user_school: getSchoolNames(user.schools),
      },
    });

    let nextCourse = await getNextCourse(course);

    const nextLesson = getNextLesson({
      canStartCourse: canStartCourse(nextCourse),
      course: nextCourse,
    });

    if (!nextCourse.available && !nextLesson.free) {
      openUnlockLessons();

      return;
    }

    if (nextCourse.type === COURSE_TYPES.FIGMA_PLUGIN) {
      navigate(`/courses/${course.id}/before-start`);

      return;
    }

    navigate(`/courses/${course.id}/lessons/${nextLesson.id}`);
  };

  return (
    <MyCourseCard
      {...course}
      onCourseCardClick={handleCourseCardClick}
      onContinueClick={handleContinueClick}
    />
  );
};

export default React.memo(MyCourseCardContainer);
