import {
  ANNUALLY_PRICE_LOOKUP_KEY,
  MONTHLY_PRICE_LOOKUP_KEY,
} from 'constants/common';

export const formatPrices = (prices) =>
  prices.map((price) => {
    if (price.key === ANNUALLY_PRICE_LOOKUP_KEY) {
      const monthlyAmount = (price.amount / 12).toFixed(2);

      return {
        ...price,
        amount: monthlyAmount.split('.')[0],
        amountCents: monthlyAmount.split('.')[1],
        amountDiscount: 48,
      };
    }

    if (price.key === MONTHLY_PRICE_LOOKUP_KEY) {
      return {
        ...price,
        amount: price.amount.split('.')[0],
        amountCents: price.amount.split('.')[1],
      };
    }
  });
