import _flatten from 'lodash/flatten';

import { COURSE_STRUCTURES } from 'constants/common';

export const getFirstLesson = ({ canStartCourse, course }) => {
  if (course.structure === COURSE_STRUCTURES.MODULES) {
    const firstModule = !canStartCourse
      ? course.modules.find((module) =>
          module.lessons.some((lesson) => lesson.free),
        )
      : course.modules[0];

    const firstLesson = !canStartCourse
      ? firstModule.lessons.find((lesson) => lesson.free)
      : firstModule.lessons[0];

    return firstLesson;
  }

  if (course.structure === COURSE_STRUCTURES.LESSONS) {
    const firstLesson = !canStartCourse
      ? course.lessons.find((lesson) => lesson.free)
      : course.lessons[0];

    return firstLesson;
  }
};

export const getNextLesson = ({ canStartCourse, course }) => {
  if (course.structure === COURSE_STRUCTURES.MODULES) {
    const nextModule = course.modules.find(
      (module) => course.completedModules.indexOf(module.id) === -1,
    );

    if (!nextModule) {
      return getFirstLesson({ canStartCourse, course });
    }

    const lessons = nextModule.lessons;

    const nextLesson = lessons.find(
      (lesson) => course.completedLessons.indexOf(lesson.id) === -1,
    );

    if (!nextLesson) {
      return getFirstLesson({ canStartCourse, course });
    }

    return nextLesson;
  }

  if (course.structure === COURSE_STRUCTURES.LESSONS) {
    const nextLesson = course.lessons.find(
      (lesson) => course.completedLessons.indexOf(lesson.id) === -1,
    );

    if (!nextLesson) {
      return getFirstLesson({ canStartCourse, course });
    }

    return nextLesson;
  }
};

export const canStartCourse = (course) => {
  if (course.available) {
    return true;
  }

  if (course.structure === COURSE_STRUCTURES.LESSONS) {
    return course.lessons.some((lesson) => lesson.free);
  }

  if (course.structure === COURSE_STRUCTURES.MODULES) {
    const lessons = _flatten(course.modules.map((module) => module.lessons));

    return lessons.some((lesson) => lesson.free);
  }
};
