import axios from 'axios';
import _orderBy from 'lodash/orderBy';

// ------------------- META API ------------------- //
export const getMeta = () => axios.get('/api/v1/meta');
// ------------------- META API ------------------- //

// ------------------- AUTH API ------------------- //
export const sendCode = (data) => axios.post('/api/v1/auth/send-code', data);

export const verifyCode = (data) =>
  axios.post('/api/v1/auth/verify-code', data);

export const resendCode = (data) =>
  axios.post('/api/v1/auth/resend-code', data);

export const checkEmail = (data) =>
  axios.post('/api/v1/auth/check-email', data);

export const unsubscribeCurrentUser = (data) =>
  axios.post('/api/v1/auth/me/unsubscribe', data);

export const submitUnsubscribeCurrentUserReasons = (data) =>
  axios.post('/api/v1/auth/me/unsubscribe/reasons', data);

export const subscribeCurrentUser = (data) =>
  axios.post('/api/v1/auth/me/subscribe', data);
// ------------------- AUTH API ------------------- //

// ------------------- USERS API ------------------- //
export const getUserInfo = () => axios.get('/api/v1/auth/me');

export const getUserActivity = () => axios.get('/api/v1/auth/me/activity');

export const updateUserActivity = (data) =>
  axios.post('/api/v1/auth/me/activity', data);

export const updateUserInfo = (data) => axios.post('/api/v1/auth/me', data);

// ------------------- USERS API ------------------- //

// ------------------- COURSES API ------------------- //
export const getCourses = () => axios.get('/api/v1/courses');

export const getFavoriteCourses = () => axios.get('/api/v1/courses/favorite');

export const getProgressCourses = () => axios.get('/api/v1/courses/progress');

export const getFeaturedCourses = () => axios.get('/api/v1/courses/featured');

export const getCourseById = (courseId) =>
  axios.get(`/api/v1/courses/${courseId}`);

export const setCourseFavorite = ({ courseId, data }) =>
  axios.post(`/api/v1/courses/${courseId}/favorite`, data);
// ------------------- COURSES API ------------------- //

// ------------------- COURSE MODULES API ------------------- //
export const getCourseModules = (courseId) =>
  axios
    .get(`/api/v1/courses/${courseId}/modules`)
    .then((modules) => _orderBy(modules, ['order'], ['asc']));
// ------------------- COURSE MODULES API ------------------- //

// ------------------- COURSE LESSONS API ------------------- //
export const getCourseLessons = (courseId) =>
  axios
    .get(`/api/v1/courses/${courseId}/lessons`)
    .then((lessons) => _orderBy(lessons, ['order'], ['asc']));

export const getCourseLessonById = ({ courseId, lessonId }) =>
  axios.get(`/api/v1/courses/${courseId}/lessons/${lessonId}`);

export const completeCourseLesson = ({ courseId, lessonId, data }) =>
  axios.post(`/api/v1/courses/${courseId}/lessons/${lessonId}/complete`, data);
// ------------------- COURSE LESSONS API ------------------- //

// ------------------- USER REVIEWS API ------------------- //

export const createUserReview = (data) =>
  axios.post('/api/v1/user-reviews', data);
// ------------------- USER REVIEWS API ------------------- //

// ------------------- PAYMENT API ------------------- //
export const getPaymentConfig = () => axios.get('/api/v1/payments/config');

export const initPayment = (data) => axios.post('/api/v1/payments/init', data);

export const initSubscription = (data) =>
  axios.post('/api/v1/payments/subscription', data);

export const initBillingPortal = (data) =>
  axios.post('/api/v1/payments/billing-portal', data);
// ------------------- PAYMENT API ------------------- //

export const getLessonById = (lessonId) =>
  axios.get(`/api/v1//lessons/${lessonId}`);

// ------------------- UPLOAD API ------------------- //
export const uploadFile = ({ data, config }) =>
  axios.post('/api/v1/upload', data, {
    ...config,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
// ------------------- UPLOAD API ------------------- //

// ------------------- DELETE API ------------------- //
export const removeFile = (data) => axios.post('/api/v1/upload/remove', data);
// ------------------- DELETE API ------------------- //

export const startCourseById = (courseId) =>
  axios.post(`/api/v1/courses/${courseId}/start`);

export const createStudentReview = (data) =>
  axios.post('/api/v1/student-reviews', data);

export const reportLesson = ({ courseId, lessonId, data }) =>
  axios.post(`/api/v1/courses/${courseId}/lessons/${lessonId}/report`, data);

export const rateLesson = ({ courseId, lessonId, data }) =>
  axios.post(`/api/v1/courses/${courseId}/lessons/${lessonId}/rate`, data);

export const sendSchoolDemoRequest = (data) =>
  axios.post('/api/v1/common/demo-request', data);
