import React from 'react';
import pluralize from 'pluralize';

import { getFreeLessonsCount } from 'helpers/getFreeLessonsCount';

import { Tag } from 'components/shared/Tag';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';
import { RoundProgressBar } from 'components/shared/RoundProgressBar';

import { CoursePreview } from 'components/CoursePreview';

import { CourseInfo } from '../CourseInfo';

import styles from './MainSection.styles.scss';

const MainSection = ({
  isProSubscription,
  startLoading,
  premium,
  lessons,
  type,
  title,
  progress,
  preview,
  description,
  duration,
  difficulty,
  lessonsCount,
  studentsCount,
  onStartClick,
  onContinueClick,
}) => (
  <div className={styles.section}>
    <CoursePreview className={styles.preview} preview={preview} />
    <div className={styles.tags}>
      {!premium && <Tag variant="free">FREE</Tag>}
      {premium && <Tag variant="pro">PRO</Tag>}
      {premium && getFreeLessonsCount(lessons) > 0 && !isProSubscription && (
        <Tag variant="lessons">
          {pluralize('LESSONS', getFreeLessonsCount(lessons), true)} FREE
        </Tag>
      )}
    </div>
    <Typography variant="heading2" className={styles.title}>
      {title}
    </Typography>

    {progress >= 0 && (
      <div className={styles.progress}>
        <RoundProgressBar
          size={24}
          progress={progress}
          progressColor="var(--color-green-primary)"
          backLineColor="var(--color-green-primary)"
          backLineOpacity={0.15}
          lineSize={16}
        />
        <Typography
          variant="body3"
          weight="medium"
          className={styles.progressText}
        >
          {progress}% completed
        </Typography>
      </div>
    )}
    {description && (
      <Typography variant="body2" className={styles.description}>
        {description}
      </Typography>
    )}
    <CourseInfo
      className={styles.info}
      itemClassName={styles.infoItem}
      withBorder
      items={['STUDENTS', 'DIFFICULTY', 'TYPE']}
      type={type}
      duration={duration}
      difficulty={difficulty}
      lessonsCount={lessonsCount}
      studentsCount={studentsCount}
    />
    {progress === 0 ? (
      <Button
        className={styles.button}
        size="lg"
        startIcon={
          startLoading && (
            <Loading size={20} color="var(--color-white-primary)" />
          )
        }
        onClick={onStartClick}
      >
        {isProSubscription ? 'Start Course' : 'Start For Free'}
      </Button>
    ) : (
      <Button className={styles.button} size="lg" onClick={onContinueClick}>
        Continue Course
      </Button>
    )}
  </div>
);

export default React.memo(MainSection);
