import React from 'react';

import SkillBox from 'assets/img/skillbox.svg';
import Umuzi from 'assets/img/umuzi.svg';
import Careerist from 'assets/img/careerist.svg';

import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import { HomeLayout } from 'components/Layout';

import { SubscriptionPlan } from './components/SubscriptionPlan';

import styles from './PricingPage.styles.scss';

const PricingPage = ({
  loading,
  buttonLoading,
  user,
  monthlyPrice,
  annuallyPrice,
  userActivity,
  openRequestDemoModal,
  onUpgradeClick,
}) => (
  <HomeLayout user={user}>
    <div className="container">
      {loading ? (
        <Loading className={styles.loading} />
      ) : (
        <div className={styles.page}>
          <div className={styles.inner}>
            <Typography className={styles.title}>
              Level up your skills with Befront Pro Plan
            </Typography>
            <Typography variant="body1" className={styles.subtitle}>
              Boost your design skills with unlimited access
            </Typography>
            <div className={styles.plans}>
              <SubscriptionPlan
                type="Pro Monthly"
                active={userActivity?.isProSubscription}
                price={monthlyPrice.amount}
                priceCents={monthlyPrice.amountCents}
                priceText="per month"
                description="Monthly subscription for enhancing your design skills step by step with unlimited access to practical learning"
                buttonLoading={buttonLoading === monthlyPrice.key}
                onButtonClick={() => onUpgradeClick(monthlyPrice.key)}
              />
              <SubscriptionPlan
                type="Pro Yearly"
                active={false}
                price={annuallyPrice.amount}
                priceCents={annuallyPrice.amountCents}
                priceText="per month, paid yearly"
                description="Unlock a full year Pro access for taking your design skills to new heights with our all-inclusive subscription"
                buttonLoading={buttonLoading === annuallyPrice.key}
                onButtonClick={() => onUpgradeClick(annuallyPrice.key)}
              />
            </div>
            <div className={styles.schoolPlan}>
              <Typography className={styles.secondaryTitle}>
                Looking for a School Plan?
              </Typography>
              <Typography className={styles.subtitle}>
                Incorporate game changer Befront Figma plugin into your school,
                bootcamp to take students’ educational experience to the next
                level.
              </Typography>
              <Typography
                variant="body1"
                weight="medium"
                className={styles.text}
              >
                Join other companies strengthening their UX/UI design teaching
                with Befront:
              </Typography>
              <div className={styles.schools}>
                <div className={styles.school}>
                  <SkillBox />
                </div>
                <div className={styles.school}>
                  <Umuzi />
                </div>
                {/* <div className={styles.school}>
                  <Careerist />
                </div> */}
              </div>
              <div className={styles.actions}>
                <Button
                  className={styles.action}
                  onClick={openRequestDemoModal}
                >
                  Get school plan
                </Button>
                <Typography className={styles.actionText}>
                  from $12 per student/month
                </Typography>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  </HomeLayout>
);

export default React.memo(PricingPage);
