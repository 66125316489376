import React from 'react';
import cn from 'classnames';

import { bytesToSize } from 'helpers/bytesToSize';
import { getFileTypeIcon } from 'helpers/getFileTypeIcon';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';
import { LinearProgressBar } from 'components/shared/LinearProgressBar';
import { Link } from 'components/shared/Link';

import styles from './UploadMaterials.styles.scss';

const UploadMaterials = ({
  className,
  haveError,
  value,
  getInputProps,
  getRootProps,
  onBrowseClick,
  onRemoveClick,
}) => (
  <div
    className={cn(
      styles.upload,
      { [styles.uploadWithValue]: !!value },
      { [styles.uploadHaveError]: haveError },
      className,
    )}
  >
    <div {...getRootProps()} className={styles.inner}>
      <input {...getInputProps()} className={styles.field} />
      <Icon name="upload" className={styles.icon} />
      <Typography className={styles.text} variant="body2" mode="compact">
        Drop your file here or{' '}
        <Link
          className={styles.textLink}
          variant="body2"
          mode="compact"
          onClick={onBrowseClick}
        >
          browse
        </Link>
      </Typography>
    </div>
    <div className={styles.files}>
      {value.map((file) => (
        <div key={file.id} className={styles.file}>
          <Icon
            className={styles.fileRemoveIcon}
            name="trash"
            onClick={onRemoveClick}
          />
          <div className={styles.fileIconWrapper}>
            <Icon
              className={styles.fileIcon}
              name={getFileTypeIcon(file.type)}
            />
          </div>
          <div className={styles.fileInner}>
            <Typography
              className={styles.fileTitle}
              variant="body2"
              weight="medium"
              mode="compact"
            >
              {file.name}
            </Typography>
            <Typography className={styles.fileSize} variant="body3">
              {bytesToSize(file.size)}
            </Typography>
            <div className={styles.fileProgress}>
              <LinearProgressBar progress={file.percentage} />
              <Typography
                className={styles.fileProgressText}
                variant="body3"
                weight="medium"
              >
                {file.percentage}%
              </Typography>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default React.memo(UploadMaterials);
