import React from 'react';

import { Rating } from 'components/shared/Rating';

const RatingFiled = ({ field, form, ...restProps }) => {
  const handleChange = (value) => {
    form.setFieldValue(field.name, value);
  };

  return <Rating {...field} {...restProps} onChange={handleChange} />;
};

export default React.memo(RatingFiled);
