import React from 'react';

import { Typography } from 'components/shared/Typography';

import { Lesson } from '../Lesson';

import styles from './Module.styles.scss';

const Module = ({ title, lessons, onLessonClick }) => (
  <div className={styles.module}>
    <div className={styles.header}>
      <Typography
        className={styles.title}
        variant="body3"
        weight="medium"
        mode="compact"
      >
        {title}
      </Typography>
    </div>
    <div className={styles.lessons}>
      {lessons.map((lesson) => (
        <Lesson
          key={lesson.id}
          id={lesson.id}
          available={lesson.available}
          completed={lesson.completed}
          current={lesson.current}
          order={lesson.order}
          title={lesson.title}
          onClick={onLessonClick}
        />
      ))}
    </div>
  </div>
);

export default React.memo(Module);
