import React from 'react';

import { Popover } from 'components/shared/Popover';

import { MenuHeader } from './components/MenuHeader';
import { MenuDropdown } from './components/MenuDropdown';

import {
  checkPrevDisabled,
  checkNextDisabled,
  getLessonOrder,
} from './LessonMenu.helpers';

const LessonMenu = ({
  currentLessonRef,
  scrollbarRef,
  course,
  currentLesson,
  onBackClick,
  onLessonClick,
  onNextLessonClick,
  onPreviousLessonClick,
}) => (
  <Popover
    placement="bottom"
    triggerElement={({ isMounted }) => (
      <MenuHeader
        open={isMounted}
        prevDisabled={checkPrevDisabled({ course, currentLesson })}
        nextDisabled={checkNextDisabled({ course, currentLesson })}
        courseAvailable={course.available}
        lessonTitle={currentLesson.title}
        lessonOrder={getLessonOrder({
          course,
          currentLessonId: currentLesson.id,
        })}
        lessonsCount={course.lessonsCount}
        onNextLessonClick={onNextLessonClick}
        onPreviousLessonClick={onPreviousLessonClick}
      />
    )}
  >
    <MenuDropdown
      scrollbarRef={scrollbarRef}
      currentLessonRef={currentLessonRef}
      course={course}
      currentLesson={currentLesson}
      onLessonClick={onLessonClick}
      onBackClick={onBackClick}
    />
  </Popover>
);

export default React.memo(LessonMenu);
