import * as Yup from 'yup';

import { EMAIL_REGEX } from 'constants/common';

export const initialValues = { email: '', code: '' };

export const getValidationSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .test('emailFormat', 'Email is invalid', (value) => {
        if (!value || value.length < 3) {
          return true;
        }

        if (value.length >= 3 && !EMAIL_REGEX.test(value)) {
          return false;
        }

        return true;
      }),
    code: Yup.string().ensure(),
  });
