import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './SelectTag.styles.scss';

const SelectTag = ({ className, text, ...restProps }) => (
  <div className={cn(styles.checkbox, className)}>
    <input type="checkbox" className={styles.field} {...restProps} />
    <div className={styles.textWrapper}>
      <Typography
        variant="body2"
        mode="compact"
        weight="medium"
        className={styles.text}
      >
        {text}
      </Typography>
    </div>
  </div>
);

export default React.memo(SelectTag);
