import React from 'react';
import cn from 'classnames';

import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';
import { Avatar } from 'components/shared/Avatar';

import styles from './AvatarUpload.styles.scss';

const AvatarUpload = ({
  className,
  value,
  getRootProps,
  getInputProps,
  onAddClick,
  onRemoveClick,
}) => (
  <div className={cn(styles.upload, className)} {...getRootProps()}>
    <input className={styles.field} {...getInputProps()} />
    {value ? (
      <div className={styles.avatarWrapper}>
        <Avatar className={styles.avatar} url={value} />
        <div className={styles.overlay} onClick={onRemoveClick}>
          <Icon className={styles.removeIcon} name="trash" />
        </div>
      </div>
    ) : (
      <div className={styles.empty}>
        <Icon className={styles.emptyIcon} name="users" />
      </div>
    )}
    <div className={styles.buttons}>
      {value && (
        <Button
          className={cn(styles.removeButton, styles.button)}
          size="md"
          variant="outlined"
          startIcon={<Icon name="trash" />}
          onClick={onRemoveClick}
        >
          Delete photo
        </Button>
      )}
      <Button
        className={styles.button}
        size="md"
        variant="outlined"
        startIcon={<Icon name="plus" />}
        onClick={onAddClick}
      >
        Add profile photo
      </Button>
    </div>
  </div>
);

export default React.memo(AvatarUpload);
