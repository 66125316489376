import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import { WrongIcon } from 'components/WrongIcon';
import { CompletedIcon } from 'components/CompletedIcon';

import styles from './CurrentAnswer.styles.scss';

const CurrentAnswer = ({
  correct,
  url,
  text,
  onImageMouseDown,
  onImageMouseUp,
}) => (
  <div className={styles.answer}>
    <div
      className={cn(styles.preview, {
        [styles.previewCorrect]: correct,
        [styles.previewIncorrect]: !correct,
      })}
      onMouseDown={onImageMouseDown}
      onMouseUp={onImageMouseUp}
    >
      <img className={styles.previewImg} src={url} />
      {correct ? (
        <CompletedIcon
          className={styles.iconWrapper}
          iconClassName={styles.icon}
        />
      ) : (
        <WrongIcon className={styles.iconWrapper} iconClassName={styles.icon} />
      )}
    </div>
    <div className={styles.info}>
      <Icon className={styles.infoIcon} name="click" />
      <Typography
        className={styles.infoText}
        variant="body2"
        weight="medium"
        mode="compact"
      >
        Click the image to compare
      </Typography>
    </div>
    <div
      className={cn(styles.comment, {
        [styles.commentCorrect]: correct,
        [styles.commentIncorrect]: !correct,
      })}
    >
      <Typography className={styles.commentText} variant="body2" withHTML>
        {text.replace(/\n/g, '<br>')}
      </Typography>
    </div>
  </div>
);

export default React.memo(CurrentAnswer);
