import React from 'react';

import UnlockLessonsIcon from 'assets/img/unlock-lessons.svg';

import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';

import styles from './UnlockLessonsModal.styles.scss';

const UnlockLessonsModal = ({
  open,
  onClose,
  onContinueClick,
  onDashboardClick,
}) => (
  <Modal className={styles.modal} open={open} onClose={onClose}>
    <UnlockLessonsIcon />
    <Typography className={styles.title} variant="heading2" weight="bold">
      Would you like to continue?
    </Typography>
    <Typography className={styles.text} variant="body1">
      Upgrade your account to Pro to access other lessons
    </Typography>
    <div className={styles.actions}>
      <Button className={styles.action} onClick={onContinueClick}>
        Continue
      </Button>
      <Button
        className={styles.action}
        variant="outlined"
        onClick={onDashboardClick}
      >
        Go to dashboard
      </Button>
    </div>
  </Modal>
);

export default React.memo(UnlockLessonsModal);
