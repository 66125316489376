import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import { AdvantageItem } from './components/AdvantageItem';

import { ADVANTAGES } from './AdvantagesSection.constants';

import styles from './AdvantagesSection.styles.scss';

const AdvantagesSection = () => (
  <div className={cn(styles.section, 'section')}>
    <Typography className={styles.title} family="prompt">
      Become confident in your design skills
    </Typography>
    <div className={styles.items}>
      {ADVANTAGES.map((advantage) => (
        <AdvantageItem key={advantage.order} {...advantage} />
      ))}
    </div>
  </div>
);

export default React.memo(AdvantagesSection);
