export const items = [
  {
    order: 1,
    title: 'New career seekers',
    text: 'The best way to learn if design is right for you is to get your hands dirty. Get a taste of UX/UI role on real-life cases in Befront environment.',
  },
  {
    order: 2,
    title: 'UX/UI designers',
    text: 'Power up your skills through tons of real-life practical tasks. Learn new design skills from a growing library of step-by-step guided tutorials dedicated to UX design professionals.',
  },
  {
    order: 3,
    title: 'Product Teams',
    text: 'Increase your effectiveness as a Product manager, Software engineer or Marketing manager through mastering Figma skills and learning design foundations.',
  },
];
