import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';

import styles from './Lesson.styles.scss';

const Lesson = ({
  id,
  current,
  available,
  completed,
  order,
  title,
  onClick,
}) => (
  <div
    className={cn(styles.lesson, {
      [styles.lessonCurrent]: current,
      [styles.lessonDisabled]: !available,
    })}
    onClick={() => (available ? onClick(id) : () => {})}
  >
    <div className={styles.orderWrapper}>
      <Typography
        className={styles.order}
        variant="body3"
        weight="medium"
        mode="compact"
      >
        {order}
      </Typography>
    </div>
    <Typography
      className={styles.title}
      variant="body2"
      weight="medium"
      mode="compact"
    >
      {title || 'Untitled'}
    </Typography>
    {completed && <Icon className={styles.completedIcon} name="completed" />}
    {!available && <Icon className={styles.lockIcon} name="lock" />}
  </div>
);

export default React.memo(Lesson);
