import React from 'react';

import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';

const Action = ({
  correct,
  submitted,
  allChecked,
  nextLoading,
  isLastLessonInCourse,
  onTryAgainClick,
  onNextLessonClick,
  onCheckClick,
}) => {
  if (submitted && correct && isLastLessonInCourse) {
    return <Button onClick={onNextLessonClick}>Finish Course</Button>;
  }

  if (!submitted) {
    return (
      <Button disabled={!allChecked} onClick={onCheckClick}>
        Check
      </Button>
    );
  }

  if (submitted && !correct) {
    return <Button onClick={onTryAgainClick}>Try Again</Button>;
  }

  if (submitted && correct) {
    return (
      <Button
        loading={nextLoading}
        endIcon={<Icon name="arrowRight" />}
        onClick={onNextLessonClick}
      >
        Next Lesson
      </Button>
    );
  }
};

export default React.memo(Action);
