import React from 'react';
import cn from 'classnames';

import { checkIsCourseNew } from 'helpers/checkIsCourseNew';

import { Tag } from 'components/shared/Tag';

import LinesEllipsis from 'components/LinesEllipsis';

import { StatusTag } from 'components/StatusTag';
import { FavoriteIcon } from 'components/FavoriteIcon';
import { CoursePreview } from 'components/CoursePreview';

import { CardInfo } from './components/CardInfo';

import styles from './CourseCard.styles.scss';

const CourseCard = ({
  className,
  withStatus,
  completed,
  soon,
  progress,
  favorite,
  id,
  type,
  preview,
  title,
  createdAt,
  description,
  difficulty,
  studentsCount,
  onFavoriteChange,
  onCardClick,
}) => {
  return (
    <div
      className={cn(styles.card, { [styles.cardSoon]: soon }, className)}
      onClick={onCardClick}
    >
      <div className={styles.tags}>
        {soon && <Tag variant="soon">SOON</Tag>}
        {checkIsCourseNew(createdAt) && <Tag variant="new">NEW</Tag>}
      </div>
      <FavoriteIcon
        className={styles.favoriteIcon}
        courseId={id}
        favorite={favorite}
        onChange={onFavoriteChange}
      />
      <CoursePreview preview={preview} className={styles.preview} />

      <LinesEllipsis
        className={styles.title}
        component="p"
        text={title}
        maxLine="1"
        ellipsis="..."
        trimRight
        basedOn="letters"
      />

      {withStatus && !soon && (
        <StatusTag
          className={styles.status}
          completed={completed}
          progress={progress}
        />
      )}
      <LinesEllipsis
        className={styles.text}
        component="p"
        text={description}
        maxLine="3"
        ellipsis="..."
        trimRight
        basedOn="letters"
      />
      <CardInfo
        className={styles.cardInfo}
        difficulty={difficulty}
        type={type}
        studentsCount={studentsCount}
      />
    </div>
  );
};

export default React.memo(CourseCard);
