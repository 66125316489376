import React, { useState } from 'react';

import { logEvent } from 'helpers/logEvent';

import { useAuthModal } from 'context/authModals';

import Menu from './Menu';

const MenuContainer = (props) => {
  const [expanded, setExpanded] = useState(false);

  const { dispatch } = useAuthModal();

  const handleSignInClick = () => {
    logEvent({ eventType: 'Login_Clicked' });

    dispatch({
      type: 'OPEN_MODAL',
      payload: { currentModal: 'SIGNIN_MODAL', currentModalData: null },
    });
  };

  const handleSignUpClick = () => {
    logEvent({ eventType: 'SignUp_Clicked' });

    dispatch({
      type: 'OPEN_MODAL',
      payload: { currentModal: 'SIGNUP_MODAL', currentModalData: null },
    });
  };

  const handleMenuClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Menu
      {...props}
      expanded={expanded}
      onMenuClick={handleMenuClick}
      onSignInClick={handleSignInClick}
      onSignUpClick={handleSignUpClick}
    />
  );
};

export default React.memo(MenuContainer);
