import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { COURSE_TYPES } from 'constants/common';

import { startCourseById } from 'store/course/actions';

import Lessons from './Lessons';

const LessonsContainer = ({
  courseId,
  courseType,
  courseTitle,
  completedLessons,
  ...restProps
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [startLoadingId, setStartLoadingId] = useState(null);

  const handleLessonClick = async (lesson) => {
    if (courseType === COURSE_TYPES.FIGMA_PLUGIN) {
      navigate(`/courses/${courseId}/before-start`);

      return;
    }

    try {
      setStartLoadingId(lesson.id);

      await dispatch(startCourseById(courseId));

      setStartLoadingId(null);

      navigate(`/courses/${courseId}/lessons/${lesson.id}`);
    } catch (error) {
      console.warn(error);

      setStartLoadingId(null);
    }
  };

  return (
    <Lessons
      {...restProps}
      completedLessons={completedLessons}
      startLoadingId={startLoadingId}
      onLessonClick={handleLessonClick}
    />
  );
};

export default React.memo(LessonsContainer);
