export const SECTIONS_COUNT = 12;
export const HEADER_HEIGHT = 64;
export const MENU_ITEMS = [
  'Use of Our Service',
  'User Content',
  'User Content License Grant',
  'Our Proprietary Rights',
  'Paid Services',
  'Privacy',
  'Security',
  'Third-Party Links',
  'Indemnity',
  'No Warranty',
  'Limitation of Liability',
  'General',
];
