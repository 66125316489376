import React from 'react';
import cn from 'classnames';

import { SIDEBAR_MENU } from 'config/menu';

import { Header } from 'components/Header';
import { Sidebar } from 'components/Sidebar';

import styles from './MainLayout.styles.scss';

const MainLayout = ({ className, mainClassName, children }) => (
  <div className={cn(styles.layout, className)}>
    <div className={styles.container}>
      <Sidebar menuItems={SIDEBAR_MENU} />
      <div className={styles.inner}>
        <Header className={styles.header} withLogo={false} />
        <div className={cn(styles.main, mainClassName)}>{children}</div>
      </div>
    </div>
  </div>
);

export default React.memo(MainLayout);
