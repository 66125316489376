import React from 'react';
import { Formik, Form as FormikForm } from 'formik';

import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import { FormikField } from 'components/FormikField';
import { InputField } from 'components/FormikFields';

import {
  initialValues,
  getValidationSchema,
} from './EnterPromoForm.formConfig';

import styles from './EnterPromoForm.styles.scss';

const EnterPromoForm = ({ continueLoading, currentEmail, onContinueClick }) => (
  <Formik
    enableReinitialize
    validateOnMount
    initialValues={{ ...initialValues, email: currentEmail }}
    validationSchema={getValidationSchema()}
    onSubmit={onContinueClick}
  >
    <FormikForm className={styles.form}>
      <FormikField
        name="email"
        component={InputField}
        controlProps={{
          className: styles.formControl,
          required: true,
          label: 'Email',
        }}
        componentProps={{
          fieldClassName: styles.formField,
          size: 'lg',
          placeholder: 'name@mail.com',
        }}
      />
      <FormikField
        name="code"
        component={InputField}
        controlProps={{ className: styles.formControl, label: 'Promocode' }}
        componentProps={{
          fieldClassName: styles.formField,
          size: 'lg',
          placeholder: 'XXX-XXXXXXXX-XXXX',
        }}
      />
      <Button
        className={styles.action}
        startIcon={
          continueLoading && <Loading color={'var(--color-white-primary)'} />
        }
        type="submit"
      >
        Continue
      </Button>
    </FormikForm>
  </Formik>
);

export default React.memo(EnterPromoForm);
