import React from 'react';

import { Typography } from 'components/shared/Typography';

import styles from './PortfolioSection.styles.scss';

const PortfolioSection = ({ preview }) => (
  <div className={styles.section}>
    <Typography className={styles.title} variant="body1" weight="semiBold">
      At the end of this course, you will get a result like in this image
    </Typography>
    <div className={styles.inner}>
      <img className={styles.preview} src={preview} />
    </div>
  </div>
);

export default React.memo(PortfolioSection);
