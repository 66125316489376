import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './AdvantageItem.styles.scss';

const AdvantageItem = ({ className, order, title, text }) => (
  <div className={cn(styles.item, className)}>
    <div className={styles.order}>
      <Typography className={styles.orderText}>{order}</Typography>
    </div>
    <div className={styles.inner}>
      <Typography className={styles.title} family="prompt">
        {title}
      </Typography>
      <Typography className={styles.text}>{text}</Typography>
    </div>
  </div>
);

export default React.memo(AdvantageItem);
