import React from 'react';
import cn from 'classnames';

import KeyIcon from 'assets/img/key.svg';
import UnlockBg from 'assets/img/unlock-lessons-bg.svg';

import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';

import styles from './UnlockLessonsSection.styles.scss';

const UnlockLessonsSection = ({ className, onGoToProClick }) => (
  <div className={cn(styles.section, className)}>
    <UnlockBg className={styles.bg} />
    <KeyIcon className={styles.icon} />
    <div className={styles.inner}>
      <Typography className={styles.text} variant="subtitle">
        Unlock all lessons and boost your design skills
      </Typography>
      <Button className={styles.button} size="lg" onClick={onGoToProClick}>
        Go to Pro
      </Button>
    </div>
  </div>
);

export default React.memo(UnlockLessonsSection);
