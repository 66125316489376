import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { logEvent } from 'helpers/logEvent';
import { getSchoolNames } from 'helpers/getSchoolNames';
import { getLocationName } from 'helpers/getLocationName';

import { FaqDrawer } from './components/FaqDrawer';

import { userActivitySelector, userSelector } from 'store/auth/selectors';

import { useModal } from 'hooks/useModal';

import Header from './Header';

const HeaderContainer = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector(userSelector);
  const userActivity = useSelector(userActivitySelector);

  const [faqOpen, openFaq, closeFaq] = useModal({ defaultOpen: false });

  const handleGoProClick = () => {
    logEvent({
      eventType: 'Go_Pro_Clicked',
      eventProps: {
        page: getLocationName(location.pathname),
      },
      userProps: {
        user_email: user.email,
        user_is_pro: userActivity.isProSubscription,
        user_school: getSchoolNames(user.schools),
      },
    });

    navigate('/pricing');
  };

  const handleFaqClick = () => {
    openFaq();
  };

  const isProSubscription = userActivity
    ? userActivity.isProSubscription
    : false;

  return (
    <>
      <Header
        {...props}
        isProSubscription={isProSubscription}
        onGoProClick={handleGoProClick}
        onFaqClick={handleFaqClick}
      />
      <FaqDrawer open={faqOpen} onClose={closeFaq} />
    </>
  );
};

export default React.memo(HeaderContainer);
