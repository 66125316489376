import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import { ROUTES } from 'constants/routes';

import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';

import LogoIcon from 'assets/img/logo.svg';

import styles from './Header.styles.scss';

const Header = ({
  className,
  isAuthenticated,
  onInstallFigmaPluginClick,
  onSignInClick,
  onSignUpClick,
  onDashboardClick,
}) => (
  <div className={cn(styles.header, className)}>
    <div className={styles.inner}>
      <NavLink
        to={isAuthenticated ? ROUTES.DASHBOARD_PAGE : '/'}
        className={styles.logoLink}
      >
        <LogoIcon className={styles.logo} />
      </NavLink>
    </div>

    <div className={styles.actions}>
      <div className={styles.menu}>
        <Typography
          className={styles.menuItem}
          component={NavLink}
          variant="body2"
          to="/pricing"
        >
          Pricing
        </Typography>
      </div>
      {isAuthenticated ? (
        <Button className={styles.action} size="sm" onClick={onDashboardClick}>
          Go to Dashboard
        </Button>
      ) : (
        <>
          <Button
            className={styles.action}
            size="sm"
            variant="outlined"
            startIcon={<Icon name="download" />}
            onClick={onInstallFigmaPluginClick}
          >
            Install Figma plugin
          </Button>
          <Button
            className={styles.action}
            size="sm"
            variant="outlined"
            onClick={onSignInClick}
          >
            Log In
          </Button>
          <Button className={styles.action} size="sm" onClick={onSignUpClick}>
            Sign Up
          </Button>
        </>
      )}
    </div>
  </div>
);

export default React.memo(Header);
