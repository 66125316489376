import React from 'react';
import cn from 'classnames';

import { Radio } from 'components/shared/Radio';
import { Checkbox } from 'components/shared/Checkbox';
import { Typography } from 'components/shared/Typography';

import { WrongIcon } from 'components/WrongIcon';
import { CompletedIcon } from 'components/CompletedIcon';

import styles from './AnswerText.styles.scss';

const AnswerText = ({
  submitted,
  correct,
  checked,
  answersMultiple,
  data,
  comment,
  onClick,
}) => (
  <div
    className={cn(styles.answer, {
      [styles.answerSelected]: checked,
      [styles.answerCorrect]:
        (checked && correct && submitted) || (!checked && correct && submitted),
      [styles.answerIncorrect]: checked && !correct && submitted,
      [styles.answerSubmitted]: submitted,
      [styles.answerSubmittedSelected]: submitted && checked,
    })}
    onClick={!submitted ? onClick : () => {}}
  >
    <div className={styles.inner}>
      {answersMultiple ? (
        <Checkbox
          className={styles.multipleType}
          readOnly
          checked={checked}
          disabled={submitted}
        />
      ) : (
        <Radio
          className={styles.multipleType}
          readOnly
          checked={checked}
          disabled={submitted}
        />
      )}
      <Typography className={styles.text} variant="body2" mode="compact">
        {data}
      </Typography>
      <CompletedIcon
        className={cn(styles.iconWrapper, styles.iconWrapperCorrect, {
          [styles.iconWrapperComment]: !!comment,
        })}
        iconClassName={styles.iconCorrect}
      />
      <WrongIcon
        className={cn(styles.iconWrapper, styles.iconWrapperIncorrect, {
          [styles.iconWrapperComment]: !!comment,
        })}
        iconClassName={styles.iconIncorrect}
      />
    </div>
    {comment &&
      ((checked && correct && submitted) ||
        (!checked && correct && submitted) ||
        (checked && !correct && submitted)) && (
        <Typography
          className={cn(styles.comment, {
            [styles.commentCorrect]:
              (checked && correct && submitted) ||
              (!checked && correct && submitted),
            [styles.commentIncorrect]: checked && !correct && submitted,
          })}
          variant="body2"
          mode="compact"
        >
          {comment}
        </Typography>
      )}
  </div>
);

export default React.memo(AnswerText);
