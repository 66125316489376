import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './Radio.styles.scss';

const Radio = ({ className, disabled, label, ...restProps }) => (
  <div
    className={cn(
      styles.radio,
      { [styles.radioWithLabel]: label, [styles.radioDisabled]: disabled },
      className,
    )}
  >
    <input type="radio" className={styles.field} {...restProps} />
    <div className={styles.iconWrapper}>
      <div className={styles.icon} />
    </div>
    {label && (
      <Typography variant="body1" weight="medium" className={styles.label}>
        {label}
      </Typography>
    )}
  </div>
);

export default React.memo(Radio);
