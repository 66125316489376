import React from 'react';
import { Formik, Form } from 'formik';

import { Typography } from 'components/shared/Typography';
import { Modal } from 'components/shared/Modal';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import { FormikField } from 'components/FormikField';
import { InputField } from 'components/FormikFields';
import { AvatarUpload } from 'components/AvatarUpload';

import { validationSchema } from './EditUserDetailsModal.formConfig';

import { convertDataToForm } from './EditUserDetailsModal.helpers';

import styles from './EditUserDetailsModal.styles.scss';

const EditUserDetailsModal = ({
  loading,
  open,
  user,
  onClose,
  onSaveClick,
}) => {
  return (
    <Modal open={open} centered onClose={onClose} className={styles.modal}>
      <Typography className={styles.title} variant="subtitle">
        Edit your account
      </Typography>
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={convertDataToForm(user)}
        validationSchema={validationSchema}
        onSubmit={onSaveClick}
      >
        <Form className={styles.form}>
          <AvatarUpload className={styles.uploadAvatar} />
          <FormikField
            name="firstName"
            component={InputField}
            controlProps={{ required: true, label: 'First name' }}
          />
          <FormikField
            name="lastName"
            component={InputField}
            controlProps={{ required: true, label: 'Last name' }}
          />
          <div className={styles.actions}>
            <Button
              className={styles.action}
              size="md"
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              className={styles.action}
              size="md"
              type="submit"
              startIcon={loading && <Loading />}
            >
              Save
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default React.memo(EditUserDetailsModal);
