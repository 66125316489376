import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';
import { Loading } from 'components/shared/Loading';

import { LessonIcon } from 'components/LessonIcon';

import styles from './LessonItem.styles.scss';

const LessonItem = ({
  className,
  startLoading,
  available,
  completed,
  id,
  type,
  title,
  onClick,
}) => (
  <div
    className={cn(
      styles.lesson,
      { [styles.lessonDisabled]: !available },
      className,
    )}
    onClick={() => available && onClick({ id, available, title })}
  >
    <div className={styles.iconWrapper}>
      <LessonIcon className={styles.icon} type={type} />
    </div>
    <Typography
      variant="body3"
      weight="medium"
      mode="compact"
      className={styles.title}
    >
      {title}
    </Typography>
    {completed && !startLoading && (
      <Icon className={styles.checkIcon} name="completed" />
    )}
    {!available && <Icon name="lock" className={styles.lockIcon} />}
    {available && startLoading && (
      <Loading className={styles.startLoading} size={18} />
    )}
    <Button
      className={styles.button}
      size="sm"
      startIcon={
        startLoading && <Loading size={18} color="var(--color-white-primary)" />
      }
    >
      Start lesson
    </Button>
  </div>
);

export default React.memo(LessonItem);
