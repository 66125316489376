import React from 'react';
import cn from 'classnames';
import pluralize from 'pluralize';

import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';
import { RoundProgressBar } from 'components/shared/RoundProgressBar';

import LinesEllipsis from 'components/LinesEllipsis';

import { CoursePreview } from 'components/CoursePreview';
import { CompletedIcon } from 'components/CompletedIcon';

import styles from './MyCourseCard.styles.scss';

const MyCourseCard = ({
  className,
  progress,
  preview,
  title,
  lessonsLeft,
  onCourseCardClick,
  onContinueClick,
}) => (
  <div className={cn(styles.card, className)} onClick={onCourseCardClick}>
    <CoursePreview preview={preview} />
    <div className={styles.inner}>
      <LinesEllipsis
        className={styles.title}
        component="p"
        text={title}
        maxLine="1"
        ellipsis="..."
        trimRight
        basedOn="letters"
      />
      <div className={styles.info}>
        {progress === 100 ? (
          <>
            <CompletedIcon />
            <Typography
              className={styles.completedText}
              variant="caption"
              weight="medium"
            >
              Completed
            </Typography>
          </>
        ) : (
          <>
            <RoundProgressBar
              progress={progress}
              size={18}
              backLineColor="var(--color-green-10)"
              progressColor="var(--color-green-primary)"
              lineSize={16}
            />
            <Typography className={styles.status} variant="body3">
              {`${progress}% completed`}
            </Typography>
            {!!lessonsLeft && (
              <Typography className={styles.text} variant="body3">
                {`${pluralize('lessons', lessonsLeft, true)} left`}
              </Typography>
            )}
          </>
        )}
      </div>
    </div>
    <Button
      className={styles.button}
      size="md"
      onClick={(e) => {
        e.stopPropagation();
        onContinueClick();
      }}
    >
      Continue
    </Button>
  </div>
);

MyCourseCard.defaultProps = {
  admin: false,
};

export default React.memo(MyCourseCard);
