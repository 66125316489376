import React from 'react';
import InputRange from 'react-input-range';
import cn from 'classnames';

import styles from './RangeSlider.styles.scss';

const RangeSlider = ({ value, step, maxValue, minValue, onChange }) => (
  <>
    <InputRange
      classNames={{
        inputRange: styles.inputRange,
        disabledInputRange: styles.inputRangeDisabled,
        track: styles.track,
        activeTrack: styles.trackActive,
        sliderContainer: cn(styles.sliderContainer, {
          [styles.slideContainerMax]: Number(value) === maxValue,
        }),
        slider: styles.slider,
        minLabel: styles.minLabel,
        maxLabel: styles.maxLabel,
        labelContainer: styles.labelContainer,
        valueLabel: styles.valueLabel,
      }}
      step={step}
      maxValue={maxValue}
      minValue={minValue}
      value={value}
      onChange={onChange}
    />
  </>
);

export default React.memo(RangeSlider);
