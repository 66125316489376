import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes';

import { logEvent } from 'helpers/logEvent';
import { getSchoolNames } from 'helpers/getSchoolNames';

import { logoutUser } from 'store/auth/actions';
import { userActivitySelector, userSelector } from 'store/auth/selectors';

import UserMenu from './UserMenu';

const UserMenuContainer = ({ className }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const user = useSelector(userSelector);
  const userActivity = useSelector(userActivitySelector);

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleMenuItemClick = (itemKey) => {
    switch (itemKey) {
      case 'account': {
        navigate(ROUTES.PROFILE_PAGE);

        break;
      }
      case 'logout': {
        logEvent({
          eventType: 'Logout_Clicked',
          userProps: {
            user_email: user.email,
            user_is_pro: userActivity.isProSubscription,
            user_school: getSchoolNames(user.schools),
          },
        });

        dispatch(logoutUser())
          .then(() => {
            navigate('/');
          })
          .catch((err) => {
            console.warn(err);
          });

        break;
      }
    }
  };

  if (!user) {
    return null;
  }

  return (
    <UserMenu
      open={open}
      user={user}
      onClick={handleClick}
      onMenuItemClick={handleMenuItemClick}
      className={className}
    />
  );
};

export default React.memo(UserMenuContainer);
