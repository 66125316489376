import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateUserInfo } from 'store/auth/actions';

import EditUserDetailsModal from './EditUserDetailsModal';

const EditUserDetailsModalContainer = ({ onClose, ...restProps }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleSaveClick = async (values) => {
    try {
      setLoading(true);

      await dispatch(updateUserInfo(values));

      setLoading(false);

      onClose();
    } catch (error) {
      console.warn(error);

      setLoading(false);
    }
  };

  return (
    <EditUserDetailsModal
      {...restProps}
      loading={loading}
      onClose={onClose}
      onSaveClick={handleSaveClick}
    />
  );
};

export default React.memo(EditUserDetailsModalContainer);
