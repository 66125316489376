import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './Menu.styles.scss';

const Menu = ({ className, menuItems }) => (
  <div className={cn(styles.menu, className)}>
    {menuItems.map((menuItem) => (
      <NavLink
        className={({ isActive }) =>
          isActive ? cn(styles.item, styles.itemActive) : styles.item
        }
        key={menuItem.text}
        to={menuItem.to}
      >
        <Icon className={styles.icon} name={menuItem.icon} />

        <Typography
          className={styles.text}
          variant="body1"
          weight="medium"
          mode="compact"
        >
          {menuItem.text}
        </Typography>
      </NavLink>
    ))}
  </div>
);

export default React.memo(Menu);
