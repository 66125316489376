export const getStatusIcon = ({ progress, completed }) => {
  if (progress === 0 && !completed) {
    return 'play';
  }
  if (progress > 0 && !completed) {
    return 'clock';
  }
  if (completed) {
    return 'completed';
  }
};

export const getStatusText = ({ progress, completed }) => {
  if (progress === 0 && !completed) {
    return 'Not Started';
  }
  if (progress > 0 && !completed) {
    return 'In Progress';
  }
  if (completed) {
    return 'Completed';
  }
};
