import React from 'react';

import { Button } from 'components/shared/Button';

const FavoriteIcon = ({
  className,
  iconClassName,
  withOverlay,
  favorite,
  size,
  overlaySize,
  onClick,
}) => (
  <Button
    variant="ghost"
    className={className}
    iconColor={favorite ? 'var(--color-yellow-medium)' : '#021930'}
    mode="icon"
    size="sm"
    iconName={favorite ? 'bookmarkFilled' : 'bookmark'}
    onClick={(event) => {
      event.stopPropagation();
      onClick();
    }}
  />
);

FavoriteIcon.defaultProps = {
  size: 24,
  overlaySize: 32,
};

export default React.memo(FavoriteIcon);
