import React from 'react';

import { useModal } from 'hooks/useModal';

import { SubmitIdea } from './components/SubmitIdea';

import ShareReview from './ShareReview';

const ShareReviewContainer = (props) => {
  const [modalOpen, openModal, closeModal, modalData] = useModal({
    defaultOpen: false,
  });

  const handleAddReviewClick = () => {
    openModal();
  };

  return (
    <>
      <ShareReview {...props} onAddReviewClick={handleAddReviewClick} />
      <SubmitIdea {...modalData} open={modalOpen} onClose={closeModal} />
    </>
  );
};

export default React.memo(ShareReviewContainer);
