import _flatten from 'lodash/flatten';

import { COURSE_STRUCTURES } from 'constants/common';

const _getNextLessonWithModules = ({ course, currentLessonId }) => {
  const lessons = _flatten(course.modules.map((module) => module.lessons));

  const currentLesson = lessons.find((lesson) => lesson.id === currentLessonId);

  const currentModule = course.modules.find(
    (module) => module.id === currentLesson.moduleId,
  );

  let nextLesson = null;

  if (currentLesson.order < currentModule.lessons.length) {
    nextLesson = currentModule.lessons[currentLesson.order];
  }

  if (currentLesson.order === currentModule.lessons.length) {
    const nextModule = course.modules[currentModule.order];

    if (!nextModule) {
      nextLesson = course.modules[0].lessons[0];
    } else {
      nextLesson = nextModule.lessons[0];
    }
  }

  if (
    (!course.available && !nextLesson.free) ||
    course.completedLessons.indexOf(nextLesson.id) > -1
  ) {
    const firstUncompletedLesson = _getFirstFreeUncompletedLesson({
      lessons: lessons.filter((lesson) => lesson.id !== currentLessonId),
      completedLessons: course.completedLessons,
    });

    if (!firstUncompletedLesson) {
      return nextLesson;
    }

    return firstUncompletedLesson;
  }

  return nextLesson;
};

const _getFirstFreeUncompletedLesson = ({ lessons, completedLessons }) => {
  const firstUncompletedLesson = lessons.find(
    (lesson) => lesson.free && completedLessons.indexOf(lesson.id) === -1,
  );

  return firstUncompletedLesson;
};

const _getNextLesson = ({ course, currentLessonId }) => {
  const lessons = course.lessons;

  const currentLesson = lessons.find((lesson) => lesson.id === currentLessonId);

  if (!currentLesson) {
    console.warn('lesson not found: getCourseNextLessonById');

    return null;
  }

  let nextLesson = null;

  if (currentLesson.order < lessons.length) {
    nextLesson = lessons[currentLesson.order];
  }

  if (currentLesson.order === lessons.length) {
    nextLesson = lessons[0];
  }

  if (
    (!course.available && !nextLesson.free) ||
    course.completedLessons.indexOf(nextLesson.id) > -1
  ) {
    const firstUncompletedLesson = _getFirstFreeUncompletedLesson({
      lessons: lessons.filter((lesson) => lesson.id !== currentLessonId),
      completedLessons: course.completedLessons,
    });

    if (!firstUncompletedLesson) {
      return nextLesson;
    }

    return firstUncompletedLesson;
  }

  return nextLesson;
};

export const getCourseNextLessonById = ({ course, currentLessonId }) => {
  const nextLesson =
    course.structure === COURSE_STRUCTURES.MODULES
      ? _getNextLessonWithModules({ course, currentLessonId })
      : _getNextLesson({ course, currentLessonId });

  return nextLesson;
};
