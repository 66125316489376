import { createSlice } from '@reduxjs/toolkit';

import {
  getUserInfo,
  updateUserInfo,
  logoutUser,
  setCurrentUser,
} from './actions';

const initialState = {
  user: null,
  userActivity: null,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setCurrentUser, (state, action) => {
      state.user = action.payload.user;
      state.userActivity = action.payload.userActivity;

      if (action.payload) {
        state.isAuthenticated = true;
      } else {
        state.isAuthenticated = false;
      }
    });

    builder.addCase(getUserInfo.fulfilled, (state, { payload }) => {
      state.user = payload.user;
      state.userActivity = payload.userActivity;
      state.isAuthenticated = true;
    });

    builder.addCase(updateUserInfo.fulfilled, (state, { payload }) => {
      state.user = { ...state.user, ...payload };
    });

    builder.addCase(logoutUser, () => {
      return initialState;
    });
  },
});

export default authSlice.reducer;
