import React from 'react';

import { LESSON_TYPES } from 'constants/common';

import { Icon } from 'components/shared/Icon';

const LessonIcon = ({ className, type }) => {
  switch (type) {
    case LESSON_TYPES.QUIZ: {
      return <Icon className={className} name="quiz" />;
    }
    case LESSON_TYPES.CONNECTION_QUIZ: {
      return <Icon className={className} name="connectionQuiz" />;
    }
    case LESSON_TYPES.THEORY_LESSON: {
      return <Icon className={className} name="theory" />;
    }
    case LESSON_TYPES.FIGMA_LESSON: {
      return <Icon className={className} name="practice" />;
    }
    case LESSON_TYPES.TWO_COLUMNS_LESSON: {
      return <Icon className={className} name="twoColumns" />;
    }
    case LESSON_TYPES.DIFFERENCE_LESSON: {
      return <Icon className={className} name="difference" />;
    }
    default: {
      return null;
    }
  }
};

export default React.memo(LessonIcon);
