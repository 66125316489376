import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import * as api from 'api/methods';

import { userActivitySelector, userSelector } from 'store/auth/selectors';

import { useModal } from 'hooks/useModal';

import { EditUserDetailsModal } from '../EditUserDetailsModal';

import UserSection from './UserSection';

const UserSectionContainer = (props) => {
  const user = useSelector(userSelector);
  const userActivity = useSelector(userActivitySelector);

  const [billingSettingsLoading, setBillingSettingsLoading] = useState(false);

  const [modalOpen, openModal, closeModal] = useModal({ defaultOpen: false });

  const handleEditIconClick = () => {
    openModal();
  };

  const handleBillingSettingsClick = async () => {
    try {
      setBillingSettingsLoading(true);

      const response = await api.initBillingPortal({
        returnUrl: window.location.href,
      });

      if (response.url) {
        window.location.href = response.url;
      }
    } catch (error) {
      console.warn(error);
    }

    setBillingSettingsLoading(false);
  };

  const isProSubscription = userActivity
    ? userActivity.isProSubscription
    : false;

  return (
    <>
      <UserSection
        {...props}
        isProSubscription={isProSubscription}
        billingSettingsLoading={billingSettingsLoading}
        user={user}
        onEditIconClick={handleEditIconClick}
        onBillingSettingsClick={handleBillingSettingsClick}
      />
      <EditUserDetailsModal open={modalOpen} user={user} onClose={closeModal} />
    </>
  );
};

export default React.memo(UserSectionContainer);
