import React from 'react';

import { Loading } from 'components/shared/Loading';

import { MainLayout, TabsLayout } from 'components/Layout';
import { CourseCard } from 'components/CourseCard';

import styles from './CoursesPage.styles.scss';

const CoursesPage = ({ loading, courses }) => (
  <MainLayout>
    <TabsLayout title="Courses">
      {loading ? (
        <Loading className={styles.loading} />
      ) : (
        !!courses.length && (
          <div className={styles.courses}>
            {courses.map((course) => (
              <CourseCard
                className={styles.course}
                key={course.id}
                withStatus
                {...course}
              />
            ))}
          </div>
        )
      )}
    </TabsLayout>
  </MainLayout>
);

export default React.memo(CoursesPage);
