import React from 'react';

import { Typography } from 'components/shared/Typography';

import { CodeInput } from './components/CodeInput';

import styles from './EnterCodeForm.styles.scss';

const EnterCodeForm = ({
  codeError,
  resetTrigger,
  timerSecondsLeft,
  onCodeChange,
  onResendCodeClick,
}) => (
  <>
    <CodeInput
      error={codeError}
      resetTrigger={resetTrigger}
      onChange={onCodeChange}
    />
    <div className={styles.inner}>
      {timerSecondsLeft === 0 && (
        <Typography
          className={styles.link}
          variant="body2"
          onClick={onResendCodeClick}
        >
          Resend code
        </Typography>
      )}
      {timerSecondsLeft > 0 && (
        <>
          <Typography variant="body2" className={styles.text}>
            Code sent. You can request a new code after
          </Typography>
          <Typography
            variant="body2"
            weight="semiBold"
            component="span"
            className={styles.textCaption}
          >
            {timerSecondsLeft} sec.
          </Typography>
        </>
      )}
    </div>
  </>
);

export default React.memo(EnterCodeForm);
