import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { ROUTES } from 'constants/routes';

import App from 'App';

import { PrivateRoute } from 'components/PrivateRoute';

import { NotFoundPage } from 'pages/NotFoundPage';

import { HomePage } from 'pages/HomePage';
import { PrivacyPolicyPage } from 'pages/PrivacyPolicyPage';
import { TermsPage } from 'pages/TermsPage';
import { PricingPage } from 'pages/PricingPage';
import { DashboardPage } from 'pages/DashboardPage';
import { CoursesPage } from 'pages/CoursesPage';
import { CoursePage } from 'pages/CoursePage';
import { ProfilePage } from 'pages/ProfilePage';
import { FaqPage } from 'pages/FaqPage';
import { UnsubscribePage } from 'pages/UnsubscribePage';
import { BeforeStartCoursePage } from 'pages/BeforeStartCoursePage';

import { LessonProvider } from 'providers/LessonProvider';

export const router = createBrowserRouter([
  {
    path: ROUTES.HOME_PAGE,
    element: <App />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: ROUTES.DASHBOARD_PAGE,
        element: (
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.COURSES_PAGE,
        element: (
          <PrivateRoute>
            <CoursesPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.COURSE_PAGE,
        element: (
          <PrivateRoute>
            <CoursePage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.COURSE_BEFORE_START_PAGE,
        element: (
          <PrivateRoute>
            <BeforeStartCoursePage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.COURSE_LESSON_PAGE,
        element: (
          <PrivateRoute>
            <LessonProvider />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.PROFILE_PAGE,
        element: (
          <PrivateRoute>
            <ProfilePage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.PRIVACY_POLICY_PAGE,
        element: <PrivacyPolicyPage />,
      },
      {
        path: ROUTES.TERMS_PAGE,
        element: <TermsPage />,
      },
      {
        path: ROUTES.PRICING_PAGE,
        element: <PricingPage />,
      },
      {
        path: ROUTES.FAQ_PAGE,
        element: <FaqPage />,
      },
      {
        path: ROUTES.UNSUBSCRIBE_PAGE,
        element: <UnsubscribePage />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
]);
