import React from 'react';
import cn from 'classnames';

import styles from './Input.styles.scss';

const SIZES = {
  lg: styles.fieldLarge,
  md: styles.fieldMedium,
};

const Input = ({
  className,
  fieldClassName,
  endAdornmentClassName,
  startAdornmentClassName,
  disabled,
  multiline,
  haveError,
  inputRef,
  value,
  size,
  startAdornment,
  endAdornment,
  ...restProps
}) => {
  const Component = multiline ? 'textarea' : 'input';

  return (
    <div
      className={cn(
        styles.input,
        { [styles.inputDisabled]: disabled },
        className,
      )}
    >
      {startAdornment && (
        <div className={cn(styles.startAdornment, startAdornmentClassName)}>
          {startAdornment}
        </div>
      )}
      <Component
        {...restProps}
        ref={inputRef}
        className={cn(
          styles.field,
          SIZES[size],
          {
            [styles.fieldHaveValue]: !!value,
            [styles.fieldHaveError]: haveError,
            [styles.fieldMultiline]: multiline,
            [styles.fieldWithStartAdornment]: !!startAdornment,
            [styles.fieldWithEndAdornment]: !!endAdornment,
          },
          fieldClassName,
        )}
        value={value}
      />
      {endAdornment && (
        <div className={cn(styles.endAdornment, endAdornmentClassName)}>
          {endAdornment}
        </div>
      )}
    </div>
  );
};

Input.defaultProps = {
  size: 'md',
};

export default React.memo(Input);
