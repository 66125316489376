export const FAQ_QUESTIONS = [
  {
    id: 1,
    title: 'How to download & install figma plugin?',
    children: [
      { id: 1, text: 'Go to figma.com' },
      { id: 2, text: 'Click on "Explore Community" in the left side panel' },
      { id: 2, text: 'Click on "Plugins" at the top of the page' },
      { id: 3, text: 'Type "Befront" in the Search field' },
      { id: 5, text: 'Click on "Befront" plugin - you’ll see plugin page' },
      { id: 6, text: 'Click "Install" button' },
      { id: 8, text: 'Congrats! You made it!' },
    ],
  },
];
