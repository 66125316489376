import React from 'react';

import { TheoryBlock } from 'components/TheoryBlock';
import { LessonFooter } from 'components/LessonFooter';
import { LessonLayout } from 'components/Layout';

import { Action } from './components/Action';

import styles from './TheoryLessonPage.styles.scss';

const TheoryLessonPage = ({
  nextLoading,
  isLastLessonInCourse,
  lesson,
  onNextLessonClick,
  openReportLessonModal,
}) => (
  <LessonLayout>
    <div className={styles.page}>
      <div className={styles.inner}>
        <div className={styles.blocks}>
          {lesson.blocks.map((block) => (
            <TheoryBlock key={block.id} type={block.type} data={block.data} />
          ))}
        </div>

        <LessonFooter
          openReportLessonModal={openReportLessonModal}
          action={
            <Action
              nextLoading={nextLoading}
              isLastLessonInCourse={isLastLessonInCourse}
              onClick={onNextLessonClick}
            />
          }
        />
      </div>
    </div>
  </LessonLayout>
);

export default React.memo(TheoryLessonPage);
