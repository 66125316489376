export const getSectionPositionIndex = ({
  currentScrollTop,
  sectionsPositions,
}) => {
  for (let i = 0; i < sectionsPositions.length; i++) {
    const sectionPosition = sectionsPositions[i + 1];

    if (currentScrollTop <= sectionPosition) {
      return i;
    }
  }
};
