import React from 'react';
import cn from 'classnames';
import pluralize from 'pluralize';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';
import { Loading } from 'components/shared/Loading';

import { CoursePreview } from 'components/CoursePreview';

import styles from './PopularCoursesSection.styles.scss';

const PopularCoursesSection = ({
  className,
  loading,
  courses,
  onCourseClick,
}) => (
  <div className={cn(styles.section, className)}>
    <Typography className={styles.title} variant="subtitle">
      Popular Courses
    </Typography>
    <div className={styles.inner}>
      {loading ? (
        <Loading />
      ) : (
        courses.map((course) => (
          <div
            className={styles.course}
            key={course.id}
            onClick={() => onCourseClick(course.id)}
          >
            <CoursePreview
              className={styles.preview}
              preview={course.preview}
            />
            <div className={styles.courseInner}>
              <Typography
                className={styles.courseTitle}
                variant="body2"
                weight="medium"
              >
                {course.title}
              </Typography>
              <div className={styles.courseInfo}>
                <div className={styles.courseInfoItem}>
                  <Icon className={styles.courseInfoIcon} name="play" />
                  <Typography
                    className={styles.courseInfoText}
                    variant="body3"
                    weight="medium"
                  >
                    {pluralize('lessons', course.lessonsCount, true)}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  </div>
);

export default React.memo(PopularCoursesSection);
