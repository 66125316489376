import React, { useState, useEffect } from 'react';

import * as api from 'api/methods';

import { showToast } from 'helpers/showToast';

import { useResponsive } from 'hooks/useResponsive';
import { useQuery } from 'hooks/useQuery';

import { UNSUBSCRIBE_STEPS } from './UnsubscribePage.constants';

import UnsubscribePage from './UnsubscribePage';

const UnsubscribePageContainer = () => {
  const query = useQuery();

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [resubscribeLoading, setResubscribeLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(
    UNSUBSCRIBE_STEPS.CHOOSE_REASON,
  );

  useResponsive();

  useEffect(() => {
    const _unsubscribeUser = async () => {
      try {
        await api.unsubscribeCurrentUser({
          email: query.get('email'),
        });

        setLoading(false);
      } catch (error) {
        console.warn(error);

        setLoading(false);

        showToast({
          data: {
            variant: 'error',
            title: 'Error!',
            text: error.message || 'Server error',
          },
        });
      }
    };

    _unsubscribeUser();
  }, []);

  const handleSubmitClick = async (values) => {
    setCurrentStep(UNSUBSCRIBE_STEPS.REASON_SUBMITTED);

    try {
      setSubmitLoading(true);

      api.submitUnsubscribeCurrentUserReasons({
        email: query.get('email'),
        emailTemplate: query.get('emailTemplate'),
        tags: values.tags,
      });

      setSubmitLoading(false);
    } catch (error) {
      console.warn(error);

      showToast({
        data: {
          variant: 'error',
          title: 'Error!',
          text: error.message || 'Server error',
        },
      });
    }
  };

  const handleResubscribeClick = async () => {
    setCurrentStep(UNSUBSCRIBE_STEPS.RESUBSCRIBED);

    try {
      setResubscribeLoading(true);

      api.subscribeCurrentUser({
        email: query.get('email'),
      });

      setResubscribeLoading(false);
    } catch (error) {
      console.warn(error);

      showToast({
        data: {
          variant: 'error',
          title: 'Error!',
          text: error.message || 'Server error',
        },
      });
    }
  };

  return (
    <UnsubscribePage
      loading={loading}
      submitLoading={submitLoading}
      resubscribeLoading={resubscribeLoading}
      currentStep={currentStep}
      onSubmitClick={handleSubmitClick}
      onResubscribeClick={handleResubscribeClick}
    />
  );
};

export default React.memo(UnsubscribePageContainer);
