import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  ANNUALLY_PRICE_LOOKUP_KEY,
  MONTHLY_PRICE_LOOKUP_KEY,
} from 'constants/common';

import * as api from 'api/methods';

import { logEvent } from 'helpers/logEvent';
import { getSchoolNames } from 'helpers/getSchoolNames';

import { userActivitySelector, userSelector } from 'store/auth/selectors';

import { useAuthModal } from 'context/authModals';

import { useFetch } from 'hooks/useFetch';
import { useModal } from 'hooks/useModal';
import { useResponsive } from 'hooks/useResponsive';

import { formatPrices } from './PricingPage.helpers';

import { RequestDemoModal } from './components/RequestDemoModal';

import PricingPage from './PricingPage';

const PricingPageContainer = () => {
  const user = useSelector(userSelector);
  const userActivity = useSelector(userActivitySelector);

  const { dispatch } = useAuthModal();

  const [buttonLoading, setButtonLoading] = useState(null);
  const [billedYearly, setBilledYearly] = useState(true);

  const [requestDemoModalOpen, openRequestDemoModal, closeRequestDemoModal] =
    useModal({
      defaultOpen: false,
    });

  const { loading, data: prices } = useFetch(
    {
      defaultData: [],
      fetcher: api.getPaymentConfig,
      immediately: true,
    },
    null,
    [],
  );

  useResponsive();

  useEffect(() => {
    if (user && userActivity) {
      logEvent({
        eventType: 'Pricing_Page - Viewed',
        userProps: {
          user_email: user.email,
          user_is_pro: userActivity.isProSubscription,
          user_school: getSchoolNames(user.schools),
        },
      });
    } else {
      logEvent({
        eventType: 'Pricing_Page - Viewed',
      });
    }
  }, []);

  const handleDurationChange = (event) => {
    if (user && userActivity) {
      logEvent({
        eventType: 'Pricing_Page - Toggle_Yearly_Clicked',
        userProps: {
          user_email: user.email,
          user_is_pro: userActivity.isProSubscription,
          user_school: getSchoolNames(user.schools),
        },
      });
    } else {
      logEvent({
        eventType: 'Pricing_Page - Toggle_Yearly_Clicked',
      });
    }

    setBilledYearly(event.target.checked);
  };

  const handleUpgradeClick = (subscriptionType) => {
    const _upgrade = () => {
      setButtonLoading(subscriptionType);

      const currentPrice = prices.find(
        (price) => price.key === subscriptionType,
      );

      api
        .initSubscription({
          priceId: currentPrice.id,
          successUrl: '/dashboard',
          cancelUrl: window.location.href,
        })
        .then(({ url }) => {
          setButtonLoading(false);

          if (url) {
            window.location.href = url;
          }
        })
        .catch(() => {
          setButtonLoading(false);
        });
    };

    if (!user) {
      dispatch({
        type: 'OPEN_MODAL',
        payload: {
          currentModal: 'SIGNUP_MODAL',
          currentModalData: { onRegistered: _upgrade },
        },
      });

      logEvent({
        eventType: 'Pricing_Page - Upgrade_Clicked',
      });

      return;
    }

    if (user && userActivity) {
      logEvent({
        eventType: 'Pricing_Page - Upgrade_Clicked',
        userProps: {
          user_email: user.email,
          user_is_pro: userActivity.isProSubscription,
          user_school: getSchoolNames(user.schools),
        },
      });
    }

    _upgrade();
  };

  // const handleSwitchToFreeClick = () => {
  //   setButtonLoading('free');

  //   api
  //     .initBillingPortal({ returnUrl: window.location.href })
  //     .then(({ url }) => {
  //       setButtonLoading(false);

  //       window.location.href = url;
  //     })
  //     .catch(() => {
  //       setButtonLoading(false);
  //     });
  // };

  const formattedPrices = !loading ? formatPrices(prices) : [];
  const monthlyPrice = !loading
    ? formattedPrices.find((price) => price.key === MONTHLY_PRICE_LOOKUP_KEY)
    : null;
  const annuallyPrice = !loading
    ? formattedPrices.find((price) => price.key === ANNUALLY_PRICE_LOOKUP_KEY)
    : null;

  return (
    <>
      <PricingPage
        loading={loading}
        buttonLoading={buttonLoading}
        user={user}
        monthlyPrice={monthlyPrice}
        annuallyPrice={annuallyPrice}
        userActivity={userActivity}
        billedYearly={billedYearly}
        onDurationChange={handleDurationChange}
        onUpgradeClick={handleUpgradeClick}
        openRequestDemoModal={openRequestDemoModal}
      />
      <RequestDemoModal
        open={requestDemoModalOpen}
        onClose={closeRequestDemoModal}
      />
    </>
  );
};

export default React.memo(PricingPageContainer);
