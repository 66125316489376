import React from 'react';

import { ROUTES } from 'constants/routes';

import handEmojiImg from 'assets/img/hand-emoji.png';

import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';
import { NotificationCard } from 'components/shared/NotificationCard';

import { CourseCard } from 'components/CourseCard';
import { MainLayout } from 'components/Layout';
import { MyCourseCard } from 'components/MyCourseCard';

import { Section } from './components/Section';

import styles from './DashboardPage.styles.scss';

const DashboardPage = ({
  loading,
  showCard,
  expirationText,
  courses,
  featuredCourses,
  userName,
  onCloseNotificationClick,
}) => (
  <MainLayout>
    <div className={styles.page}>
      {showCard && (
        <NotificationCard
          className={styles.notificationCard}
          variant="warning"
          title="Your promo code will expire soon"
          text={`Access to the platform is valid until ${expirationText}. Contact your school to extend access`}
          onCloseClick={onCloseNotificationClick}
        />
      )}
      <div className={styles.header}>
        <Typography variant="heading1" className={styles.title}>
          {userName ? `Hello, ${userName}` : 'Welcome to Befront'}
        </Typography>
        <Typography className={styles.text} variant="body2">
          Learn anything and boost your skills
        </Typography>
        <img src={handEmojiImg} className={styles.handImg} />
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className={styles.inner}>
          {!!courses.length && (
            <Section
              className={styles.section}
              linkTo="/account/profile"
              withLink={courses.length}
              title="Continue learning"
            >
              <div className={styles.courses}>
                {courses.map((course) => (
                  <MyCourseCard
                    course={course}
                    key={course.id}
                    className={styles.course}
                  />
                ))}
              </div>
            </Section>
          )}
          {!!featuredCourses.length && (
            <Section
              className={styles.section}
              linkTo={ROUTES.COURSES_PAGE}
              withLink={featuredCourses.length >= 3}
              title="Featured courses"
            >
              <div className={styles.featuredCourses}>
                {featuredCourses.map((course) => (
                  <CourseCard
                    {...course}
                    className={styles.featuredCourse}
                    key={course.id}
                  />
                ))}
              </div>
            </Section>
          )}
        </div>
      )}
    </div>
  </MainLayout>
);

export default React.memo(DashboardPage);
