import React from 'react';
import cn from 'classnames';

import Who1Icon from 'assets/img/landing/who/who-1.svg';
import Who2Icon from 'assets/img/landing/who/who-2.svg';
import Who3Icon from 'assets/img/landing/who/who-3.svg';

import { Typography } from 'components/shared/Typography';

import { items } from './WhoSection.config';

import styles from './WhoSection.styles.scss';

const _renderIcon = (order) => {
  switch (order) {
    case 1: {
      return <Who1Icon className={styles.itemPreview} />;
    }
    case 2: {
      return <Who2Icon className={styles.itemPreview} />;
    }
    case 3: {
      return <Who3Icon className={styles.itemPreview} />;
    }
  }
};

const WhoSection = () => (
  <div className={cn(styles.section, 'section')}>
    <Typography className={styles.title} family="prompt">
      Advance in your career with Befront
    </Typography>
    <div className={styles.inner}>
      {items.map((item) => (
        <div className={styles.item} key={item.order}>
          {_renderIcon(item.order)}
          <div className={styles.itemInner}>
            <Typography className={styles.itemTitle} family="prompt">
              {item.title}
            </Typography>
            <Typography className={styles.itemText}>{item.text}</Typography>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default React.memo(WhoSection);
