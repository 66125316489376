import React from 'react';

import practice1Url from 'assets/img/landing/practice/practice-1.png';
import practice2Url from 'assets/img/landing/practice/practice-2.png';
import practice3Url from 'assets/img/landing/practice/practice-3.png';
import practice4Url from 'assets/img/landing/practice/practice-4.png';

import Practice11Icon from 'assets/img/landing/practice/practice11-icon.svg';
import Practice12Icon from 'assets/img/landing/practice/practice12-icon.svg';
import Practice13Icon from 'assets/img/landing/practice/practice13-icon.svg';
import Practice21Icon from 'assets/img/landing/practice/practice21-icon.svg';
import Practice22Icon from 'assets/img/landing/practice/practice22-icon.svg';
import Practice31Icon from 'assets/img/landing/practice/practice31-icon.svg';
import Practice32Icon from 'assets/img/landing/practice/practice32-icon.svg';
import Practice33Icon from 'assets/img/landing/practice/practice33-icon.svg';
import Practice41Icon from 'assets/img/landing/practice/practice41-icon.svg';
import Practice42Icon from 'assets/img/landing/practice/practice42-icon.svg';

import { Typography } from 'components/shared/Typography';

import styles from './Item.styles.scss';

const _renderPreview = (order) => {
  switch (order) {
    case 1: {
      return <img className={styles.preview} src={practice1Url} />;
    }
    case 2: {
      return <img className={styles.preview} src={practice2Url} />;
    }
    case 3: {
      return <img className={styles.preview} src={practice3Url} />;
    }
    case 4: {
      return <img className={styles.preview} src={practice4Url} />;
    }
  }
};

const _renderListIcon = (iconKey) => {
  switch (iconKey) {
    case '1-1': {
      return <Practice11Icon className={styles.listIcon} />;
    }
    case '1-2': {
      return <Practice12Icon className={styles.listIcon} />;
    }
    case '1-3': {
      return <Practice13Icon className={styles.listIcon} />;
    }
    case '2-1': {
      return <Practice21Icon className={styles.listIcon} />;
    }
    case '2-2': {
      return <Practice22Icon className={styles.listIcon} />;
    }
    case '3-1': {
      return <Practice31Icon className={styles.listIcon} />;
    }
    case '3-2': {
      return <Practice32Icon className={styles.listIcon} />;
    }
    case '3-3': {
      return <Practice33Icon className={styles.listIcon} />;
    }
    case '4-1': {
      return <Practice41Icon className={styles.listIcon} />;
    }
    case '4-2': {
      return <Practice42Icon className={styles.listIcon} />;
    }
  }
};

const Item = ({ items, order, title }) => (
  <div className={styles.item}>
    {_renderPreview(order)}
    <div className={styles.inner}>
      <Typography className={styles.title} family="prompt">
        {title}
      </Typography>
      <div className={styles.list}>
        {items.map((item, itemIndex) => (
          <div key={itemIndex} className={styles.listItem}>
            {_renderListIcon(item.iconKey)}
            <Typography className={styles.listText}>{item.text}</Typography>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default React.memo(Item);
