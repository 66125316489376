import React from 'react';

import BadgeIcon from 'assets/img/ph-badge.svg';

import { Typography } from 'components/shared/Typography';
import { Loading } from 'components/shared/Loading';

import styles from './ProductHuntBadge.styles.scss';

const ProductHuntBadge = ({ votesLoading, votesCount, onClick }) => (
  <div className={styles.badge} onClick={onClick}>
    <BadgeIcon />
    <div className={styles.inner}>
      <Typography className={styles.title}>Product Hunt</Typography>
      <Typography className={styles.text} weight="medium">
        #1 Product of the Week
      </Typography>
    </div>
    <div className={styles.counter}>
      {votesLoading ? (
        <Loading
          size={16}
          className={styles.loading}
          innerClassName={styles.loadingInner}
        />
      ) : (
        <>
          <div className={styles.counterIcon} />
          <Typography className={styles.counterText}>{votesCount}</Typography>
        </>
      )}
    </div>
  </div>
);

export default React.memo(ProductHuntBadge);
