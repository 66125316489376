import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';

import styles from './Difficulty.styles.scss';

const Difficulty = ({ className, iconClassName, value }) => (
  <div
    className={cn(
      styles.difficulty,
      {
        [styles.difficultyEasy]: value === 'EASY',
        [styles.difficultyMedium]: value === 'MEDIUM',
        [styles.difficultyHard]: value === 'HARD',
      },
      className,
    )}
  >
    {value === 'EASY' && (
      <Icon className={cn(styles.icon, iconClassName)} name="difficultyEasy" />
    )}
    {value === 'MEDIUM' && (
      <Icon
        className={cn(styles.icon, iconClassName)}
        name="difficultyMedium"
      />
    )}
    {value === 'HARD' && (
      <Icon className={cn(styles.icon, iconClassName)} name="difficultyHard" />
    )}
  </div>
);

export default React.memo(Difficulty);
