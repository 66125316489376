import React from 'react';
import cn from 'classnames';
import _camelCase from 'lodash/camelCase';
import _upperFirst from 'lodash/upperFirst';
import pluralize from 'pluralize';

import { COURSE_TYPES } from 'constants/course';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';
import { Difficulty } from 'components/shared/Difficulty';

import styles from './CourseInfo.styles.scss';

const renderItem = (
  itemType,
  { itemClassName, withBorder, lessonsCount, studentsCount, difficulty, type },
) => {
  switch (itemType) {
    case 'LESSONS_COUNT': {
      return (
        <div
          key={itemType}
          className={cn(
            styles.item,
            { [styles.itemWithBorder]: withBorder },
            itemClassName,
          )}
        >
          <Icon name="play" className={styles.icon} />
          <Typography
            variant="body3"
            weight="medium"
            mode="compact"
            className={styles.text}
          >
            {pluralize('lessons', lessonsCount, true)}
          </Typography>
        </div>
      );
    }
    case 'DIFFICULTY': {
      return (
        <div
          key={itemType}
          className={cn(
            styles.item,
            { [styles.itemWithBorder]: withBorder },
            itemClassName,
          )}
        >
          <Difficulty className={styles.difficultyIcon} value={difficulty} />
          <Typography
            variant="body3"
            weight="medium"
            mode="compact"
            className={styles.text}
          >
            {_upperFirst(_camelCase(difficulty))}
          </Typography>
        </div>
      );
    }
    case 'STUDENTS': {
      return (
        <div
          key={itemType}
          className={cn(
            styles.item,
            { [styles.itemWithBorder]: withBorder },
            itemClassName,
          )}
        >
          <Icon name="users" className={styles.icon} />
          <Typography
            variant="body3"
            weight="medium"
            mode="compact"
            className={styles.text}
          >
            {studentsCount}
          </Typography>
        </div>
      );
    }
    case 'TYPE': {
      return (
        <div
          key={itemType}
          className={cn(
            styles.item,
            { [styles.itemWithBorder]: withBorder },
            itemClassName,
          )}
        >
          <Icon
            name={type === COURSE_TYPES.WEB ? 'web' : 'figma'}
            className={styles.icon}
          />
          <Typography
            variant="body3"
            weight="medium"
            mode="compact"
            className={styles.text}
          >
            {type === COURSE_TYPES.WEB ? 'Web' : 'Plugin'}
          </Typography>
        </div>
      );
    }
  }
};

const CourseInfo = ({
  className,
  itemClassName,
  withBorder,
  items,
  lessonsCount,
  studentsCount,
  difficulty,
  type,
}) => (
  <div className={cn(styles.info, className)}>
    {items.map((item) =>
      renderItem(item, {
        itemClassName,
        withBorder,
        lessonsCount,
        studentsCount,
        difficulty,
        type,
      }),
    )}
  </div>
);

CourseInfo.defaultProps = {
  items: [],
  withBorder: false,
};

export default React.memo(CourseInfo);
