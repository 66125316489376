import React from 'react';
import { Formik, Form } from 'formik';

import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import { FormikField } from 'components/FormikField';
import { InputField } from 'components/FormikFields';

import { initialValues, validationSchema } from './EnterEmailForm.formConfig';

import styles from './EnterEmailForm.styles.scss';

const EnterEmailForm = ({
  submitLoading,
  withPromocodeButton,
  buttonText,
  onSubmit,
  onHavePromocodeClick,
}) => (
  <Formik
    validateOnMount
    enableReinitialize
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ values }) => (
      <Form className={styles.form}>
        <FormikField
          name="email"
          component={InputField}
          controlProps={{
            className: styles.emailControl,
            required: true,
            label: 'Email',
          }}
          componentProps={{
            fieldClassName: styles.emailField,
            size: 'lg',
            placeholder: 'name@mail.com',
          }}
        />
        <div className={styles.actions}>
          {withPromocodeButton && (
            <Button
              className={styles.action}
              size="md"
              variant="outlined"
              onClick={() => onHavePromocodeClick(values)}
            >
              I have a promocode
            </Button>
          )}
          <Button
            className={styles.action}
            type="submit"
            startIcon={
              submitLoading && <Loading color="var(--color-white-primary)" />
            }
          >
            {buttonText}
          </Button>
        </div>
      </Form>
    )}
  </Formik>
);

EnterEmailForm.defaultProps = {
  withPromocodeButton: true,
  buttonText: 'Continue',
};

export default React.memo(EnterEmailForm);
