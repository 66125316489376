import React from 'react';
import cn from 'classnames';

import CourseDefaultPreview from 'assets/img/course-preview.svg';

import styles from './CoursePreview.styles.scss';

const SIZES = {
  sm: styles.sm,
  md: styles.md,
};

const CoursePreview = ({ className, size, preview }) =>
  preview ? (
    <img className={cn(styles.preview, SIZES[size], className)} src={preview} />
  ) : (
    <CourseDefaultPreview
      className={cn(styles.preview, SIZES[size], className)}
    />
  );

CoursePreview.defaultProps = {
  size: 'sm',
};

export default React.memo(CoursePreview);
