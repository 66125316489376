import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import * as api from 'api/methods';

import { useFetch } from 'hooks/useFetch';

import { formatCourses } from './PopularCoursesSection.helpers';

import PopularCoursesSection from './PopularCoursesSection';

const PopularCoursesSectionContainer = (props) => {
  const { id: currentCourseId } = useParams();

  const navigate = useNavigate();

  const { loading, data: courses } = useFetch(
    {
      defaultData: [],
      fetcher: api.getFeaturedCourses,
      immediately: true,
    },
    null,
    [],
  );

  const handleCourseClick = (courseId) => {
    navigate(`/courses/${courseId}`);
  };

  return (
    <PopularCoursesSection
      {...props}
      loading={loading}
      courses={formatCourses({ courses, currentCourseId })}
      onCourseClick={handleCourseClick}
    />
  );
};

export default React.memo(PopularCoursesSectionContainer);
