import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import { LessonLayout } from 'components/Layout';
import { LessonFooter } from 'components/LessonFooter';

import { Action } from './components/Action';
import { CurrentAnswer } from './components/CurrentAnswer';

import styles from './DifferenceLessonPage.styles.scss';

const DifferenceLessonPage = ({
  className,
  submitted,
  nextLoading,
  isLastLessonInCourse,
  currentAnswer,
  currentCompareAnswer,
  lesson,
  onAnswerClick,
  onNextLessonClick,
  openReportLessonModal,
}) => (
  <LessonLayout>
    <div className={cn(styles.page, className)}>
      <Typography variant="subtitle">{lesson.text}</Typography>
      {submitted ? (
        <CurrentAnswer
          answer={currentAnswer}
          compareAnswer={currentCompareAnswer}
        />
      ) : (
        <div className={styles.answers}>
          <div className={styles.answer}>
            <img
              className={styles.answerImg}
              src={lesson.leftItem.url}
              onClick={() =>
                onAnswerClick({
                  answer: lesson.leftItem,
                  compareAnswer: lesson.rightItem,
                })
              }
            />
          </div>
          <div className={styles.answer}>
            <img
              className={styles.answerImg}
              src={lesson.rightItem.url}
              onClick={() =>
                onAnswerClick({
                  answer: lesson.rightItem,
                  compareAnswer: lesson.leftItem,
                })
              }
            />
          </div>
        </div>
      )}
      <LessonFooter
        openReportLessonModal={openReportLessonModal}
        action={
          <Action
            submitted={submitted}
            nextLoading={nextLoading}
            isLastLessonInCourse={isLastLessonInCourse}
            onNextLessonClick={onNextLessonClick}
          />
        }
      />
    </div>
  </LessonLayout>
);

export default React.memo(DifferenceLessonPage);
