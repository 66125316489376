import { IMAGE_SIZE, VIDEO_SIZE } from './Upload.constants';

export const getValidator = (file) => {
  if (file.type.includes('image/')) {
    if (file.size > IMAGE_SIZE) {
      return {
        code: '1',
        message: 'File is too large. Allowed maximum size is 4 MB',
      };
    }
  }

  if (file.type.includes('video/')) {
    if (file.size > VIDEO_SIZE) {
      return {
        code: '2',
        message: 'File is too large. Allowed maximum size is 20 MB',
      };
    }
  }

  return null;
};
