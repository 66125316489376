import React, { useState, useRef, createRef, useEffect } from 'react';

import { SECTIONS_COUNT, HEADER_HEIGHT } from './PrivacyPolicyPage.constants';

import { getSectionPositionIndex } from './PrivacyPolicyPage.helpers';

import PrivacyPolicyPage from './PrivacyPolicyPage';

const PrivacyPolicyPageContainer = () => {
  const sectionsRef = useRef(
    Array.from(Array(SECTIONS_COUNT)).map(() => createRef(null)),
  );

  const [sectionsPositions, setSectionsPositions] = useState([]);
  const [currentScrollTop, setCurrentScrollTop] = useState(0);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(null);

  useEffect(() => {
    if (sectionsRef.current) {
      for (let i = 0; i < sectionsRef.current.length; i++) {
        const sectionBox =
          sectionsRef.current[i].current.getBoundingClientRect();

        setSectionsPositions((prevPositions) => [
          ...prevPositions,
          sectionBox.top - HEADER_HEIGHT,
        ]);
      }
    }
  }, [sectionsRef]);

  const _handleScroll = () => {
    const element = document.querySelector('.home-layout');

    setCurrentScrollTop(element.scrollTop);
  };

  useEffect(() => {
    const element = document.querySelector('.home-layout');

    element.addEventListener('scroll', _handleScroll);

    return () => {
      element.removeEventListener('scroll', _handleScroll);
    };
  }, []);

  useEffect(() => {
    if (currentScrollTop === 0) {
      setCurrentSectionIndex(0);
    } else {
      setCurrentSectionIndex(
        getSectionPositionIndex({ currentScrollTop, sectionsPositions }),
      );
    }
  }, [currentScrollTop]);

  const handleItemClick = (index) => {
    sectionsRef.current[index].current.scrollIntoView();
  };

  return (
    <>
      <PrivacyPolicyPage
        sectionsRef={sectionsRef}
        currentSectionIndex={currentSectionIndex}
        onItemClick={handleItemClick}
      />
    </>
  );
};

export default React.memo(PrivacyPolicyPageContainer);
