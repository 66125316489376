import React, { useEffect, useState } from 'react';

import ProductHuntBadge from './ProductHuntBadge';

const ProductHuntBadgeContainer = (props) => {
  const [votesLoading, setVotesLoading] = useState(true);
  const [votesCount, setVotesCount] = useState(0);

  const handleClick = () => {
    window.open('https://producthunt.com/posts/befront', '_blank');
  };

  useEffect(() => {
    fetch('https://api.producthunt.com/v2/api/graphql', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer 8Vxyfl-BwCwa-2QiFa3ckHDwd9BSzwofYrJGNlQ98zM',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: '{post(id:"344154"){votesCount}}',
        variables: null,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        const {
          data: { post },
        } = response;

        setVotesLoading(false);

        if (post?.votesCount > 0) {
          setVotesCount(post?.votesCount);
        }
      })
      .catch(() => {
        setVotesLoading(false);
      });
  }, []);

  return (
    <ProductHuntBadge
      {...props}
      votesLoading={votesLoading}
      votesCount={votesCount}
      onClick={handleClick}
    />
  );
};

export default React.memo(ProductHuntBadgeContainer);
