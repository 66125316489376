export const SOCIAL_ICONS = [
  {
    icon: 'linkedinSocial',
    link: 'https://www.linkedin.com/company/befront/',
  },
  {
    icon: 'instagramSocial',
    link: 'https://www.instagram.com/befront.io/',
  },
  {
    icon: 'discordSocial',
    link: 'https://discord.gg/XBr29KFEgd',
  },
  {
    icon: 'tiktokSocial',
    link: 'https://www.tiktok.com/@befront.io',
  },
];
