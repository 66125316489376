import React from 'react';
import pluralize from 'pluralize';

import { Typography } from 'components/shared/Typography';

import { Module } from '../Module';

import { prepareModule, getFreeLessonsCount } from './ModuleSection.helpers';

import styles from './ModulesSection.styles.scss';

const ModulesSection = ({
  courseAvailable,
  modules,
  completedModules,
  completedLessons,
  courseType,
}) => (
  <div className={styles.section}>
    <div className={styles.header}>
      <Typography className={styles.title} variant="heading2">
        Course Modules
      </Typography>
      {!courseAvailable && getFreeLessonsCount(modules).length > 0 && (
        <div className={styles.tag}>
          <Typography
            className={styles.tagText}
            variant="body3"
            weight="semiBold"
          >
            {pluralize('LESSONS', getFreeLessonsCount(modules), true)} FREE
          </Typography>
        </div>
      )}
    </div>
    <div className={styles.inner}>
      {modules.map((module) => (
        <Module
          key={module.id}
          completedLessons={completedLessons}
          courseType={courseType}
          {...prepareModule({
            courseAvailable,
            module,
            completedModules,
            completedLessons,
          })}
        />
      ))}
    </div>
  </div>
);

export default React.memo(ModulesSection);
