import React from 'react';
import cn from 'classnames';

import { Footer } from 'components/Footer';

import { Menu } from './components/Menu';
import { Header } from './components/Header';

import styles from './HomeLayout.styles.scss';

const HomeLayout = ({ user, children, onSignUpClick, onSignInClick }) => (
  <div className={cn(styles.layout, 'home-layout')}>
    <Header onSignUpClick={onSignUpClick} onSignInClick={onSignInClick} />
    <div className={styles.inner}>{children}</div>
    <Footer />
    {!user && (
      <Menu onSignUpClick={onSignUpClick} onSignInClick={onSignInClick} />
    )}
  </div>
);

export default React.memo(HomeLayout);
