import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getCourseById } from 'store/course/actions';
import { getLessonById } from 'store/lesson/actions';

import { lessonSelector } from 'store/lesson/selectors';

import { useCourseNextLesson } from 'hooks/useCourseNextLesson';
import { useModal } from 'hooks/useModal';

import { RateModal } from 'components/RateModal';
import { ReportLessonModal } from 'components/ReportLessonModal';
import { UnlockLessonsModal } from 'components/UnlockLessonsModal';

import LessonProvider from './LessonProvider';

const LessonProviderContainer = () => {
  const { courseId, lessonId } = useParams();

  const dispatch = useDispatch();

  const lesson = useSelector(lessonSelector);

  const [loading, setLoading] = useState(true);

  const [rateModalOpen, openRateModal, closeRateModal, rateModalData] =
    useModal({
      defaultOpen: false,
    });
  const [
    unlockLessonsModalOpen,
    openUnlockLessonsModal,
    closeUnlockLessonsModal,
  ] = useModal({ defaultOpen: false });
  const [reportLessonModalOpen, openReportLessonModal, closeReportLessonModal] =
    useModal({
      defaultOpen: false,
    });

  const [nextLoading, isLastLessonInCourse, onNextLessonClick] =
    useCourseNextLesson({
      lessonId,
      openRateModal,
      closeRateModal,
      openUnlockLessonsModal,
    });

  useEffect(() => {
    setLoading(true);

    Promise.all([
      dispatch(getCourseById(courseId)),
      dispatch(getLessonById({ courseId, lessonId })),
    ]).finally(() => {
      setLoading(false);
    });
  }, [lessonId]);

  return (
    <>
      <LessonProvider
        loading={loading}
        nextLoading={nextLoading}
        isLastLessonInCourse={isLastLessonInCourse}
        lessonType={lesson?.type}
        openReportLessonModal={openReportLessonModal}
        onNextLessonClick={onNextLessonClick}
      />
      <RateModal
        {...rateModalData}
        open={rateModalOpen}
        onClose={closeRateModal}
      />
      <UnlockLessonsModal
        open={unlockLessonsModalOpen}
        onClose={closeUnlockLessonsModal}
      />
      <ReportLessonModal
        open={reportLessonModalOpen}
        onClose={closeReportLessonModal}
      />
    </>
  );
};

export default React.memo(LessonProviderContainer);
