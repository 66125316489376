import _orderBy from 'lodash/orderBy';

export const getSchoolNames = (schools) => {
  if (!schools || schools.length === 0) {
    return '';
  }

  return _orderBy(schools, ['name'], ['asc'])
    .map((school) => school.name)
    .toString();
};
