import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import { SECTOR_LENGTH, CIRCLE_LENGTH } from './RoundProgressBar.constants';

import styles from './RoundProgressBar.styles.scss';

const RoundProgressBar = ({
  className,
  textClassName,
  withProgressText,
  lineSize,
  size,
  progress,
  progressColor,
  backLineColor,
  backLineOpacity,
  textVariant,
}) => {
  const progressLength = `${SECTOR_LENGTH * progress},${
    CIRCLE_LENGTH - SECTOR_LENGTH * progress
  }`;

  return (
    <div
      className={cn(styles.progress, className)}
      style={{ width: size, height: size }}
    >
      <svg viewBox="0 0 100 100">
        <path
          fill="transparent"
          strokeLinecap="round"
          strokeWidth={lineSize}
          stroke={backLineColor}
          strokeDasharray={`${CIRCLE_LENGTH} 0`}
          strokeOpacity={backLineOpacity}
          d="M50 10 a 40 40 0 0 1 0 80 a 40 40 0 0 1 0 -80"
        />
        <path
          className={styles.path}
          fill="transparent"
          strokeLinecap="round"
          strokeWidth={lineSize}
          stroke={progressColor}
          strokeDasharray={progressLength}
          d="M50 10 a 40 40 0 0 1 0 80 a 40 40 0 0 1 0 -80"
        />
      </svg>
      {withProgressText && (
        <Typography
          className={cn(styles.text, textClassName)}
          variant={textVariant}
        >
          {progress}%
        </Typography>
      )}
    </div>
  );
};

RoundProgressBar.defaultProps = {
  withProgressText: false,
  backLineOpacity: 1,
  backLineColor: 'var(--color-green-primary',
  progressColor: 'var(--color-green-primary)',
};

export default React.memo(RoundProgressBar);
