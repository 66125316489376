import * as Yup from 'yup';

export const initialValues = {
  email: '',
};

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required.'),
});
