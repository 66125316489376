import React from 'react';

import { Lesson } from '../Lesson';

import styles from './Lessons.styles.scss';

const Lessons = ({ lessons, onLessonClick }) => (
  <div className={styles.lessons}>
    {lessons.map((lesson) => (
      <Lesson
        key={lesson.id}
        id={lesson.id}
        available={lesson.available}
        completed={lesson.completed}
        current={lesson.current}
        order={lesson.order}
        title={lesson.title}
        onClick={onLessonClick}
      />
    ))}
  </div>
);

export default React.memo(Lessons);
