import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BeforeStartCoursePage from './BeforeStartCoursePage';

const BeforeStartCoursePageContainer = (props) => {
  const navigate = useNavigate();

  const { id: courseId } = useParams();

  const handleInstallLinkClick = () => {
    window.open(
      'https://www.figma.com/community/plugin/946462257210461125/Befront',
      '_blank',
    );
  };

  const handleBackClick = () => {
    navigate(`/courses/${courseId}`);
  };

  return (
    <BeforeStartCoursePage
      {...props}
      onInstallLinkClick={handleInstallLinkClick}
      onBackClick={handleBackClick}
    />
  );
};

export default React.memo(BeforeStartCoursePageContainer);
