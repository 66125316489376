import React from 'react';
import { useSelector } from 'react-redux';

import { userSelector } from 'store/auth/selectors';

import { useFavorite } from 'hooks/useFavorite';

import FavoriteIcon from './FavoriteIcon';

const FavoriteIconContainer = ({
  courseId,
  favorite: defaultFavorite,
  onChange,
  ...restProps
}) => {
  const user = useSelector(userSelector);

  const [favorite, handleFavoriteClick] = useFavorite({
    defaultFavorite,
    courseId,
    onChange,
  });

  if (!user) {
    return null;
  }

  return (
    <FavoriteIcon
      {...restProps}
      favorite={favorite}
      onClick={handleFavoriteClick}
    />
  );
};

export default React.memo(FavoriteIconContainer);
