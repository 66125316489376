import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logEvent } from 'helpers/logEvent';
import { getSchoolNames } from 'helpers/getSchoolNames';

import { userActivitySelector, userSelector } from 'store/auth/selectors';
import { lessonSelector } from 'store/lesson/selectors';
import { courseSelector } from 'store/course/selectors';

import UnlockLessonsModal from './UnlockLessonsModal';

const UnlockLessonsModalContainer = ({ onClose, ...restProps }) => {
  const navigate = useNavigate();

  const user = useSelector(userSelector);
  const userActivity = useSelector(userActivitySelector);
  const course = useSelector(courseSelector);
  const lesson = useSelector(lessonSelector);

  const handleContinueClick = () => {
    logEvent({
      eventType: 'Course_Lesson_Page - Continue_Clicked',
      eventProps: {
        course_title: course.title,
        lesson_title: lesson?.title,
      },
      userProps: {
        user_email: user.email,
        user_is_pro: userActivity.isProSubscription,
        user_school: getSchoolNames(user.schools),
      },
    });

    navigate('/pricing');
  };

  const handleDashboardClick = () => {
    logEvent({
      eventType: 'Course_Lesson_Page - NotNow_Clicked',
      eventProps: {
        course_title: course.title,
        lesson_title: lesson?.title,
      },
      userProps: {
        user_email: user.email,
        user_is_pro: userActivity.isProSubscription,
        user_school: getSchoolNames(user.schools),
      },
    });

    onClose();
  };

  return (
    <UnlockLessonsModal
      {...restProps}
      onClose={onClose}
      onDashboardClick={handleDashboardClick}
      onContinueClick={handleContinueClick}
    />
  );
};

export default React.memo(UnlockLessonsModalContainer);
