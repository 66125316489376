import React from 'react';

import { COOKIE_ENABLED } from 'constants/common';

import CookieBanner from './CookieBanner';

const CookieBannerContainer = (props) => {
  const handleAcceptCookieClick = (value) => {
    localStorage.setItem(COOKIE_ENABLED, value);
    window.dispatchEvent(new Event('storage'));
  };

  return (
    <CookieBanner {...props} onAcceptCookieClick={handleAcceptCookieClick} />
  );
};

export default React.memo(CookieBannerContainer);
