import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateUserInfo } from 'store/auth/actions';

import Form from './Form';

const FormContainer = ({ onCloseModal, ...restProps }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleStartLearningClick = async (values) => {
    try {
      setLoading(true);

      const data = {
        ...values,
        firstLogin: false,
      };

      await dispatch(updateUserInfo(data));

      onCloseModal();
    } catch (error) {
      console.warn(error);
    }

    setLoading(false);
  };

  return (
    <Form
      {...restProps}
      loading={loading}
      onStartLearningClick={handleStartLearningClick}
    />
  );
};

export default React.memo(FormContainer);
