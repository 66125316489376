import React, { useState } from 'react';

import * as api from 'api/methods';

import { showToast } from 'helpers/showToast';

import { convertDataToApi } from './RequestDemoModal.helpers';

import RequestDemoModal from './RequestDemoModal';

const RequestDemoModalContainer = ({ onClose, ...restProps }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmitClick = async (values) => {
    try {
      setLoading(true);

      const data = convertDataToApi(values);

      await api.sendSchoolDemoRequest(data);

      showToast({
        data: {
          variant: 'success',
          text: 'You are successfully request for the demo',
        },
      });
    } catch (error) {
      console.warn(error);

      showToast({
        data: {
          variant: 'error',
          title: 'Error!',
          text: error.message || 'Server error',
        },
      });
    }

    setLoading(false);

    onClose();
  };

  return (
    <RequestDemoModal
      {...restProps}
      onClose={onClose}
      loading={loading}
      onSubmitClick={handleSubmitClick}
    />
  );
};

export default React.memo(RequestDemoModalContainer);
