import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './Checkbox.styles.scss';

const Checkbox = ({
  className,
  iconWrapperClassName,
  disabled,
  label,
  ...restProps
}) => (
  <div
    className={cn(
      styles.checkbox,
      {
        [styles.checkboxWithLabel]: label,
        [styles.checkboxDisabled]: disabled,
      },
      className,
    )}
  >
    <input type="checkbox" className={styles.field} {...restProps} />
    <div className={cn(styles.iconWrapper, iconWrapperClassName)}>
      <Icon name="check" className={styles.icon} />
    </div>
    {label && <Typography className={styles.label}>{label}</Typography>}
  </div>
);

export default React.memo(Checkbox);
