import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { COURSE_TYPES } from 'constants/common';

import { startCourseById } from 'store/course/actions';

import Module from './Module';

const ModuleContainer = ({ lessons, courseType, ...restProps }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id: courseId } = useParams();

  const [startLoadingId, setStartLoadingId] = useState(null);

  const handleLessonClick = async ({ id, available }) => {
    if (courseType === COURSE_TYPES.FIGMA_PLUGIN) {
      navigate(`/courses/${courseId}/before-start`);

      return;
    }

    try {
      if (available) {
        setStartLoadingId(id);

        await dispatch(startCourseById(courseId));

        setStartLoadingId(null);

        navigate(`/courses/${courseId}/lessons/${id}`);
      }
    } catch (error) {
      console.warn(error);

      setStartLoadingId(null);
    }
  };

  return (
    <Module
      {...restProps}
      lessons={lessons}
      startLoadingId={startLoadingId}
      onLessonClick={handleLessonClick}
    />
  );
};

export default React.memo(ModuleContainer);
