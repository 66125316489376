import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { logEvent } from 'helpers/logEvent';
import { getSchoolNames } from 'helpers/getSchoolNames';

import { userActivitySelector, userSelector } from 'store/auth/selectors';
import { courseSelector } from 'store/course/selectors';
import { lessonSelector } from 'store/lesson/selectors';

import DifferenceLessonPage from './DifferenceLessonPage';

const DifferenceLessonPageContainer = (props) => {
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [currentCompareAnswer, setCurrentCompareAnswer] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const user = useSelector(userSelector);
  const userActivity = useSelector(userActivitySelector);
  const course = useSelector(courseSelector);
  const lesson = useSelector(lessonSelector);

  useEffect(() => {
    logEvent({
      eventType: 'Lesson_Page - Viewed',
      eventProps: {
        course_title: course.title,
        lesson_title: lesson?.title,
      },
      userProps: {
        user_email: user.email,
        user_is_pro: userActivity.isProSubscription,
        user_school: getSchoolNames(user.schools),
      },
    });
  }, []);

  const handleAnswerClick = ({ answer, compareAnswer }) => {
    setCurrentAnswer(answer);
    setCurrentCompareAnswer(compareAnswer);
    setSubmitted(true);
  };

  return (
    <DifferenceLessonPage
      {...props}
      submitted={submitted}
      lesson={lesson}
      currentAnswer={currentAnswer}
      currentCompareAnswer={currentCompareAnswer}
      onAnswerClick={handleAnswerClick}
    />
  );
};

export default React.memo(DifferenceLessonPageContainer);
