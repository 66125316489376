import React from 'react';
import SimpleBar from 'simplebar-react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import { HomeLayout } from 'components/Layout';

import { MENU_ITEMS } from './PrivacyPolicyPage.constants';

import styles from './PrivacyPolicyPage.styles.scss';

const PrivacyPolicyPage = ({
  sectionsRef,
  currentSectionIndex,
  onItemClick,
}) => (
  <HomeLayout>
    <div className={styles.page}>
      <div className={styles.header}>
        <SimpleBar>
          <div className={styles.menu}>
            {MENU_ITEMS.map((item, index) => (
              <div
                key={item}
                className={cn(styles.menuItem, {
                  [styles.menuItemActive]: index === currentSectionIndex,
                })}
                onClick={() => onItemClick(index)}
              >
                <Typography
                  variant="body1"
                  weight="semiBold"
                  className={styles.menuItemText}
                >
                  {item}
                </Typography>
              </div>
            ))}
          </div>
        </SimpleBar>
      </div>

      <div className={styles.inner}>
        <div className={styles.section}>
          <Typography className={styles.title} variant="heading2">
            Befront’s Privacy Policy
          </Typography>
          <Typography
            variant="body1"
            weight="medium"
            className={styles.caption}
          >
            This Policy was last updated January 25, 2020.
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              Befront, Inc. ( “Befront” “we,” “our,” or “us”) is committed to
              protecting your privacy. Our Privacy Policy (the “Policy”)
              explains how we collect, use, disclose, and protect information
              about you or associated with you (“personal information”), and
              your choices about the collection and use of your information.
              This Privacy Policy applies to the online services offered by
              Befront such as our website at https://befront.io, and any other
              online service location (collectively the “Service”) that makes
              this Privacy Policy available to you.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              By using the Service, you agree to our Terms of Use and consent to
              our collection, use and disclosure practices, and other activities
              as described in this Privacy Policy. If you do not agree and
              consent, discontinue use of the Service.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              Depending on where you are located or reside, you may be entitled
              to additional rights.
            </Typography>
            <ol className={styles.sectionOrderedList}>
              <Typography
                component="li"
                variant="body1"
                className={styles.sectionOrderedListItem}
              >
                If you are a data subject in the European Union, please see the
                “Additional Disclosures for People in Europe” section below;
              </Typography>
              <Typography
                component="li"
                variant="body1"
                className={styles.sectionOrderedListItem}
              >
                If you are a California resident please see the “Additional
                Disclosures for California Residents” section below; and
              </Typography>
              <Typography
                component="li"
                variant="body1"
                className={styles.sectionOrderedListItem}
              >
                If you are a Nevada resident please see the “Additional
                Disclosures for Nevada Residents” section below.
              </Typography>
            </ol>
            <Typography variant="body1" className={styles.sectionText}>
              If you have any questions or wish to exercise your rights and
              choices, please contact us as set out in the “Contact Us” section.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[0]}>
          <Typography className={styles.sectionTitle} variant="subtitle">
            Information Collection
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              This section will discuss what type of information is collected.
              This includes categories of information that (1) you provide to us
              directly, (2) is collected by us automatically, and (3) we receive
              from third-party sources.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            1. Information You Provide Directly to Us
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              You can browse our site without providing personal information,
              but you must register in order to access most of the features of
              Service. Certain personal information—such as a user name, email
              address—are required to create an account. However, after you set
              up an account, you may choose what additional information may be
              shared through public profiles, including your name, location,
              website, links to your social media profiles, and other
              information that may be considered personal information.
              Similarly, should you choose to engage in messaging through the
              Service, the contents of such messages may be processed and saved
              by us. You may choose to voluntarily provide other information to
              us that we do not request, and, in such instances, you are solely
              responsible for such information.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              The following are categories of information we collect and have
              collected directly from you in the last 12 months:
            </Typography>
            <ul className={styles.sectionUnorderedList}>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Contact Data, including your first and last name, email address,
                postal address, and phone number.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Account Credentials, including your username, password, and
                information for authentication and account access.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Demographic Data, including your age, gender, and country.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Billing Data, including your payment instrument number (such as
                a credit or debit card number), expiration date, and security
                code as necessary to process your payments.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Content, including content within any messages you send to us
                (such as feedback and questions to customer support) or publicly
                post on the Service (such as in your public profile, in reviews,
                and comments) as well as any academic content that you generate
                and submit for grading.
              </Typography>
            </ul>
            <Typography variant="body1" className={styles.sectionText}>
              You may choose to voluntarily provide other information to us,
              and, in such instances, you are solely responsible for such
              information.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            2. Information Collected Automatically
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              In addition, we automatically collect information when you use the
              Service. The categories of information we automatically collect
              and have collected in the last 12 months includes:
            </Typography>
            <ul className={styles.sectionUnorderedList}>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Service Use Data, including data about features you use, pages
                you visit, emails and advertisements you view, products and
                services you view and purchase, the time of day you browse, and
                your referring and exiting pages.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Device Data, including data about the type of device or browser
                you use, your device’s operating software, your internet service
                provider, your device’s regional and language settings, and
                device identifiers such as IP address and Ad Id.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Location Data, including imprecise location data (such as
                location derived from an IP address or data that indicates a
                city or postal code level) and, with your consent, precise
                location data (such as latitude/longitude data).
              </Typography>
            </ul>
            <Typography variant="body1" className={styles.sectionText}>
              We use various current – and later – developed tracking
              technologies to automatically collect information when you use the
              Service, including the following:
            </Typography>
            <ul className={styles.sectionUnorderedList}>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Log Files, which are files that record events that occur in
                connection with your use of the Service.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Cookies, which are small data files stored on your device that
                act as a unique tag to identify your browser. We use two types
                of cookies: session cookies and persistent cookies. Session
                cookies make it easier for you to navigate our website and
                expire when you close your browser. Persistent cookies help with
                personalizing your experience, remembering your preferences, and
                supporting security features. Additionally, persistent cookies
                allow us to bring you advertising both on and off the Service.
                Persistent cookies may remain on your device for extended
                periods of time, and generally may be controlled through your
                browser settings.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Pixels (also known as web beacons), which is code embedded in a
                website, video, email, or advertisement that sends information
                about your use to a server. There are various types of pixels,
                including image pixels (which are small graphic images) and
                JavaScript pixels (which contains JavaScript code). When you
                access a website, video, email, or advertisement that contains a
                pixel, the pixel may permit us or a separate entity to drop or
                read cookies on your browser. Pixels are used in combination
                with cookies to track activity by a particular browser on a
                particular device. We may incorporate pixels from separate
                entities that allow us to track our conversions, bring you
                advertising both on and off the Service, and provide you with
                additional functionality, such as the ability to connect our
                Service with your social media account.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Device Fingerprinting, which is the process of analyzing and
                combining sets of data elements from your device’s browser, such
                as JavaScript objects and installed fonts, to create a
                “fingerprint” of your device and uniquely identify your browser
                and device
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                App Technologies, which are technologies included in our apps
                that are not browser-based like cookies and cannot be controlled
                by browser settings. For example, our apps may include SDKs,
                which is code that sends information about your use to a server.
                These SDKs allow us track our conversions, bring you advertising
                both on and off the Service, and provide you with additional
                functionality, such as the ability to connect our Service with
                your social media account.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Location-Identifying Technologies, which are technologies used
                to collect your location. For example, GPS, WiFi, and Bluetooth
                may be used to collect precise location data when you consent to
                precise location tracking through our apps. Location data may be
                used for purposes such as verifying your device’s location and
                delivering or restricting relevant content and advertising based
                on that location.
              </Typography>
            </ul>
            <Typography variant="body1" className={styles.sectionText}>
              For further information on how we use tracking technologies for
              analytics and advertising, and your rights and choices regarding
              them, see the “Analytics and Advertising” and “Your Rights and
              Choices” sections below.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            3. Information From Other Sources
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              We also collect information from other sources. The categories of
              sources we collect information from including in the last 12
              months, include:
            </Typography>
            <ul className={styles.sectionUnorderedList}>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Data brokers or resellers from which we purchase data to
                supplement the data we collect.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Social networks when you engage with our content, reference our
                Service, or grant us permission to access information from the
                social networks.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Partners that offer co-branded services, sell or distribute our
                products, or engage in joint marketing activities.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Publicly-available sources, including data in the public domain.
              </Typography>
            </ul>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[1]}>
          <Typography
            className={styles.sectionTitle}
            variant="body1"
            weight="semiBold"
          >
            Use of Information
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              This section discusses how collected information is used by
              Befront.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              We collect this information for a number of business purposes
              including to operate, maintain, and provide to you the features
              and functionality of the Service, as well as to communicate
              directly with you, such as to send you email messages.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              The categories with respect to our business purposes for using
              information, including in the last 12 months, include:
            </Typography>
            <ul className={styles.sectionUnorderedList}>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Operate and manage our Service, including your registration and
                account.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Perform services requested by you, such as respond to your
                comments, questions, and requests, and provide customer service.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Sending you technical notices, updates, security alerts,
                information regarding changes to our policies, and support and
                administrative messages.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Preventing and addressing fraud, breach of policies or terms,
                and threats or harm.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Monitoring and analyzing trends, usage, and activities.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Conducting research, including focus groups and surveys.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Improving the Service or other Befront websites, apps, marketing
                efforts, products and services.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Developing and sending you direct marketing, including
                advertisements and communications about our and other entities’
                products, offers, promotions, rewards, events, and services.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Conducting promotions, including verifying your eligibility and
                delivering prizes in connection with your entries.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Sending you advertising.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Fulfilling any other business or commercial purposes at your
                direction or with your notice and/or consent.
              </Typography>
            </ul>
            <Typography variant="body1" className={styles.sectionText}>
              Notwithstanding the above, we may use information that does not
              identify you (including information that has been aggregated or
              de-identified) for any purpose except as prohibited by applicable
              law. For information on your rights and choices regarding how we
              use information about you, please see the “Your Rights and
              Choices” section below.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[2]}>
          <Typography className={styles.sectionTitle} variant="subtitle">
            Sharing of Information
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              This section explains our sharing practices with respect to
              information collected.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              We share information we collect in accordance with the practices
              described in this Privacy Policy. The categories of entities to
              whom we disclose and have shared information with in the last 12
              months, include the following:
            </Typography>
            <ul className={styles.sectionUnorderedList}>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Service Providers. We share information with entities that
                process information on our behalf for our business purposes.
                Service providers assist us with services such as payment
                processing, chat functionality, data analytics, marketing and
                advertising, website hosting, and technical support. We may
                permit our service providers to use information that does not
                identify you (including information that has been aggregated or
                de-identified) for any purpose except as prohibited by
                applicable law.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Vendors and Other Parties. We share information with vendors for
                business and commercial purposes, including analytics and
                advertising technology companies. Vendors may act as our service
                providers, or in certain contexts, independently decide how to
                process your information. For more information on advertising
                and analytics, see the “Analytics and Advertising” section
                below.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Affiliates. We share information with our related entities
                including our parent and sister companies for business purposes
                such as customer support, marketing, and technical operations.
                We also may share information with affiliates for commercial
                purposes.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Corporate and School Customers. We share information with our
                corporate customers and schools that purchase our Service in
                connection with us processing information on their behalf. For
                example, we share information with our corporate and school
                customers in order to facilitate your orders, maintain and
                administer your online accounts, provide feedback, academic
                content, and student performance data to account managers and
                instructors, respond to your questions and comments, provide
                support, comply with your requests, market and advertise to you,
                and otherwise comply with applicable law.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Partners. We share information with our partners (such as
                bootcamps, interview prep organizations, and employment
                marketplaces) in connection with offering co-branded services,
                selling or distributing our products, or engaging in joint
                marketing activities.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Promotions. When you voluntarily enter a sweepstakes, contest,
                or other promotion, we share information as set out in the
                official rules that govern the promotion as well as for
                administrative purposes and as required by law (e.g., on a
                winners list). By entering a promotion, you agree to the
                official rules that govern that promotion, and may, except where
                prohibited by applicable law, allow the sponsor and/or other
                entities to use your name, voice and/or likeness in advertising
                or marketing materials.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Public Information. We share information you make public through
                the Service, such as information in your profile or that you
                post on public boards or other public sections of the Service.
                Please think carefully before making information public as you
                are solely responsible for any information you make public. Once
                you have posted information, you may not be able to edit or
                delete such information, subject to additional rights set out in
                the “Your Rights and Choices” section below.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Merger or Acquisition. We share information in connection with,
                or during negotiations of, any proposed or actual merger,
                purchase, sale or any other type of acquisition or business
                combination of all or any portion of our assets, or transfer of
                all or a portion of our business to another business.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Security and Compelled Disclosure. We share information to
                comply with the law or other legal process, and where required,
                in response to lawful requests by public authorities, including
                to meet national security or law enforcement requirements. We
                also share information to protect the rights, property, life,
                health, security and safety of us, the Service or anyone else.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Facilitating Requests. We share information at your request or
                direction, such as when you choose to share information with a
                social network about your activities on the Service.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Consent. We share information with notice to you and your
                consent.
              </Typography>
            </ul>
            <Typography variant="body1" className={styles.sectionText}>
              Notwithstanding the above, we may share information that does not
              identify you (including information that has been aggregated or
              de-identified) except as prohibited by applicable law. For
              information on your rights and choices regarding how we share
              information about you, please see the “Your Rights and Choices”
              section below.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[3]}>
          <Typography className={styles.sectionTitle} variant="subtitle">
            Social Media and Technology Integrations
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              We offer parts of our Service through websites, platforms, and
              services operated or controlled by separate entities. In addition,
              we integrate technologies operated or controlled by separate
              entities into parts of our Service. Some examples include:
            </Typography>
            <ul className={styles.sectionUnorderedList}>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Links. Our Service includes links that hyperlink to websites,
                platforms, and other services not operated or controlled by us.
                We may get a commission if you purchase a product after clicking
                on a link to an affiliate’s website.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Liking, Sharing, and Logging-In. We may embed a pixel or SDK on
                our Service that allows you to “like” or “share” content on, or
                log-in to your account through social media. If you choose to
                engage with such integration, we may receive information from
                the social network that you have authorized to share with us.
                Please note that the social network may independently collect
                information about you through the integration.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Brand Pages and Chatbots. We may offer our content through
                social media. Any information you provide to us when you engage
                with our content (such as through our brand page or via our
                chatbot on Facebook Messenger) is treated in accordance with
                this Privacy Policy. Also, if you publicly reference our Service
                on social media (e.g., by using a hashtag associated with
                Befront in a tweet or post), we may use your reference on or in
                connection with our Service.
              </Typography>
            </ul>
            <Typography variant="body1" className={styles.sectionText}>
              Please note that when you interact with other entities, including
              when you leave our Service, those entities may independently
              collect information about you and solicit information from you.
              The information collected and stored by those entities remains
              subject to their own policies and practices, including what
              information they share with us, your rights and choices on their
              services and devices, and whether they store information in the
              U.S. or elsewhere. We encourage you to familiarize yourself with
              and consult their privacy policies and terms of use.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[4]}>
          <Typography className={styles.sectionTitle} variant="subtitle">
            Analytics and Advertising
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              We use analytics services, such as Google Analytics, to help us
              understand how users access and use the Service. In addition, we
              work with agencies, advertisers, ad networks, and other technology
              services to place ads about our products and services on other
              websites and services. For example, we place ads through Google
              and Facebook that you may view on their platforms as well as on
              other websites and services.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              As part of this process, we may incorporate tracking technologies
              into our own Service (including our website and emails) as well as
              into our ads displayed on other websites and services. Some of
              these tracking technologies may track your activities across time
              and services for purposes of associating the different devices you
              use, and delivering relevant ads and/or other content to you
              (“Interest-based Advertising”).
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              We also use audience matching services to reach people (or people
              similar to people) who have visited our Service or are identified
              in one or more of our databases (“Matched Ads”). This is done by
              us uploading a customer list to a technology service or
              incorporating a pixel from a technology service into our own
              Service, and the technology service matching common factors
              between our data and their data. For instance, we incorporate the
              Facebook pixel on our Service and may share your email address
              with Facebook as part of our use of Facebook Custom Audiences.
              Some technology services may provide us with their own data, which
              is then uploaded into another technology service for matching
              common factors between those datasets.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              As indicated above, vendors may act as our service providers, or
              in certain contexts, independently decide how to process your
              information.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              For further information on the types of tracking technologies we
              use on the Service and your rights and choices regarding
              analytics, Interest-based Advertising, and Matched Ads, please see
              the “Information Collected Automatically” and “Your Rights and
              Choices” sections.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[5]}>
          <Typography className={styles.sectionTitle} variant="subtitle">
            Your Rights and Choices
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              You have rights and choices with respect to information that
              relates to you, however such rights will vary depending on the
              category of information, and the subsequent use of such
              information. This section further describes your rights.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            1. Account Information.
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              You may access, update, or remove certain information that you
              have provided to us through your account by visiting your account
              settings. Please note that we will retain and use your information
              as necessary to comply with our legal obligations, resolve
              disputes, and enforce our agreements. European data subjects and
              California residents have additional rights as set forth in the
              sections entitled “Additional Disclosures for Data Subjects in
              Europe” and “Additional Disclosures for California Residents”
              below.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography className={styles.sectionSubTitle} variant="subtitle">
            2. Tracking Technology Choices.
          </Typography>
          <div className={styles.sectionInner}>
            <ul className={styles.sectionUnorderedList}>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Cookies and Pixels. Most browsers accept cookies by default. You
                can instruct your browser by changing its settings, to decline
                or delete cookies. If you use multiple browsers on your device,
                you will need to instruct each browser separately. Your ability
                to limit cookies is subject to your browser settings and
                limitations.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Do Not Track. Your browser settings may allow you to
                automatically transmit a “Do Not Track” signal to online
                services you visit. Note, however, there is no industry
                consensus as to what site and app operators should do with
                regard to these signals. Accordingly, unless and until the law
                is interpreted to require us to do so, we do not monitor or take
                action with respect to “Do Not Track” signals. For more
                information on “Do Not Track,” visit http://www.allaboutdnt.com.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                App and Location Technologies. You can stop all collection of
                information via an app by uninstalling the app. You can also
                reset your device Ad ID at any time through your device
                settings, which is designed to allow you to limit the use of
                information collected about you. You can stop all collection of
                precise location data through an app by uninstalling the app or
                withdrawing your consent through your device settings.
              </Typography>
            </ul>
            <Typography variant="body1" className={styles.sectionText}>
              Please be aware that if you disable or remove tracking
              technologies some parts of the Service may not function correctly.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography className={styles.sectionSubTitle} variant="subtitle">
            3. Analytics and Interest-Based Advertising.
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              Google provides tools to allow you to opt out of the use of
              certain information collected by Google Analytics at
              https://tools.google.com/dlpage/gaoptout and by Google Analytics
              for Display Advertising or the Google Display Network at
              https://www.google.com/settings/ads/onweb/.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              Opting out only means that the selected participants should no
              longer deliver certain targeted ads to you, but does not mean you
              will no longer receive any targeted content and/or ads (e.g., in
              connection with the participants’ other customers or from other
              technology services).
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              To opt out of us using your data for Matched Ads, please contact
              us as set forth in the “Contact Us” section below and specify that
              you wish to opt out of matched ads. We will request that the
              applicable technology service not serve you matched ads based on
              information we provide to it. Alternatively, you may directly
              contact the applicable technology service to opt out.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              Please note that if you opt out using any of these methods, the
              opt out will only apply to the specific browser or device from
              which you opt out. We are not responsible for the effectiveness
              of, or compliance with, any opt out options or programs, or the
              accuracy of any other entities’ statements regarding their opt out
              options or programs.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography className={styles.sectionSubTitle} variant="subtitle">
            4. Communications.
          </Typography>
          <div className={styles.sectionInner}>
            <ul className={styles.sectionUnorderedList}>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                E-mails. You can opt-out of receiving promotional emails from us
                at any time by following the instructions as provided in emails
                to click on the unsubscribe link, or by contacting us as set out
                in the “Contact Us” section below. Please note that you cannot
                opt-out of non-promotional emails, such as those about your
                account, transactions, servicing, or our ongoing business
                relations.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Push Notifications. If you have opted-in to receive push
                notifications on your device, you can opt-out at any time by
                adjusting the permissions in your device or uninstalling our
                app.
              </Typography>
            </ul>
            <Typography variant="body1" className={styles.sectionText}>
              Please note that your opt out is limited to the email address and
              device used and will not affect subsequent subscriptions.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[6]}>
          <Typography className={styles.sectionTitle} variant="subtitle">
            Children’s Privacy
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              Our Service is generally not directed at children under the age of
              16 and we do not knowingly collect or solicit personal information
              (as defined by the U.S. Children’s Privacy Protection Act, or
              “COPPA”) from anyone under the age of 13 in a manner not permitted
              by COPPA. If you are a parent or guardian and you believe we have
              collected information from your child in a manner not permitted by
              law, please contact us through our email info@befront.io. We will
              remove the data to the extent required by applicable law.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              We do not “sell,” as that term is defined under the California
              Consumer Privacy Act, the personal information of minors under the
              age of 16 who are California residents. If you are a California
              resident under 18 years old and registered to use the Service, you
              can ask us to remove any content or information you have posted on
              the Service. To make a request, email us at the email address set
              out in “Contact Us” section with “California Under 18 Content
              Removal Request” in the subject line, and tell us what you want
              removed. We will make reasonable good faith efforts to remove the
              post from prospective public view, although we cannot ensure the
              complete or comprehensive removal of the content and may retain
              the content as necessary to comply with our legal obligations,
              resolve disputes, and enforce our agreements.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[7]}>
          <Typography className={styles.sectionTitle} variant="subtitle">
            Data Security
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              Befront uses commercially reasonable administrative, technical,
              and physical security safeguards designed to preserve the
              integrity and security of all information collected through the
              Service. However, no security system is impenetrable and we cannot
              guarantee the security of our systems 100%. In the event that any
              information under our control is compromised as a result of a
              breach of security, we will take reasonable and appropriate steps
              to investigate the situation, notify those individuals whose
              information may have been compromised in accordance with any
              applicable laws and regulations, and take other steps as
              appropriate. Your privacy settings may also be affected by changes
              to the functionality of third party sites and services that you
              add to the Befront Service, such as social networks. Befront is
              not responsible for the functionality or security measures of any
              third party.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[8]}>
          <Typography className={styles.sectionTitle} variant="subtitle">
            International Transfer
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              We are based in Delaware, USA and the information we collect is
              governed by Delaware law. If you are accessing the Service from
              outside of Delaware, USA, please be aware that information
              collected through the Service may be transferred to, processed,
              stored, and used in Delaware, USA and other jurisdictions. Data
              protection laws in Delaware, USA and other jurisdictions may be
              different from those of your country of residence. Your use of the
              Service or provision of any information therefore constitutes your
              consent to the transfer to and from, processing, usage, sharing,
              and storage of information about you in the USA and other
              jurisdictions as set out in this Privacy Policy.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[9]}>
          <Typography className={styles.sectionTitle} variant="subtitle">
            Changes to this Privacy Policy
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              Befront reserves the right to modify or update this Privacy Policy
              from time to time to reflect the changes in our business and
              practices, and so you should review this page periodically. When
              we change the policy we will reflect the ‘last modified’ date at
              the top of this Privacy Policy.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[10]}>
          <Typography
            className={styles.sectionTitle}
            variant="body1"
            weight="semiBold"
          >
            Links to Other Websites and Services
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              The Services may, from time to time, contain links to and from
              websites, platforms, and other services not operated or controlled
              by us. If you follow a link to any of these locations, please note
              that these locations have their own privacy policies and that we
              do not accept any responsibility or liability for their policies.
              Please check the individual policies before you submit any
              information to those locations.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[11]}>
          <Typography
            className={styles.sectionTitle}
            variant="body1"
            weight="semiBold"
          >
            Additional Disclosures for California Residents
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              These additional disclosures for California residents apply only
              to individuals who reside in California. The California Consumer
              Privacy Act of 2018 (“CCPA”) provides additional rights to know,
              delete and opt out, and requires businesses collecting or
              disclosing personal information to provide notices and means to
              exercise rights.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            1. Notice of Collection.
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              We collect the following categories of personal information
              enumerated in the CCPA:
            </Typography>
            <ul className={styles.sectionUnorderedList}>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Identifiers, including name, email address, phone number account
                name, IP address, and an ID or number assigned to your account.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Customer records, billing and shipping address, and credit or
                debit card information.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Demographics, such as your age or gender. This category includes
                data that may qualify as protected classifications under other
                California or federal laws.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Commercial information, including purchases, you subscription
                and engagement with the Services.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Internet activity, including your interactions with our Service,
                your academic content, and performance data.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Audio or visual data, including pictures or vides you post on
                our Service.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Geolocation data , including location enabled services such as
                WiFi and GPS.
              </Typography>
            </ul>
            <Typography variant="body1" className={styles.sectionText}>
              For more details on information we collect, including the sources
              we receive information from, review the Information Collection
              section. We collect and use these categories of personal
              information for the business purposes described in the Use of
              Information section, including to provide and manage our Services.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              Befront does not generally sell information as the term “sell” is
              traditionally understood. However, to the extent “sale” under the
              CCPA is interpreted to include advertising technology activities
              such as those disclosed in the Analytics and Advertising section
              as a “sale,” we will comply with applicable law as to such
              activity. We discloses the following categories of personal
              information for commercial purposes: identifiers, demographic
              information, commercial information, internet activity,
              geolocation data and inferences. We use and partner with different
              types of entities to assist with our daily operations and manage
              our Service. Please review the Sharing of Information section for
              more detail about the parties we have shared information with.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            2. Right to Know and Delete
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              If you are a California resident, you have the right to delete the
              personal information we have collected from you and the right to
              know certain information about our data practices in the preceding
              12 months. In particular, you have the right to request the
              following from us:
            </Typography>
            <ul className={styles.sectionUnorderedList}>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                The categories of personal information we have collected about
                you;
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                The categories of sources from which the personal information
                was collected;
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                The categories of personal information about you we disclosed
                for a business purpose or sold;
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                The categories of third parties to whom the personal information
                was disclosed for a business purpose or sold;
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                The business or commercial purpose for collecting or selling the
                personal information; and
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                The specific pieces of personal information we have collected
                about you.
              </Typography>
            </ul>
            <Typography variant="body1" className={styles.sectionText}>
              To exercise any of these rights, please contact us through our
              email info@befront.io. In the request, please specify which right
              you are seeking to exercise and the scope of the request. We will
              confirm receipt of your request within 10 days. We may require
              specific information from you to help us verify your identity and
              process your request. If we are unable to verify your identity, we
              may deny your request to know or delete.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              If personal information about you has been processed by us as a
              service provider on behalf of a corporate customer or school
              customer and you wish to exercise any rights you have with such
              personal information, please inquire with our corporate or school
              customer directly. If you wish to make your request directly to
              us, please provide the name of the corporate customer or school
              customer on whose behalf we processed your personal information.
              We will refer your request to that corporate customer or school
              customer, and will support them to the extent required by
              applicable law in responding to your request.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            3. Right to Opt-Out
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              To the extent Befront sells your personal information as the term
              “sell” is defined under the California Consumer Privacy Act, you
              have the right to opt-out of the sale of your personal information
              by us to third parties at any time. You may also submit a request
              to opt-out by contacting us through our email info@befront.io.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            4. Authorized Agent
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              You can designate an authorized agent to submit requests on your
              behalf. However, we will require written proof of the agent’s
              permission to do so and verify your identity directly.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            5. Right to Non-Discrimination
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              You have the right not to receive discriminatory treatment by us
              for the exercise of any of your rights.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            6. Shine the Light.
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              Customers who are residents of California may request (i) a list
              of the categories of personal information disclosed by us to third
              parties during the immediately preceding calendar year for those
              third parties’ own direct marketing purposes; and (ii) a list of
              the categories of third parties to whom we disclosed such
              information. To exercise a request, please write us at the email
              or postal address set out in “Contact Us” above and specify that
              you are making a “California Shine the Light Request.” We may
              require additional information from you to allow us to verify your
              identity and are only required to respond to requests once during
              any calendar year.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[12]}>
          <Typography
            className={styles.sectionTitle}
            variant="body1"
            weight="semiBold"
          >
            Additional Disclosures for Nevada Residents
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              Nevada consumers may submit requests directing businesses not to
              sell certain kinds of personal information that the business has
              collected or will collect about the consumer. A sale under Nevada
              law is the exchange of personal information for monetary
              consideration by the business to a third party for the third party
              to license or sell the personal information to other third
              parties. If you are a Nevada consumer and wish to obtain
              information about our compliance with Nevada law, please contact
              us through our email info@befront.io.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[13]}>
          <Typography
            className={styles.sectionTitle}
            variant="body1"
            weight="semiBold"
          >
            Additional Disclosures for Data Subjects in Europe
          </Typography>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            1. Role
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              Data protection laws in Europe distinguish between organizations
              that process personal data for their own purposes (known as
              “controllers”) and organizations that process personal data on
              behalf of other organizations (known as “processors”). Befront
              acts as a controller with respect to personal data collected as
              you interact with our websites, emails, and advertisements.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            2. Legal Basis for Processing
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              In the European Union (“EU”), the purposes for which we process
              your personal data are:
            </Typography>
            <ol className={styles.sectionOrderedList}>
              <Typography
                component="li"
                variant="body1"
                className={styles.sectionOrderedListItem}
              >
                Where you have given consent to the processing for one or more
                specific purposes, either to us or to our service providers or
                partners;
              </Typography>
              <Typography
                component="li"
                variant="body1"
                className={styles.sectionOrderedListItem}
              >
                Where we need to perform the contract we are about to enter into
                or have entered into with you for the Service;
              </Typography>
              <Typography
                component="li"
                variant="body1"
                className={styles.sectionOrderedListItem}
              >
                Where it is necessary for our legitimate interests (or those of
                a third party) and your interests and fundamental rights do not
                override those interests; and
              </Typography>
              <Typography
                component="li"
                variant="body1"
                className={styles.sectionOrderedListItem}
              >
                Where we need to comply with a legal or regulatory obligation in
                the EU.
              </Typography>
            </ol>
            <Typography variant="body1" className={styles.sectionText}>
              We have set out in Annex 1 below, found here, a description of all
              the ways we plan to use your personal data, and which of the legal
              bases we rely on to do so.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            3. Data Transfer
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              If your data is collected in Europe, we will transfer your
              personal data subject to appropriate safeguards, such as Standard
              Contractual Clauses.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            4. Your Data Subject Rights.
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              If you are located in the EU or Switzerland, you have the
              following rights in respect of your personal data that we hold:
            </Typography>
            <ul className={styles.sectionUnorderedList}>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Right of access. The right to obtain access to your personal
                data.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Right to rectification. The right to obtain rectification of
                your personal data without undue delay where that personal data
                is inaccurate or incomplete.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Right to erasure. The right to obtain the erasure of your
                personal data without undue delay in certain circumstances, such
                as where the personal data is no longer necessary in relation to
                the purposes for which it was collected or processed.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Right to restriction. The right to obtain the restriction of the
                processing undertaken by us on your personal data in certain
                circumstances, such as where the accuracy of the personal data
                is contested by you, for a period enabling us to verify the
                accuracy of that personal data.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Right to portability. The right to portability allows you to
                move, copy or transfer personal data easily from one
                organization to another.
              </Typography>
              <Typography
                variant="body1"
                component="li"
                className={styles.sectionUnorderedListItem}
              >
                Right to object. You have a right to object to processing based
                on legitimate interests and direct marketing.
              </Typography>
            </ul>
            <Typography variant="body1" className={styles.sectionText}>
              If you wish to exercise one of these rights, please contact us
              using the contact details at the end of this Privacy Policy. We
              will respond to your request within 30 days. We may request
              specific information from you to help us confirm your identity and
              process your request. Applicable law may require or permit us to
              decline your request. If we decline your request, we will tell you
              why, subject to legal restrictions. Please note that we retain
              information as necessary to fulfil the purposes for which it was
              collected, and may continue to retain and use information even
              after a data subject request for purposes of our legitimate
              interests, including as necessary to comply with our legal
              obligations, resolve disputes, prevent fraud, and enforce our
              agreements.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              If your personal data has been processed by us on behalf of a
              business customer and you wish to exercise any rights you have
              with such personal data, please inquire with our customer
              directly. If you wish to make your request directly to us, please
              provide the name of our customer on whose behalf we processed your
              personal data. We will refer your request to that customer, and
              will support them to the extent required by applicable law in
              responding to your request.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            5. Complaints.
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              If you have a complaint about our use of your personal data or
              response to your requests regarding your personal data, you may
              submit a complaint to the data protection regulator in your
              jurisdiction. We would, however, appreciate the opportunity to
              address your concerns before you approach a data protection
              regulator, and would welcome you directing an inquiry first to us.
              In addition to the contact information above, please contact our
              email info@befront.io.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[14]}>
          <Typography
            className={styles.sectionTitle}
            variant="body1"
            weight="semiBold"
          >
            Contact Us
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              If you have any questions about this Privacy Policy, our data
              practices, our compliance with applicable law, or the Service,
              please contact us by submitting a ticket through our email
              info@befront.io, or by contacting us at: Befront, Inc., 251 Little
              Falls Drive, Wilmington, New Castle County, Delaware Count, USA,
              19808
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[15]}>
          <Typography
            className={styles.sectionTitle}
            variant="body1"
            weight="semiBold"
          >
            ANNEX 1
          </Typography>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionTitle}
            variant="body1"
            weight="semiBold"
          >
            Legal basis for processing in the EU
          </Typography>
          <div className={styles.sectionInner}>
            <div className={styles.sectionTable}>
              <div className={styles.sectionTableRow}>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1" weight="semiBold">
                    Category of personal information
                  </Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1" weight="semiBold">
                    Purpose/Activity
                  </Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1" weight="semiBold">
                    Lawful basis for processing including basis of legitimate
                    interest
                  </Typography>
                </div>
              </div>
              <div className={styles.sectionTableRow}>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    Contact information and basic personal details such as your
                    first name, last name, e-mail address and date of birth.
                  </Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    We use this information to operate, maintain and provide to
                    you the features of the Service.
                  </Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    The processing is necessary for: the performance of a
                    contract and to take steps prior to entering into a
                    contract; and our legitimate interests, namely administering
                    the Service, for marketing purposes and communicating with
                    users.
                  </Typography>
                </div>
              </div>
              <div className={styles.sectionTableRow}>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">-</Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    We use this information to communicate with you, including
                    sending Service-related communications (such as invoices and
                    information about updates to the Service, and any news,
                    alerts and marketing communications (in line with your
                    settings and options)).
                  </Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    The processing is necessary for: the performance of the
                    contract and to take steps prior to entering into a
                    contract; and our legitimate interests, namely administering
                    the Service, for marketing purposes and communicating with
                    users.
                  </Typography>
                </div>
              </div>
              <div className={styles.sectionTableRow}>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">-</Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    We use this information to deal with enquiries and
                    complaints made by or about you relating to the Service.
                  </Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    The processing is necessary for our legitimate interests,
                    namely administering the Service, for marketing purposes and
                    communicating with users.
                  </Typography>
                </div>
              </div>
              <div className={styles.sectionTableRow}>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    Information about how you access and use the Service
                    including, for example, how frequently you access the
                    Service, the time you access the Service and how long you
                    use the Service for, whether you access the Service from
                    multiple devices, the website from which you came and the
                    website to which you go when you leave the Service, and
                    other actions you take on the Service.
                  </Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    We use this information to present the Service to you on
                    your device.
                  </Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    The processing is necessary for our legitimate interests,
                    namely to tailor the Service to the user and improve the
                    Service generally.
                  </Typography>
                </div>
              </div>
              <div className={styles.sectionTableRow}>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">-</Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    We use this information to administer the Service for
                    internal operations, including troubleshooting, data
                    analysis, testing, research, statistical and survey
                    purposes, and to help us develop new products and services.
                  </Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    The processing is necessary for our legitimate interests,
                    namely communicating with users and responding to queries,
                    complaints, and concerns, and for developing and improving
                    the Service.
                  </Typography>
                </div>
              </div>
              <div className={styles.sectionTableRow}>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">-</Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    We use this information to detect and prevent fraud.
                  </Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    The processing is necessary for our legitimate interests,
                    namely the detection and prevention of fraud.
                  </Typography>
                </div>
              </div>
              <div className={styles.sectionTableRow}>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    Content you post to the Service including any content you
                    provide through messages, chat, or other functionality.
                  </Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    We use this information to operate, maintain and provide to
                    you the features of the Service.
                  </Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    The processing is necessary for our legitimate interests,
                    namely administering the Service.
                  </Typography>
                </div>
              </div>
              <div className={styles.sectionTableRow}>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">-</Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    We use this information to improve the Service.
                  </Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    The processing is necessary for our legitimate interests,
                    namely for developing and improving the Service.
                  </Typography>
                </div>
              </div>
              <div className={styles.sectionTableRow}>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    Location: When you use the Service, we will collect and
                    process location data. We use various technologies to
                    determine location, including IP address, GPS and other
                    sensors that may, for example, provide Befront with
                    information on nearby devices, Wi-Fi access points and
                    mobile towers.
                  </Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    We use this information to operate, maintain and provide to
                    you the features of the Service.
                  </Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    The processing is necessary for our legitimate interests,
                    namely administering the Service.
                  </Typography>
                </div>
              </div>
              <div className={styles.sectionTableRow}>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    Information provided by social networks, including LinkedIn,
                    when you connect to the Service. The features and
                    functionality of the Service connects social networks,
                    predominantly LinkedIn, to our Service. In this case, we
                    collect personal information from the social network in
                    accordance with your privacy settings on that social
                    network. The connected social network may provide us with
                    information, such as your name, LinkedIn ID, profile
                    picture, network, gender, username, userID, age or age
                    range, language, country, connections and any other
                    information you have agreed it can share or that the social
                    network provides to us.
                  </Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    We will use the personal information provided to allow you
                    to log in to the Service, to help create a public profile
                    for you, and where we provide the functionality, we will
                    also use the information provided to assist you in sharing
                    the content you create, or your experiences on the Service
                    with your contacts on the connected social network.
                  </Typography>
                </div>
                <div className={styles.sectionTableCell}>
                  <Typography variant="body1">
                    The processing is necessary for: the performance of the
                    contract and to take steps prior to entering into a
                    contract; and our legitimate interests, namely administering
                    the Service, for marketing purposes and communicating with
                    users.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </HomeLayout>
);
export default React.memo(PrivacyPolicyPage);
