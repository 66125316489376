import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';
import { Collapsible } from 'components/shared/Collapsible';

import { CoursePreview } from 'components/CoursePreview';

import { LessonItem } from '../LessonItem';

import { LessonsCount } from './components/LessonsCount';
import { Progress } from './components/Progress';

import styles from './Module.styles.scss';

const Module = ({
  available,
  completed,
  lessons,
  startLoadingId,
  preview,
  title,
  progress,
  onLessonClick,
}) => (
  <Collapsible
    className={cn(styles.module, { [styles.moduleDisabled]: !available })}
    openedClassName={cn(
      styles.module,
      { [styles.moduleDisabled]: !available },
      styles.moduleOpened,
    )}
    transitionTime={150}
    triggerTagName="div"
    trigger={
      <>
        <div className={styles.header}>
          <CoursePreview preview={preview} className={styles.preview} />
          <Typography
            className={styles.title}
            variant="body1"
            weight="medium"
            mode="compact"
          >
            {title}
          </Typography>
        </div>
        <div className={styles.inner}>
          <LessonsCount
            className={styles.count}
            iconClassName={styles.countIcon}
            count={lessons.length}
          />
          {progress > 0 && progress < 100 && (
            <Progress className={styles.progress} progress={progress} />
          )}
          {completed && (
            <Icon className={styles.completedIcon} name="completed" />
          )}
          {!available && <Icon className={styles.lockIcon} name="lock" />}
        </div>
      </>
    }
  >
    {lessons.map((lesson) => (
      <LessonItem
        className={styles.lesson}
        key={lesson.id}
        startLoading={startLoadingId === lesson.id}
        {...lesson}
        onClick={onLessonClick}
      />
    ))}
  </Collapsible>
);

export default React.memo(Module);
