import React from 'react';
import cn from 'classnames';
import _upperFirst from 'lodash/upperFirst';
import _camelCase from 'lodash/camelCase';

import { COURSE_TYPES } from 'constants/course';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';
import { Difficulty } from 'components/shared/Difficulty';

import styles from './CardInfo.styles.scss';

const CardInfo = ({ className, type, studentsCount, difficulty }) => (
  <div className={cn(styles.info, className)}>
    {!!studentsCount && (
      <div className={styles.item}>
        <Icon className={styles.itemIcon} name="users" />
        <Typography className={styles.itemText} variant="body3" weight="medium">
          {studentsCount}
        </Typography>
      </div>
    )}
    {difficulty && (
      <div className={styles.item}>
        <div className={styles.difficultyWrapper}>
          <Difficulty className={styles.itemIcon} value={difficulty} />
        </div>
        <Typography className={styles.itemText} variant="body3" weight="medium">
          {_upperFirst(_camelCase(difficulty))}
        </Typography>
      </div>
    )}
    <div className={styles.item}>
      <Icon
        className={styles.itemIcon}
        name={type === COURSE_TYPES.WEB ? 'web' : 'figma'}
      />
      <Typography className={styles.itemText} variant="body3" weight="medium">
        {type === COURSE_TYPES.WEB ? 'Web' : 'Plugin'}
      </Typography>
    </div>
  </div>
);

export default React.memo(CardInfo);
