import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import * as api from 'api/methods';

import { userSelector } from 'store/auth/selectors';

import { showToast } from 'helpers/showToast';

import SubmitIdea from './SubmitIdea';

const SubmitIdeaContainer = (props) => {
  const user = useSelector(userSelector);

  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const handleSendClick = async (values) => {
    setLoading(true);

    try {
      await api.createStudentReview({ ...values, studentId: user.id });

      setCurrentStep(2);
    } catch (error) {
      console.warn(error);

      showToast({
        data: {
          variant: 'error',
          title: 'Error!',
          text: error.message || 'Server error',
        },
      });
    }

    setLoading(false);
  };

  return (
    <SubmitIdea
      {...props}
      loading={loading}
      currentStep={currentStep}
      onSendClick={handleSendClick}
    />
  );
};

export default React.memo(SubmitIdeaContainer);
