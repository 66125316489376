import React from 'react';

import StartFreeIcon from 'assets/img/landing/start-free.svg';

import { Typography } from 'components/shared/Typography';

import styles from './CatchySection.styles.scss';

const CatchySection = ({ onStartClick }) => (
  <div className={styles.section}>
    <div className={styles.inner}>
      <Typography className={styles.text} family="prompt">
        Gain confidence in your UX/UI design skills
      </Typography>
      <button className={styles.button} onClick={onStartClick}>
        Sign up
      </button>
    </div>
    <StartFreeIcon className={styles.icon} />
  </div>
);

export default React.memo(CatchySection);
