import React from 'react';
import { Formik, Form, ErrorMessage } from 'formik';

import Logo from 'assets/img/logo.svg';

import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import { SelectTagField } from 'components/FormikFields';

import {
  UNSUBSCRIBE_PAGE_TAGS,
  UNSUBSCRIBE_STEPS,
} from './UnsubscribePage.constants';

import { initialValues, validationSchema } from './UnsubscribePage.formConfig';

import styles from './UnsubscribePage.styles.scss';

const UnsubscribePage = ({
  submitLoading,
  resubscribeLoading,
  currentStep,
  onSubmitClick,
  onResubscribeClick,
}) => (
  <div className={styles.page}>
    <div className={styles.container}>
      <Logo className={styles.logo} />
      <div className={styles.inner}>
        {currentStep === UNSUBSCRIBE_STEPS.CHOOSE_REASON && (
          <>
            <Typography
              variant="heading2"
              weight="semiBold"
              className={styles.title}
            >
              Unsubscribed
            </Typography>
            <Typography variant="body1" className={styles.text}>
              You will not receive any more emails from{' '}
              <b className={styles.textBold}>Befront</b>.
            </Typography>
            <Typography variant="body1" className={styles.text}>
              It will be very helpful for us if you choose the reason why you
              decided to unsubscribe:
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmitClick}
            >
              {({ errors }) => (
                <Form className={styles.form}>
                  <div className={styles.tags}>
                    {UNSUBSCRIBE_PAGE_TAGS.map((tag) => (
                      <FormikField
                        key={tag}
                        name="tags"
                        withControl={false}
                        component={SelectTagField}
                        componentProps={{
                          className: styles.tag,
                          type: 'checkbox',
                          text: tag,
                          value: tag,
                        }}
                      />
                    ))}
                  </div>
                  {errors.tags && (
                    <ErrorMessage name="tags">
                      {(message) => (
                        <Typography
                          variant="body3"
                          mode="compact"
                          className={styles.error}
                        >
                          {message}
                        </Typography>
                      )}
                    </ErrorMessage>
                  )}
                  <div className={styles.actions}>
                    <Button
                      className={styles.action}
                      size="lg"
                      type="submit"
                      startIcon={
                        submitLoading && (
                          <Loading color="var(--color-white-primary)" />
                        )
                      }
                    >
                      Submit
                    </Button>
                    <Button
                      className={styles.action}
                      size="lg"
                      variant="outlined"
                      type="submit"
                      startIcon={resubscribeLoading && <Loading />}
                      onClick={onResubscribeClick}
                    >
                      Resubscribe
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
        {currentStep === UNSUBSCRIBE_STEPS.REASON_SUBMITTED && (
          <>
            <Typography
              variant="heading2"
              weight="semiBold"
              className={styles.title}
            >
              Thanks!
            </Typography>
            <Typography variant="body1" className={styles.text}>
              Your feedback is appreciated
            </Typography>
          </>
        )}
        {currentStep === UNSUBSCRIBE_STEPS.RESUBSCRIBED && (
          <>
            <Typography
              variant="heading2"
              weight="semiBold"
              className={styles.title}
            >
              Subscribed again
            </Typography>
            <Typography variant="body1" className={styles.text}>
              You’ve successfully been resubscribed to Befront.
            </Typography>
          </>
        )}
      </div>
    </div>
  </div>
);

export default React.memo(UnsubscribePage);
