import React from 'react';

import { Modal } from 'components/shared/Modal';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import BackgroundIcon from './icons/payment.svg';

import styles from './SuccessfulPaymentModal.styles.scss';

const SuccessfulPaymentModal = ({ open, onClose }) => (
  <Modal className={styles.modal} open={open} onClose={onClose} centered>
    <BackgroundIcon />
    <Typography className={styles.title} variant="heading2" weight="bold">
      Welcome to Pro!
    </Typography>
    <Typography className={styles.text} variant="body1">
      Congrats, you’ve taken a giant leap forward in your commitment to
      improving your design skills.
    </Typography>
    <Button className={styles.button} onClick={onClose}>
      Great!
    </Button>
  </Modal>
);

export default React.memo(SuccessfulPaymentModal);
