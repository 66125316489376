import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logEvent } from 'helpers/logEvent';

import { ROUTES } from 'constants/routes';

import { isAuthenticatedSelector } from 'store/auth/selectors';

import { useAuthModal } from 'context/authModals';

import Header from './Header';

const HeaderContainer = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const { dispatch } = useAuthModal();

  const handleInstallFigmaPluginClick = () => {
    window.open(
      'https://www.figma.com/community/plugin/946462257210461125/Befront',
      '_blank',
    );
  };

  const handleSignInClick = () => {
    logEvent({ eventType: 'Login_Clicked' });

    dispatch({
      type: 'OPEN_MODAL',
      payload: { currentModal: 'SIGNIN_MODAL', currentModalData: null },
    });
  };

  const handleSignUpClick = () => {
    logEvent({ eventType: 'SignUp_Clicked' });

    dispatch({
      type: 'OPEN_MODAL',
      payload: { currentModal: 'SIGNUP_MODAL', currentModalData: null },
    });
  };

  const handleDashboardClick = () => {
    navigate(ROUTES.DASHBOARD_PAGE);
  };

  return (
    <Header
      {...props}
      isAuthenticated={isAuthenticated}
      onInstallFigmaPluginClick={handleInstallFigmaPluginClick}
      onDashboardClick={handleDashboardClick}
      onSignInClick={handleSignInClick}
      onSignUpClick={handleSignUpClick}
    />
  );
};

export default React.memo(HeaderContainer);
