export const categories = [
  {
    key: 'what-is-befront',
    title: 'What is Befront?',
    items: [
      {
        key: 'courses-practice',
        title: 'Courses & Practice',
        description:
          'You’ll find course and practice information when clicking on them. Some courses and practices have free lessons. You can try them out before subscribing for your convenience.',
        imageUrl: '/s3/faq/faq-1.png',
      },
      {
        key: 'lessons',
        title: 'Lessons',
        description:
          'There are 6 types of lessons you’ll find on our platform.',
        imageUrl: '/s3/faq/faq-2.png',
      },
      {
        key: 'practice',
        title: 'Practice Figma Lesson',
        description:
          'Befront plugin for Figma help you advance your practical design skill interactively with bite-sized tasks right in Figma. Everything is easy - install the plugin, open it and start practicing! No more annoying pausing the video course and switching between browser and Figma for doing tasks.',
        videoUrl: '/s3/faq/practice-lesson.mp4',
      },
      {
        key: 'quiz',
        title: 'Quiz',
        description:
          'In these interactive lesson you’ll train your knowledge. You need to choose one or several answers to questions you have on the screen.',
        videoUrl: '/s3/faq/quiz.mp4',
      },
      {
        key: 'connection-quiz',
        title: 'Connection Quiz',
        description:
          'You’ll see two columns and the task is to connect item from the left column with the item from the right one.',
        videoUrl: '/s3/faq/connection-quiz.mp4',
      },
      {
        key: 'difference',
        title: 'Differences Lesson',
        description:
          'Do you want to train your eye for design? We think every designer want it. Go ahead and develop your design eye with our special lesson type called “Differences“. The task is simple - choose between two options which you think is better.',
        videoUrl: '/s3/faq/differences.mp4',
      },
      {
        key: 'two-columns',
        title: 'Two Columns Lesson',
        description:
          'There are a list of words and two columns on the screen. Your task here will be to sort out all words into left or right column.',
        videoUrl: '/s3/faq/two-columns.mp4',
      },
      // {
      //   key: 'chat',
      //   title: 'Chat Lesson',
      //   description:
      //     'This lesson type is a communication simulator which helps you boost communication and soft skills. Read the task and bot’s message, choose the best reply on your opinion and get feedback from the system. Check if you were right and learn how to behave and communicate in different situations in your professional life.',
      //   videoUrl: '/s3/faq/chat.mp4',
      // },
      {
        key: 'theory',
        title: 'Theory Lesson',
        description:
          'In this lesson type you’ll find some theory parts on the topic. As the learning on your platform is practice-oriented, you’ll find a minimum of boring and long theoretical lessons. Only small and useful information we include in these types of lessons.',
        videoUrl: '/s3/faq/theory.mp4',
      },
    ],
  },
  {
    key: 'how-to-install-plugin',
    title: 'How to install plugin?',
    items: [
      {
        key: 'how-to-install',
        list: [
          'Browse the Community in your browser, Figma desktop app, or mobile web.',
          'Select Community in the left sidebar, or visit https://figma.com/community/plugins',
          'Select the Plugin pill at the top.',
          'Type "Befront" in the search bar.',
          'Click "Install".',
        ],
        videoUrl: '/s3/faq/how-to-install-plugin.mp4',
      },
    ],
  },
  {
    key: 'how-to-run-plugin',
    title: 'How to run plugin?',
    items: [
      {
        key: 'how-to-run',
        list: [
          'Click on the Main menu (Figma icon) in the top-left corner.',
          'Hover your mouse over “plugins.” This should open a list of all the plugins you’ve installed on Figma.',
          'Click on Befront to run it.',
        ],
        videoUrl: '/s3/faq/how-to-run-plugin.mp4',
      },
    ],
  },
];
