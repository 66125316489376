import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import { AUTH_CODE_LENGTH } from 'constants/common';

import styles from './CodeInput.styles.scss';

const CodeInput = ({ numbersRef, value, error, onChange, onKeyDown }) => (
  <div className={styles.main}>
    <div className={styles.input}>
      {Array.from(Array(AUTH_CODE_LENGTH)).map((_, index) => (
        <input
          key={index}
          ref={numbersRef.current[index]}
          className={cn(styles.field, { [styles.error]: error })}
          type="number"
          value={value[index]}
          onChange={(event) => onChange(event, index)}
          onKeyDown={(event) => {
            onKeyDown(event, index);
          }}
        />
      ))}
    </div>
    {error && (
      <div className={styles.notification}>
        <Typography className={styles.errorText} variant="body3">
          {error}
        </Typography>
      </div>
    )}
  </div>
);

export default React.memo(CodeInput);
