import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { HIDE_EXPIRATION_DATE } from 'constants/common';

import { userSelector } from 'store/auth/selectors';

import {
  getNextDay,
  isDateAfterNow,
  getExpirationText,
  checkDaysDifference,
  getEarliestExpirationDate,
} from 'helpers/dateHelpers';

export const usePromocodeExipation = () => {
  const [showCard, setShowCard] = useState(false);
  const [expirationText, setExpirationText] = useState(null);

  const user = useSelector(userSelector);

  useEffect(() => {
    const expirationDate = getEarliestExpirationDate(
      user.status.filter(
        (statusItem) => statusItem.activationType === 'PROMOCODE',
      ),
    );

    const hideExpirationDate = localStorage.getItem(HIDE_EXPIRATION_DATE);

    if (
      !!expirationDate &&
      (!hideExpirationDate || !isDateAfterNow(hideExpirationDate)) &&
      checkDaysDifference({ date: expirationDate, days: 7 })
    ) {
      setExpirationText(getExpirationText({ date: expirationDate }));
      setShowCard(true);
    }
  }, []);

  const onCloseClick = () => {
    const nextDay = getNextDay();
    localStorage.setItem(HIDE_EXPIRATION_DATE, nextDay);

    setShowCard(false);
  };

  return { showCard, expirationText, onCloseClick };
};
