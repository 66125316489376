import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './Tag.styles.scss';

const SIZES = {
  md: styles.md,
  sm: styles.sm,
};

const VARIANTS = {
  new: styles.new,
  pro: styles.pro,
  free: styles.free,
  soon: styles.soon,
  lessons: styles.lessons,
};

const Tag = ({ className, textClassName, children, variant, size }) => (
  <div className={cn(styles.tag, VARIANTS[variant], SIZES[size], className)}>
    <Typography className={cn(styles.text, textClassName)} weight="semiBold">
      {children}
    </Typography>
  </div>
);

Tag.defaultProps = {
  variant: 'new',
  size: 'md',
};

export default React.memo(Tag);
