import React from 'react';
import cn from 'classnames';
import Scrollbar from 'react-scrollbars-custom';

import styles from './ScrollWrapper.styles.scss';

const ScrollWrapper = ({
  className,
  contentClassName,
  thumbYClassName,
  trackYClassName,
  thumbXClassName,
  trackXClassName,
  scrollbarRef,
  children,
  scrolling,
  onScrollStart,
  onScrollStop,
  ...restProps
}) => (
  <Scrollbar
    ref={scrollbarRef}
    noDefaultStyles
    removeTrackXWhenNotUsed
    removeTrackYWhenNotUsed
    className={cn(styles.scrollWrapper, className)}
    wrapperProps={{
      renderer: (props) => {
        const { elementRef, ...otherProps } = props;

        return (
          <div {...otherProps} ref={elementRef} className={styles.wrapper} />
        );
      },
    }}
    contentProps={{
      renderer: (props) => {
        const { elementRef, ...otherProps } = props;

        return (
          <div
            {...otherProps}
            ref={elementRef}
            className={cn(styles.content, contentClassName)}
          />
        );
      },
    }}
    trackYProps={{
      renderer: (props) => {
        const { elementRef, ...otherProps } = props;

        return (
          <div
            {...otherProps}
            ref={elementRef}
            className={cn(
              styles.trackY,
              { [styles.trackScrolling]: true },
              trackYClassName,
            )}
          />
        );
      },
    }}
    thumbYProps={{
      renderer: (props) => {
        const { elementRef, ...otherProps } = props;

        return (
          <div
            {...otherProps}
            ref={elementRef}
            className={cn(styles.thumbY, thumbYClassName)}
          />
        );
      },
    }}
    trackXProps={{
      renderer: (props) => {
        const { elementRef, ...otherProps } = props;

        return (
          <div
            {...otherProps}
            ref={elementRef}
            className={cn(
              styles.trackX,
              { [styles.trackScrolling]: true },
              trackXClassName,
            )}
          />
        );
      },
    }}
    thumbXProps={{
      renderer: (props) => {
        const { elementRef, ...otherProps } = props;

        return (
          <div
            {...otherProps}
            ref={elementRef}
            className={cn(styles.thumbX, thumbXClassName)}
          />
        );
      },
    }}
    onScrollStart={onScrollStart}
    onScrollStop={onScrollStop}
    {...restProps}
  >
    {children}
  </Scrollbar>
);

export default React.memo(ScrollWrapper);
