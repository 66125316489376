import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import parse from 'date-fns/parse';
import isAfter from 'date-fns/isAfter';
import differenceInDays from 'date-fns/differenceInDays';
import { enUS } from 'date-fns/locale';

export const isDateAfterNow = (date) => {
  const parsedGivenDate = parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());

  const currentDate = new Date();
  const isGivenDateAfterCurrent = isAfter(parsedGivenDate, currentDate);

  return isGivenDateAfterCurrent;
};

export const getNextDay = () => {
  const currentDate = new Date();
  const nextDay = addDays(currentDate, 1);

  return format(nextDay, 'yyyy-MM-dd HH:mm:ss');
};

export const checkDaysDifference = ({ date, days }) => {
  const currentDate = new Date();

  const daysDifference = differenceInDays(date, currentDate);

  const result = daysDifference < days;

  return result;
};

export const getEarliestExpirationDate = (status) => {
  if (!status[0]?.accessExpireDate) {
    return null;
  }

  let earliestDate = new Date(status[0].accessExpireDate);

  for (const item of status) {
    const currentDate = new Date(item.accessExpireDate);
    if (currentDate < earliestDate) {
      earliestDate = currentDate;
    }
  }

  return earliestDate;
};

export const getExpirationText = ({ lang, date }) => {
  return format(date, 'dd MMM yyyy', {
    locale: enUS,
  });
};
