import React from 'react';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';
import { Collapsible } from 'components/shared/Collapsible';

import styles from './Question.styles.scss';

const Question = ({ title, children }) => (
  <Collapsible
    transitionTime={150}
    triggerTagName="div"
    className={styles.spoiler}
    openedClassName={styles.spoilerOpened}
    open={false}
    trigger={
      <div className={styles.question}>
        <Typography
          variant="body1"
          mode="compact"
          weight="semiBold"
          className={styles.title}
        >
          {title}
        </Typography>

        <Icon name="chevronDown" className={styles.icon} />
      </div>
    }
  >
    <div className={styles.inner}>{children}</div>
  </Collapsible>
);

export default React.memo(Question);
