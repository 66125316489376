import _flatten from 'lodash/flatten';

const _calculateProgress = ({ lessons, completedLessons }) => {
  const lessonsCount = lessons.length;
  const completedLessonsCount = completedLessons.filter((completedLessonId) =>
    lessons.some((lesson) => lesson.id === completedLessonId),
  ).length;

  return Number(((100 * completedLessonsCount) / lessonsCount).toFixed());
};

export const prepareModule = ({
  courseAvailable,
  module,
  completedLessons = [],
  completedModules = [],
}) => {
  const completed = completedModules.indexOf(module.id) > -1;
  const progress = _calculateProgress({
    lessons: module.lessons,
    completedLessons,
  });

  const lessons = module.lessons.map((lesson) => ({
    ...lesson,
    available: courseAvailable || lesson.free,
    completed: completedLessons.indexOf(lesson.id) > -1,
  }));

  return {
    ...module,
    available: lessons.some((lesson) => lesson.available),
    soon: lessons.length ? lessons.some((lesson) => lesson.soon) : true,
    completed,
    progress,
    lessons,
  };
};

export const getFreeLessonsCount = (modules) => {
  const lessons = _flatten(modules.map((module) => module.lessons));

  const lessonsCount = lessons.filter((lesson) => lesson.free).length;

  return lessonsCount;
};
