import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Collapsible } from 'components/shared/Collapsible';
import { Typography } from 'components/shared/Typography';

import styles from './SpoilerBlock.styles.scss';

const SpoilerBlock = ({ className, data }) => (
  <div className={className}>
    <Collapsible
      transitionTime={150}
      triggerTagName="div"
      className={styles.spoiler}
      openedClassName={cn(styles.spoiler, styles.spoilerOpened)}
      trigger={
        <div className={styles.header}>
          <Icon className={styles.icon} name="chevronDown" />
          <Typography className={styles.title} variant="subtitle">
            {data.title}
          </Typography>
        </div>
      }
    >
      <div className={styles.inner}>
        <Typography className={styles.text} variant="body1">
          {data.text}
        </Typography>
      </div>
    </Collapsible>
  </div>
);

export default React.memo(SpoilerBlock);
