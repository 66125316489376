import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './TabsLayout.styles.scss';

const TabsLayout = ({ className, children, filters, title }) => (
  <div className={cn(styles.layout, className)}>
    <Typography variant="heading1" className={styles.title}>
      {title}
    </Typography>
    <div className={styles.inner}>{children}</div>
  </div>
);

export default React.memo(TabsLayout);
