import React from 'react';
import { useNavigate } from 'react-router-dom';

import CourseCard from './CourseCard';

const CourseCardContainer = ({
  id: courseId,
  soon: courseSoon,
  onCardClick,
  ...restProps
}) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    if (courseSoon) {
      return;
    }

    if (onCardClick) {
      onCardClick({ id: courseId, title: restProps.title });
    }

    navigate(`/courses/${courseId}`);
  };

  return (
    <CourseCard
      {...restProps}
      id={courseId}
      soon={courseSoon}
      onCardClick={handleCardClick}
    />
  );
};

export default React.memo(CourseCardContainer);
