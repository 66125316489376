export const prepareLesson = ({
  courseAvailable,
  lesson,
  completedLessons = [],
}) => {
  const completed = completedLessons.indexOf(lesson.id) > -1;

  return {
    ...lesson,
    available: courseAvailable || lesson.free,
    completed,
  };
};
