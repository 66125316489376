import React from 'react';
import cn from 'classnames';

import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import FeedbackIcon from 'assets/img/feedback.svg';
import FeedbackSmallIcon from 'assets/img/feedback-small.svg';

import styles from './ShareReview.styles.scss';

const ShareReview = ({ className, withResponsive, onAddReviewClick }) => (
  <div
    className={cn(
      styles.root,
      { [styles.responsiveRoot]: withResponsive },
      className,
    )}
  >
    <FeedbackSmallIcon
      className={styles.feedbackSmallIcon}
      onClick={onAddReviewClick}
    />
    <FeedbackIcon className={styles.feedbackIcon} />
    <Typography variant="body1" weight="medium" className={styles.text}>
      Want to share some idea, feedback?
    </Typography>
    <Button size="sm" className={styles.button} onClick={onAddReviewClick}>
      Submit review
    </Button>
  </div>
);

export default React.memo(ShareReview);
