import React from 'react';
import { Formik, Form as FormikForm } from 'formik';

import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import { FormikField } from 'components/FormikField';
import { InputField } from 'components/FormikFields';
import { AvatarUpload } from 'components/AvatarUpload';

import { initialValues, validationSchema } from './Form.formConfig';

import styles from './Form.styles.scss';

const Form = ({ loading, onStartLearningClick }) => (
  <Formik
    validateOnMount
    enableReinitialize
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onStartLearningClick}
  >
    {() => (
      <FormikForm className={styles.form}>
        <AvatarUpload className={styles.uploadAvatar} />
        <FormikField
          name="firstName"
          component={InputField}
          controlProps={{
            required: true,
            label: 'First name',
          }}
        />
        <FormikField
          name="lastName"
          component={InputField}
          controlProps={{
            required: true,
            label: 'Last name',
          }}
        />
        <Button
          className={styles.button}
          type="submit"
          size="md"
          startIcon={loading && <Loading color="var(--color-white-primary)" />}
        >
          Start Learning
        </Button>
      </FormikForm>
    )}
  </Formik>
);

export default React.memo(Form);
