import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as api from 'api/methods';

import { logEvent } from 'helpers/logEvent';
import { getSchoolNames } from 'helpers/getSchoolNames';

import { userSelector, userActivitySelector } from 'store/auth/selectors';

import { useFetch } from 'hooks/useFetch';
import { useQuery } from 'hooks/useQuery';
import { useModal } from 'hooks/useModal';
import { usePromocodeExipation } from 'hooks/usePromocodeExipation';

import { SuccessfulPaymentModal } from 'components/SuccessfulPaymentModal';

import { SetupAccount } from './components/SetupAccount';

import { orderCourses } from './DashboardPage.helpers';

import DashboardPage from './DashboardPage';

const DashboardPageContainer = () => {
  const user = useSelector(userSelector);
  const userActivity = useSelector(userActivitySelector);

  const navigate = useNavigate();

  const query = useQuery();

  const {
    showCard,
    expirationText,
    onCloseClick: onCloseNotificationClick,
  } = usePromocodeExipation();

  const [setupAccountOpen, openSetupAccount, closeSetupAccount] = useModal({
    defaultOpen: false,
  });
  const [
    successfulPaymentModalOpen,
    openSuccessfulPaymentModal,
    closeSuccessfulPaymentModal,
  ] = useModal({ defaultOpen: false });

  useEffect(() => {
    if (user && user.firstLogin) {
      openSetupAccount();
    }
  }, [user]);

  useEffect(() => {
    logEvent({
      eventType: 'Dashboard_Page - Viewed',
      userProps: {
        user_email: user.email,
        user_is_pro: userActivity.isProSubscription,
        user_school: getSchoolNames(user.schools),
      },
    });

    const subscriptionStatus = query.get('proSubscriptionStatus');

    if (subscriptionStatus === 'success') {
      logEvent({
        eventType: 'Success_Payment',
        userProps: {
          user_email: user.email,
          user_is_pro: userActivity.isProSubscription,
          user_school: getSchoolNames(user.schools),
        },
      });

      openSuccessfulPaymentModal();
    }
  }, []);

  const { loading: loadingCourses, data: courses } = useFetch(
    {
      defaultData: [],
      fetcher: api.getProgressCourses,
      immediately: true,
    },
    null,
    [],
  );

  const { loading: loadingFeaturedCourses, data: featuredCourses } = useFetch(
    {
      defaultData: [],
      fetcher: api.getFeaturedCourses,
      immediately: true,
    },
    null,
    [],
  );

  const handleCloseSuccessfulPaymentModal = () => {
    navigate('/dashboard', { replace: true });

    closeSuccessfulPaymentModal();
  };

  const loading = loadingCourses || loadingFeaturedCourses;
  const userName = user ? user.fullName : '';

  return (
    <>
      <DashboardPage
        loading={loading}
        showCard={showCard}
        expirationText={expirationText}
        courses={orderCourses(courses).slice(0, 1)}
        featuredCourses={featuredCourses.slice(0, 3)}
        userName={userName}
        onCloseNotificationClick={onCloseNotificationClick}
      />
      <SetupAccount open={setupAccountOpen} onClose={closeSetupAccount} />
      <SuccessfulPaymentModal
        open={successfulPaymentModalOpen}
        onClose={handleCloseSuccessfulPaymentModal}
      />
    </>
  );
};

export default React.memo(DashboardPageContainer);
