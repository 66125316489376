import React from 'react';
import cn from 'classnames';

import ConnectionLineIcon from 'assets/img/connection-line.svg';
import BefrontLogoIcon from 'assets/img/befront-logo.svg';
import FigmaLogoIcon from 'assets/img/figma-logo.svg';

import { Typography } from 'components/shared/Typography';
import { Link } from 'components/shared/Link';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';

import { BeforeStartLayout } from 'components/Layout';
import { FaqList } from 'components/FaqList';

import styles from './BeforeStartCoursePage.styles.scss';

const BeforeStartCoursePage = ({ onInstallLinkClick, onBackClick }) => (
  <BeforeStartLayout>
    <div className={styles.page}>
      <div className={styles.header}>
        <Button
          className={styles.back}
          variant="link"
          size="sm"
          startIcon={<Icon name="arrowLeft" />}
          onClick={onBackClick}
        >
          Back to course
        </Button>
      </div>

      <div className={styles.inner}>
        <Typography className={styles.title} variant="heading1">
          Before we start
        </Typography>
        <Typography className={styles.text} variant="body1" weight="medium">
          Befront's mission is to help you boost UX/UI skills through practice.
          Our courses consist of practical and theoretical lessons. To practice
          on real-life cases, you need to install and run Befront plugin in
          Figma. Install the plugin now so as you don't have to interrupt later.
        </Typography>
        <div className={styles.main}>
          <div className={styles.appItem}>
            <BefrontLogoIcon className={styles.appIcon} />
          </div>
          <ConnectionLineIcon className={styles.lineIcon} />
          <div className={cn(styles.appItem, styles.appItemFigma)}>
            <FigmaLogoIcon className={styles.appIcon} />
          </div>
        </div>
        <div className={styles.instructions}>
          <div className={styles.instructionItem}>
            <div className={styles.instructionOrder}>
              <Typography
                className={styles.instructionOrderText}
                variant="body1"
                mode="compact"
                weight="semiBold"
              >
                1
              </Typography>
            </div>
            <div className={styles.instructionInner}>
              <div className={styles.instructionTitleWrapper}>
                <Typography
                  className={styles.instructionTitle}
                  variant="subtitle"
                >
                  Install Figma Plugin
                </Typography>
                {/* <Icon
                    className={styles.instructionTitleIcon}
                    name="question"
                  /> */}
              </div>
              <Typography
                className={styles.instructionText}
                variant="body1"
                mode="compact"
              >
                To start the course, please,{' '}
                <Link
                  className={styles.instructionTextLink}
                  onClick={onInstallLinkClick}
                >
                  install Befront Figma plugin
                </Link>
              </Typography>
            </div>
          </div>
        </div>
        <div className={styles.instructionItem}>
          <div className={styles.instructionOrder}>
            <Typography
              className={styles.instructionOrderText}
              variant="body1"
              mode="compact"
              weight="semiBold"
            >
              2
            </Typography>
          </div>
          <div className={styles.instructionInner}>
            <div className={styles.instructionTitleWrapper}>
              <Typography
                className={styles.instructionTitle}
                variant="subtitle"
              >
                Open Figma plugin
              </Typography>
              {/* <Icon className={styles.instructionTitleIcon} name="question" /> */}
            </div>
            <FaqList
              items={[
                'Create "New Design" File in Figma;',
                'Click on the main menu (Figma icon) in the top-left corner;',
                'Hover your mouse over "plugins". This should open a list of all the plugins you\'ve installed on Figma;',
                'Click on Befront to run it.',
              ]}
            />
          </div>
        </div>
        <div className={styles.instructionItem}>
          <div className={styles.instructionOrder}>
            <Typography
              className={styles.instructionOrderText}
              variant="body1"
              mode="compact"
              weight="semiBold"
            >
              3
            </Typography>
          </div>
          <div className={styles.instructionInner}>
            <div className={styles.instructionTitleWrapper}>
              <Typography
                className={styles.instructionTitle}
                variant="subtitle"
              >
                Login
              </Typography>
              {/* <Icon className={styles.instructionTitleIcon} name="question" /> */}
            </div>
            <Typography
              className={styles.instructionText}
              variant="body1"
              mode="compact"
            >
              Login to your Befront account in the Figma plugin
            </Typography>
          </div>
        </div>
      </div>
    </div>
  </BeforeStartLayout>
);

export default React.memo(BeforeStartCoursePage);
