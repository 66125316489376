export const LESSON_TYPES = {
  FIGMA_LESSON: 'FIGMA_LESSON',
  THEORY_LESSON: 'THEORY_LESSON',
  QUIZ: 'QUIZ',
  CONNECTION_QUIZ: 'CONNECTION_QUIZ',
  TWO_COLUMNS_LESSON: 'TWO_COLUMNS_LESSON',
  DIFFERENCE_LESSON: 'DIFFERENCE_LESSON',
  CHAT_LESSON: 'CHAT_LESSON',
};
export const DURATIONS_OPTIONS = [
  { text: '10 min', value: '10_MIN' },
  { text: '30 min', value: '30_MIN' },
  { text: '1 hour', value: 'HOUR' },
  { text: '3 hour', value: '3_HOURS' },
  { text: '5 hours', value: '5_HOURS' },
  { text: '8 hours', value: '8_HOURS' },
];

export const AUTH_CODE_LENGTH = 4;

export const MENU_ITEMS = [
  { key: 'account', icon: 'users', title: 'Profile' },
  { key: 'logout', icon: 'logout', title: 'Logout' },
];

export const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';
export const MONTHLY_PRICE_LOOKUP_KEY = 'pro_plan_monthly';
export const ANNUALLY_PRICE_LOOKUP_KEY = 'pro_plan_annually';
export const WELCOME_COURSE_ID = '627a35e7afdc9d3093c73efe';
export const COURSE_STRUCTURES = {
  MODULES: 'MODULES',
  LESSONS: 'LESSONS',
};
export const COURSE_TYPES = {
  WEB: 'WEB',
  FIGMA_PLUGIN: 'FIGMA_PLUGIN',
};
export const RATE_MODAL_TYPES = {
  COMPLETED_MODULE: 'COMPLETED_MODULE',
  COMPLETED_COURSE: 'COMPLETED_COURSE',
};
export const COOKIE_ENABLED = 'bef_cookie';
export const HIDE_EXPIRATION_DATE = 'hide_expiration_date';
export const EMAIL_REGEX =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
