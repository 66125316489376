import React from 'react';
import cn from 'classnames';
import { useDroppable } from '@dnd-kit/core';

import { Typography } from 'components/shared/Typography';

import { WrongIcon } from 'components/WrongIcon';
import { CompletedIcon } from 'components/CompletedIcon';

import styles from './DropItemArea.styles.scss';

const DropItemArea = ({ className, submitted, items, id }) => {
  const { isOver, setNodeRef } = useDroppable({
    id,
  });

  return (
    <div
      className={cn(styles.area, { [styles.areaIsOver]: isOver }, className)}
      ref={setNodeRef}
    >
      {items && items.length > 0 ? (
        <div className={styles.items}>
          {items.map((item) => {
            const correct =
              (id === 'leftColumn' && item.side === 'left') ||
              (id === 'rightColumn' && item.side === 'right');

            return (
              <div
                className={cn(styles.item, {
                  [styles.itemCorrect]: correct && submitted,
                  [styles.itemIncorrect]: !correct && submitted,
                })}
                key={item.id}
              >
                <Typography
                  className={styles.itemText}
                  variant="body2"
                  mode="compact"
                >
                  {item.text}
                </Typography>

                {submitted &&
                  (correct ? (
                    <CompletedIcon className={styles.itemIconWrapper} />
                  ) : (
                    <WrongIcon className={styles.itemIconWrapper} />
                  ))}
              </div>
            );
          })}
        </div>
      ) : (
        <Typography className={styles.text} variant="body1">
          Drop items here
        </Typography>
      )}
    </div>
  );
};

export default React.memo(DropItemArea);
