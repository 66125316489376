import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';

import styles from './SubscriptionPlan.styles.scss';

const SubscriptionPlan = ({
  active,
  buttonLoading,
  type,
  price,
  priceCents,
  priceText,
  description,
  buttonText,
  onButtonClick,
}) => (
  <div
    className={cn(styles.plan, {
      [styles.planProYearly]: type === 'Pro Yearly',
    })}
  >
    <Typography variant="heading2" weight="bold" className={styles.type}>
      {type}
    </Typography>
    {type === 'Pro Yearly' && (
      <div className={styles.discount}>
        <Typography className={styles.discountText}>SAVE 35%</Typography>
      </div>
    )}
    <div className={styles.prices}>
      <Typography className={styles.price}>
        ${price}
        <Typography className={styles.priceCents} component="span">
          .{priceCents}
        </Typography>
      </Typography>
    </div>
    <Typography className={styles.priceText}>{priceText}</Typography>
    {active ? (
      <div className={styles.active}>
        <Icon className={styles.activeIcon} name="published" />
        <Typography className={styles.activeText}>Your plan</Typography>
      </div>
    ) : (
      <Button
        size="md"
        variant={type === 'Pro Yearly' ? 'primary' : 'outlined'}
        disabled={active}
        className={styles.button}
        startIcon={
          buttonLoading && <Loading color="var(--color-white-primary)" />
        }
        onClick={onButtonClick}
      >
        Get started
      </Button>
    )}

    <Typography variant="body1" className={styles.description}>
      {description}
    </Typography>
  </div>
);

export default React.memo(SubscriptionPlan);
