import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import styles from './MenuHeader.styles.scss';

const MenuHeader = ({
  open,
  prevDisabled,
  nextDisabled,
  lessonTitle,
  lessonOrder,
  lessonsCount,
  onNextLessonClick,
  onPreviousLessonClick,
}) => (
  <div className={styles.header}>
    <Button
      size="sm"
      mode="icon"
      variant="link"
      iconName="arrowLeft"
      iconColor="#1182F6"
      disabled={prevDisabled}
      onClick={onPreviousLessonClick}
    />
    <div className={styles.inner}>
      <Typography
        className={styles.title}
        variant="body2"
        weight="medium"
        mode="compact"
      >
        {lessonTitle || 'Untitled'}
      </Typography>
      <div className={styles.textWrapper}>
        <Typography
          className={styles.text}
          variant="caption"
          weight="medium"
          mode="compact"
        >
          {lessonOrder}/{lessonsCount}
        </Typography>
      </div>

      <Icon
        className={cn(styles.dropdownIcon, { [styles.dropdownIconOpen]: open })}
        name="chevronDown"
      />
    </div>
    <Button
      size="sm"
      mode="icon"
      variant="link"
      iconName="arrowRight"
      iconColor="#1182F6"
      disabled={nextDisabled}
      onClick={onNextLessonClick}
    />
  </div>
);

export default React.memo(MenuHeader);
