import React from 'react';
import SimpleBar from 'simplebar-react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import { HomeLayout } from 'components/Layout';

import { MENU_ITEMS } from './TermsPage.constants';

import styles from './TermsPage.styles.scss';

const TermsPage = ({ sectionsRef, currentSectionIndex, onItemClick }) => (
  <HomeLayout>
    <div className={styles.page}>
      <div className={styles.header}>
        <SimpleBar>
          <div className={styles.menu}>
            {MENU_ITEMS.map((item, index) => (
              <div
                key={item}
                className={cn(styles.menuItem, {
                  [styles.menuItemActive]: index === currentSectionIndex,
                })}
                onClick={() => onItemClick(index)}
              >
                <Typography
                  variant="body1"
                  weight="semiBold"
                  className={styles.menuItemText}
                >
                  {item}
                </Typography>
              </div>
            ))}
          </div>
        </SimpleBar>
      </div>

      <div className={styles.inner}>
        <div className={styles.section}>
          <Typography className={styles.title} variant="heading2">
            Terms of Service
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              Welcome to Befront, the website and service of Befront, Inc.
              (“Befront” “we,” or “us”). This page explains the terms by which
              you may use our online services, website, and software provided on
              or in connection with the service (collectively the “Service”). By
              accessing or using the Service, you signify that you have read,
              understood, and agree to be bound by this Terms of Service
              Agreement (“Agreement”) and to the collection and use of your
              information as set forth in the Befront Privacy Policy, whether or
              not you are a registered user of our Service. This Agreement
              applies to all visitors, users, and others who access the Service
              (“Users”).
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              PLEASE READ THIS AGREEMENT CAREFULLY TO ENSURE THAT YOU UNDERSTAND
              EACH PROVISION. THIS AGREEMENT CONTAINS A MANDATORY ARBITRATION OF
              DISPUTES PROVISION THAT REQUIRES THE USE OF ARBITRATION ON AN
              INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR
              CLASS ACTIONS.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[0]}>
          <Typography className={styles.sectionTitle} variant="subtitle">
            1. Use of Our Service
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              Befront provides an online interactive platform for taking and
              creating coding and design classes.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            A. Eligibility
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              This is a contract between you and Befront. You must read and
              agree to these terms before using the Befront Service. If you do
              not agree, you may not use the Service. You must be 16 years or
              older to use the Services. If you are less than 18 years of age
              and would like to register to use any part of the Services, please
              ask your parent or legal guardian to review and agree to these
              terms before you use any part of the Services, or ask them to
              complete the purchase or registration on your behalf. Supplemental
              terms and conditions may apply to some Services, such as rules for
              a particular competition, promotion, service or other activity, or
              terms that may accompany certain content accessible through the
              Services. Supplemental terms and conditions will be disclosed to
              you in connection with such competition, service or activity. Any
              supplemental terms and conditions are in addition to these terms
              and, in the event of a conflict, prevail over these terms. The
              Service is not available to any Users previously removed from the
              Service by Befront.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            B. Befront Service
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              Subject to the terms and conditions of this Agreement, you are
              hereby granted a non-exclusive, limited, non-transferable, freely
              revocable license to use the Service for your personal,
              noncommercial use only and as permitted by the features of the
              Service. Befront reserves all rights not expressly granted herein
              in the Service and the Befront Content (as defined below). Befront
              may terminate this license at any time for any reason or no
              reason.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            C. Befront Accounts
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              Your Befront account gives you access to the services and
              functionality that we may establish and maintain from time to time
              and in our sole discretion. By connecting to Befront with a
              third-party service (for example, Facebook or Twitter), you give
              us permission to access and use your information from that service
              as permitted by that service, and to store your log-in credentials
              for that service.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              You may never use another User’s account without permission. When
              creating your account, you must provide accurate and complete
              information. You are solely responsible for the activity that
              occurs on your account, and you must keep your account password
              secure. We encourage you to use “strong” passwords (passwords that
              use a combination of upper and lower case letters, numbers and
              symbols) with your account. You must notify Befront immediately of
              any breach of security or unauthorized use of your account.
              Befront will not be liable for any losses caused by any
              unauthorized use of your account.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              You may control your User profile and how you interact with the
              Service by changing the settings in your “account settings” page.
              By providing Befront your email address you consent to our using
              the email address to send you Service-related notices, including
              any notices required by law, in lieu of communication by postal
              mail. We may also use your email address to send you other
              messages, such as changes to features of the Service and special
              offers. If you do not want to receive such email messages, you may
              opt out or change your preferences in your “account settings”
              page. Opting out may prevent you from receiving email messages
              regarding updates, improvements, or offers.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            D. Service Rules
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              You agree not to engage in any of the following prohibited
              activities: (i) copying, distributing, or disclosing any part of
              the Service in any medium, including without limitation by any
              automated or non-automated “scraping”; (ii) using any automated
              system, including without limitation “robots,” “spiders,” “offline
              readers,” etc., to access the Service in a manner that sends more
              request messages to the Befront servers than a human can
              reasonably produce in the same period of time by using a
              conventional on-line web browser (except that Befront grants the
              operators of public search engines revocable permission to use
              spiders to copy materials from Befront.io for the sole purpose of
              and solely to the extent necessary for creating publicly available
              searchable indices of the materials, but not caches or archives of
              such materials); (iii) transmitting spam, chain letters, or other
              unsolicited email; (iv) attempting to interfere with, compromise
              the system integrity or security or decipher any transmissions to
              or from the servers running the Service; (v) taking any action
              that imposes, or may impose at our sole discretion an unreasonable
              or disproportionately large load on our infrastructure; (vi)
              uploading invalid data, viruses, worms, or other software agents
              through the Service; (vii) collecting or harvesting any personally
              identifiable information, including account names, from the
              Service; (viii) using the Service for any commercial solicitation
              purposes; (ix) impersonating another person or otherwise
              misrepresenting your affiliation with a person or entity,
              conducting fraud, hiding or attempting to hide your identity; (x)
              interfering with the proper working of the Service; (xi) accessing
              any content on the Service through any technology or means other
              than those provided or authorized by the Service; or (xii)
              bypassing the measures we may use to prevent or restrict access to
              the Service, including without limitation features that prevent or
              restrict use or copying of any content or enforce limitations on
              use of the Service or the content therein. We may, without prior
              notice, change the Service; stop providing the Service or features
              of the Service, to you or to users generally; or create usage
              limits for the Service. We may permanently or temporarily
              terminate or suspend your access to the Service without notice and
              liability for any reason, including if in our sole determination
              you violate any provision of this Agreement, or for no reason.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              You are solely responsible for your interactions with other
              Befront Users. We reserve the right, but have no obligation, to
              monitor disputes between you and other Users. Befront shall have
              no liability for your interactions with other Users, or for any
              User’s action or inaction.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[1]}>
          <Typography className={styles.sectionTitle} variant="subtitle">
            2. User Content
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              Some areas of the Service allow Users to post content such as
              profile information, comments, questions, course content and other
              content or information (any such materials a User submits, posts,
              displays, or otherwise makes available on the Service “User
              Content”). Access to these features may be subject to age
              restrictions. We claim no ownership rights over User Content
              created by you. The User Content you create remains yours;
              however, by sharing User Content through the Service, you agree to
              allow others to view, edit, and/or share your User Content in
              accordance with your settings and this Agreement. Befront has the
              right (but not the obligation) in its sole discretion to remove
              any User Content that is shared via the Service.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              You agree not to post User Content that: (i) may create a risk of
              harm, loss, physical or mental injury, emotional distress, death,
              disability, disfigurement, or physical or mental illness to you,
              to any other person, or to any animal; (ii) may create a risk of
              any other loss or damage to any person or property; (iii) seeks to
              harm or exploit children by exposing them to inappropriate
              content, asking for personally identifiable details or otherwise;
              (iv) may constitute or contribute to a crime or tort; (v) contains
              any information or content that we deem to be unlawful, harmful,
              abusive, racially or ethnically offensive, defamatory, infringing,
              invasive of personal privacy or publicity rights, harassing,
              humiliating to other people (publicly or otherwise), libelous,
              threatening, profane, or otherwise objectionable; (vi) contains
              any information or content that is illegal (including, without
              limitation, the disclosure of insider information under securities
              law or of another party’s trade secrets); (vii) contains any
              information or content that you do not have a right to make
              available under any law or under contractual or fiduciary
              relationships; or (viii) contains any information or content that
              you know is not correct and current or (ix) violates any school or
              other applicable policy, including those related to cheating or
              ethics. You agree that any User Content that you post does not and
              will not violate third-party rights of any kind, including without
              limitation any Intellectual Property Rights (as defined below) or
              rights of privacy. Befront reserves the right, but is not
              obligated, to reject and/or remove any User Content that Befront
              believes, in its sole discretion, violates these provisions. You
              understand that publishing your User Content on the Service is not
              a substitute for registering it with the U.S. Copyright Office,
              the Writer’s Guild of America, or any other rights organization.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              For the purposes of this Agreement, “Intellectual Property Rights”
              means all patent rights, copyright rights, mask work rights, moral
              rights, rights of publicity, trademark, trade dress and service
              mark rights, goodwill, trade secret rights and other intellectual
              property rights as may now exist or hereafter come into existence,
              and all applications therefore and registrations, renewals and
              extensions thereof, under the laws of any state, country,
              territory or other jurisdiction.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              In connection with your User Content, you affirm, represent and
              warrant the following: Your User Content and Befront’s use thereof
              as contemplated by this Agreement and the Service will not violate
              any law or infringe any rights of any third party, including but
              not limited to any Intellectual Property Rights and privacy
              rights. Befront may exercise the rights to your User Content
              granted under this Agreement without liability for payment of any
              guild fees, residuals, payments, fees, or royalties payable under
              any collective bargaining agreement or otherwise. To the best of
              your knowledge, all your User Content you provide to us is
              truthful and accurate. You have the written consent of each and
              every identifiable natural person in the User Content, if any, to
              use such person’s name or likeness in the manner contemplated by
              the Service and this Agreement, and each such person has released
              you from any liability that may arise in relation to such use.
              Befront takes no responsibility and assumes no liability for any
              User Content that you or any other User or third-party posts or
              sends over the Service. You shall be solely responsible for your
              User Content and the consequences of posting or publishing it, and
              you agree that we are only acting as a passive conduit for your
              online distribution and publication of your User Content. You
              understand and agree that you may be exposed to User Content that
              is inaccurate, objectionable, inappropriate for children, or
              otherwise unsuited to your purpose, and you agree that Befront
              shall not be liable for any damages you allege to incur as a
              result of User Content.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[2]}>
          <Typography className={styles.sectionTitle} variant="subtitle">
            3. User Content License Grant
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              By posting any User Content on the Service, you expressly grant,
              and you represent and warrant that you have all rights necessary
              to grant, to Befront a royalty-free, sublicensable, transferable,
              perpetual, irrevocable, non-exclusive, worldwide license to use,
              reproduce, modify, publish, list information regarding, edit,
              translate, distribute, syndicate, publicly perform, publicly
              display, and make derivative works of all such User Content and
              your name, voice, and/or likeness as contained in your User
              Content, in whole or in part, and in any form, media or
              technology, whether now known or hereafter developed, for use in
              connection with the Service and Befront’s (and its successors’ and
              affiliates’) business, including without limitation for promoting
              and redistributing part or all of the Service (and derivative
              works thereof) in any media formats and through any media
              channels. You also hereby grant each User of the Service a
              non-exclusive license to access your User Content through the
              Service, and to use, display, reproduce and perform such User
              Content solely as permitted through the functionality of the
              Service and under this Agreement.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[3]}>
          <Typography className={styles.sectionTitle} variant="subtitle">
            4. Our Proprietary Rights
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              Except for your User Content, the Service and all materials
              therein or transferred thereby, including, without limitation,
              software, images, text, graphics, illustrations, logos, patents,
              trademarks, service marks, copyrights, photographs, audio, videos,
              music, and User Content belonging to other Users (the “Befront
              Content”), and all Intellectual Property Rights related thereto,
              are the exclusive property of Befront and its licensors (including
              other Users who post User Content to the Service). Except as
              explicitly provided herein, nothing in this Agreement shall be
              deemed to create a license in or under any such Intellectual
              Property Rights, and you agree not to sell, license, rent, modify,
              distribute, copy, reproduce, transmit, publicly display, publicly
              perform, publish, adapt, edit or create derivative works from any
              Befront Content. Use of the Befront Content for any purpose not
              expressly permitted by this Agreement is strictly prohibited. You
              may choose to or we may invite you to submit comments or ideas
              about the Service, including without limitation about how to
              improve the Service or our products (“Ideas”). By submitting any
              Idea, you agree that your disclosure is gratuitous, unsolicited
              and without restriction and will not place Befront under any
              fiduciary or other obligation, and that we are free to use the
              Idea without any additional compensation to you, and/or to
              disclose the Idea on a non-confidential basis or otherwise to
              anyone. You further acknowledge that, by acceptance of your
              submission, Befront does not waive any rights to use similar or
              related ideas previously known to Befront, or developed by its
              employees, or obtained from sources other than you.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[4]}>
          <Typography className={styles.sectionTitle} variant="subtitle">
            5. Paid Services
          </Typography>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            A. Billing Policies
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              Certain aspects of the Service may be provided for a fee or other
              charge. If you elect to use paid aspects of the Service, you agree
              to the pricing and payment terms, as we may update them from time
              to time. Befront may add new services for additional fees and
              charges, or amend fees and charges for existing services, at any
              time in its sole discretion. Any change to our pricing or payment
              terms shall become effective in the billing cycle following notice
              of such change to you as provided in this Agreement or otherwise.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              B. Payment Information; Taxes All information that you provide in
              connection with a purchase or transaction or other monetary
              transaction interaction with the Service must be accurate,
              complete, and current. You agree to pay all charges incurred by
              users of your credit card, debit card, or other payment method
              used in connection with a purchase or transaction or other
              monetary transaction interaction with the Service at the prices in
              effect when such charges are incurred. You will pay any applicable
              taxes relating to any such purchases, transactions or other
              monetary transaction interactions.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[5]}>
          <Typography className={styles.sectionTitle} variant="subtitle">
            6. Privacy
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              We care about the privacy of our Users. You understand that by
              using the Services you consent to the collection, use and
              disclosure of your personally identifiable information and
              aggregate data as set forth in our Privacy Policy.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[6]}>
          <Typography className={styles.sectionTitle} variant="subtitle">
            7. Security
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              Befront cares about the integrity and security of your personal
              information. However, we cannot guarantee that unauthorized third
              parties will never be able to defeat our security measures or use
              your personal information for improper purposes. You acknowledge
              that you provide your personal information at your own risk.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[7]}>
          <Typography className={styles.sectionTitle} variant="subtitle">
            8. Third-Party Links
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              The Service may contain links to third-party websites,
              advertisers, services, special offers, or other events or
              activities that are not owned or controlled by Befront. Befront
              does not endorse or assume any responsibility for any such
              third-party sites, information, materials, products, or services.
              If you access a third party website from the Service, you do so at
              your own risk, and you understand that this Agreement and
              Befront’s Privacy Policy do not apply to your use of such sites.
              You expressly relieve Befront from any and all liability arising
              from your use of any third-party website, service, or content.
              Additionally, your dealings with or participation in promotions of
              advertisers found on the Service, including payment and delivery
              of goods, and any other terms (such as warranties) are solely
              between you and such advertisers. You agree that Befront shall not
              be responsible for any loss or damage of any sort relating to your
              dealings with such advertisers.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[8]}>
          <Typography className={styles.sectionTitle} variant="subtitle">
            9. Indemnity
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              You agree to defend, indemnify and hold harmless Befront and its
              subsidiaries, agents, licensors, managers, and other affiliated
              companies, and their employees, contractors, agents, officers and
              directors, from and against any and all claims, damages,
              obligations, losses, liabilities, costs or debt, and expenses
              (including but not limited to attorney’s fees) arising out of or
              related to: (i) your use of and access to the Service, including
              any data or content transmitted or received by you; (ii) your
              violation of any term of this Agreement, including without
              limitation your breach of any of the representations and
              warranties above; (iii) your violation of any third-party right,
              including without limitation any right of privacy or Intellectual
              Property Rights; (iv) your violation of any applicable law, rule
              or regulation; (v) User Content or any other information or
              content that is submitted via your account including without
              limitation misleading, false or inaccurate information; (vi)
              negligent or willful misconduct; or (vii) any other party’s access
              and use of other appropriate security code.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[9]}>
          <Typography className={styles.sectionTitle} variant="subtitle">
            10. No Warranty
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
              USE OF THE SERVICE IS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT
              PERMITTED BY APPLICABLE LAW, THE SERVICE IS PROVIDED WITHOUT
              WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT
              NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
              A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. WITHOUT LIMITING THE
              FOREGOING, BEFRONT, ITS SUBSIDIARIES, ITS AFFILIATES, AND ITS
              LICENSORS DO NOT WARRANT THAT THE CONTENT IS ACCURATE, RELIABLE OR
              CORRECT; THAT THE SERVICE WILL MEET YOUR REQUIREMENTS; THAT THE
              SERVICE WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION,
              UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE
              CORRECTED; OR THAT THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL
              COMPONENTS. ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH
              THE USE OF THE SERVICE IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL
              BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR
              MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM SUCH DOWNLOAD OR
              YOUR USE OF THE SERVICE. SOME STATES DO NOT ALLOW THE EXCLUSION OF
              IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
              THIS AGREEMENT GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO
              HAVE OTHER RIGHTS WHICH VARY FROM STATE TO STATE. THE DISCLAIMERS
              AND EXCLUSIONS UNDER THIS AGREEMENT WILL NOT APPLY TO THE EXTENT
              PROHIBITED BY APPLICABLE LAW.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              BEFRONT DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
              RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY
              A THIRD PARTY THROUGH THE BEFRONT SERVICE OR ANY HYPERLINKED
              WEBSITE OR SERVICE, AND BEFRONT WILL NOT BE A PARTY TO OR IN ANY
              WAY MONITOR ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS
              OF PRODUCTS OR SERVICES.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[10]}>
          <Typography className={styles.sectionTitle} variant="subtitle">
            11. Limitation of Liability
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
              SHALL BEFRONT, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES,
              SUPPLIERS OR LICENSORS BE LIABLE FOR ANY INDIRECT, PUNITIVE,
              INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING
              WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE,
              DATA OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR RELATING TO THE
              USE OF, OR INABILITY TO USE, THIS SERVICE. UNDER NO CIRCUMSTANCES
              WILL BEFRONT BE RESPONSIBLE FOR ANY DAMAGE, LOSS OR INJURY
              RESULTING FROM HACKING, TAMPERING OR OTHER UNAUTHORIZED ACCESS OR
              USE OF THE SERVICE OR YOUR ACCOUNT OR THE INFORMATION CONTAINED
              THEREIN.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, BEFRONT ASSUMES
              NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR
              INACCURACIES OF CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE,
              OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF
              OUR SERVICE; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE
              SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN;
              (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
              SERVICE; (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT
              MAY BE TRANSMITTED TO OR THROUGH OUR SERVICE BY ANY THIRD PARTY;
              (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR
              DAMAGE INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
              EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH THE
              SERVICE; AND/OR (VII) USER CONTENT OR THE DEFAMATORY, OFFENSIVE,
              OR ILLEGAL CONDUCT OF ANY THIRD PARTY. IN NO EVENT SHALL BEFRONT,
              ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS, OR
              LICENSORS BE LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS,
              LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS IN AN AMOUNT
              EXCEEDING THE AMOUNT YOU PAID TO BEFRONT HEREUNDER OR $100.00,
              WHICHEVER IS GREATER.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED
              LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
              LIABILITY, OR ANY OTHER BASIS, EVEN IF BEFRONT HAS BEEN ADVISED OF
              THE POSSIBILITY OF SUCH DAMAGE. THE FOREGOING LIMITATION OF
              LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN
              THE APPLICABLE JURISDICTION.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL
              OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS
              MAY NOT APPLY TO YOU. THIS AGREEMENT GIVES YOU SPECIFIC LEGAL
              RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM STATE
              TO STATE. THE EXCLUSIONS AND LIMITATIONS OF LIABILITY UNDER THIS
              AGREEMENT WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE
              LAW.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              The Service is controlled and operated from facilities in
              Delaware, USA. Befront makes no representations that the Service
              is appropriate or available for use in other locations. Those who
              access or use the Service from other jurisdictions do so at their
              own volition and are entirely responsible for compliance with all
              applicable Delaware and local laws and regulations, including but
              not limited to export and import regulations. You may not use the
              Service if you are a resident of a country embargoed by the USA,
              or are a foreign person or entity blocked or denied by US
              government. Unless otherwise explicitly stated, all materials
              found on the Service are solely directed to individuals,
              companies, or other entities located in Delaware, USA.
            </Typography>
          </div>
        </div>
        <div className={styles.section} ref={sectionsRef.current[11]}>
          <Typography className={styles.sectionTitle} variant="subtitle">
            12. General
          </Typography>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            A. Assignment.
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              This Agreement, and any rights and licenses granted hereunder, may
              not be transferred or assigned by you, but may be assigned by
              Befront without restriction. Any attempted transfer or assignment
              in violation hereof shall be null and void.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            B. Notification Procedures and Changes to the Agreement.
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              Befront may provide notifications, whether such notifications are
              required by law or are for marketing or other business related
              purposes, to you via email notice, written or hard copy notice, or
              through posting of such notice on our website, as determined by
              Befront in our sole discretion. Befront reserves the right to
              determine the form and means of providing notifications to our
              Users, provided that you may opt out of certain means of
              notification as described in this Agreement. Befront is not
              responsible for any automatic filtering you or your network
              provider may apply to email notifications we send to the email
              address you provide us. Befront may, in its sole discretion,
              modify or update this Agreement from time to time, and so you
              should review this page periodically. When we change the Agreement
              in a material manner, we will update the ‘last modified’ date at
              the bottom of this page. Your continued use of the Service after
              any such change constitutes your acceptance of the new Terms of
              Service. If you do not agree to any of these terms or any future
              Terms of Service, do not use or access (or continue to access) the
              Service.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            C. Entire Agreement/Severability.
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              This Agreement, together with any amendments and any additional
              agreements you may enter into with Befront in connection with the
              Service, shall constitute the entire agreement between you and
              Befront concerning the Service. If any provision of this Agreement
              is deemed invalid by a court of competent jurisdiction, the
              invalidity of such provision shall not affect the validity of the
              remaining provisions of this Agreement, which shall remain in full
              force and effect, except that in the event of unenforceability of
              the universal Class Action/Jury Trial Waiver, the entire
              arbitration agreement shall be unenforceable.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            D. No Waiver.
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              No waiver of any term of this Agreement shall be deemed a further
              or continuing waiver of such term or any other term, and Befront’s
              failure to assert any right or provision under this Agreement
              shall not constitute a waiver of such right or provision.
            </Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography
            className={styles.sectionSubTitle}
            variant="body1"
            weight="semiBold"
          >
            E. Contact.
          </Typography>
          <div className={styles.sectionInner}>
            <Typography variant="body1" className={styles.sectionText}>
              For legal notices please mail or serve us at 251 Little Falls
              Drive, Wilmington, New Castle County, Delaware Count, USA, 19808 .
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              You can also contact us via our email info@befront.io.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              This Agreement was last modified January 25, 2020.
            </Typography>
            <Typography variant="body1" className={styles.sectionText}>
              BEFRONT, INC.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  </HomeLayout>
);
export default React.memo(TermsPage);
