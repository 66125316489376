import { initialValues } from './EditUserDetailsModal.formConfig';

export const convertDataToForm = (data) => {
  if (!data) {
    return initialValues;
  }

  return {
    avatar: data.avatar,
    firstName: data.firstName,
    lastName: data.lastName,
  };
};
