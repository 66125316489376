import React from 'react';
import cn from 'classnames';

import reviewsImgUrl from 'assets/img/landing/reviews.png';

import { Typography } from 'components/shared/Typography';

import styles from './ReviewsSection.styles.scss';

const ReviewsSection = () => (
  <div className={cn(styles.section, 'section')}>
    <div className={styles.inner}>
      <img className={styles.preview} src={reviewsImgUrl} />
      <div className={styles.main}>
        <Typography className={styles.text} family="prompt">
          “I'm always wanting to learn more about UX/UI design and would love to
          develop my skills, but sometimes I'm like 'where do I even start?'.
          It's cool that Befront allows you to do this but in bite-sized tasks.”
        </Typography>
        <Typography className={styles.author}>Kathryn Leslie</Typography>
        <Typography className={styles.position}>
          Head of Corporate Ops at Nook Calendar
        </Typography>
      </div>
    </div>
  </div>
);

export default React.memo(ReviewsSection);
