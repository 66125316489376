import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { isAuthenticatedSelector } from 'store/auth/selectors';

import NotFoundPage from './NotFoundPage';

const NotFoundPageContainer = () => {
  const navigate = useNavigate();

  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const handleButtonClick = () => {
    navigate(isAuthenticated ? '/dashboard' : '/');
  };

  return <NotFoundPage onButtonClick={handleButtonClick} />;
};

export default React.memo(NotFoundPageContainer);
