import React from 'react';

import Icon404 from 'assets/img/404.svg';

import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import { Header } from 'components/Header';

import styles from './NotFoundPage.styles.scss';

const NotFoundPage = ({ onButtonClick }) => (
  <div className={styles.page}>
    <Header className={styles.header} withLogo />
    <div className={styles.inner}>
      <Icon404 className={styles.icon} />
      <Typography className={styles.title} variant="heading1">
        Page Not Found
      </Typography>
      <Typography className={styles.text} variant="body1">
        We're sorry, the page you requested could not be found. Please, make
        sure you have typed the current URL
      </Typography>
      <Button className={styles.button} size="md" onClick={onButtonClick}>
        Go to home
      </Button>
    </div>
  </div>
);

export default React.memo(NotFoundPage);
