import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import { WrongIcon } from 'components/WrongIcon';
import { CompletedIcon } from 'components/CompletedIcon';

import styles from './AnswerImage.styles.scss';

const AnswerImage = ({
  submitted,
  correct,
  checked,
  answersMultiple,
  data,
  comment,
  size,
  onClick,
}) => (
  <div
    className={cn(styles.answer, {
      [styles.answerSelected]: checked,
      [styles.answerCorrect]:
        (checked && correct && submitted) || (!checked && correct && submitted),
      [styles.answerIncorrect]: checked && !correct && submitted,
      [styles.answerSubmitted]: submitted,
      [styles.answerWithComment]:
        ((checked && correct && submitted) ||
          (!checked && correct && submitted) ||
          (checked && !correct && submitted)) &&
        comment,
    })}
    onClick={!submitted ? onClick : () => {}}
  >
    <img className={styles.img} src={data} />
    <CompletedIcon
      className={cn(styles.iconWrapper, styles.iconWrapperCorrect)}
      iconClassName={styles.icon}
    />
    <WrongIcon
      className={cn(styles.iconWrapper, styles.iconWrapperIncorrect)}
      iconClassName={styles.icon}
    />
    {comment &&
      ((checked && correct && submitted) ||
        (!checked && correct && submitted) ||
        (checked && !correct && submitted)) && (
        <Typography
          className={cn(styles.comment, {
            [styles.commentCorrect]:
              (checked && correct && submitted) ||
              (!checked && correct && submitted),
            [styles.commentIncorrect]: checked && !correct && submitted,
          })}
          variant="body2"
          mode="compact"
        >
          {comment}
        </Typography>
      )}
  </div>
);

export default React.memo(AnswerImage);
