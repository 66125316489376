import React from 'react';
import cn from 'classnames';

import { TextBlock } from './TextBlock';
import { DividerBlock } from './DividerBlock';
import { ImageBlock } from './ImageBlock';
import { VideoBlock } from './VideoBlock';
import { SpoilerBlock } from './SpoilerBlock';
import { NumberListBlock } from './NumberListBlock';
import { MarkerListBlock } from './MarkerListBlock';

import styles from './TheoryBlock.styles.scss';

const TheoryBlock = ({ type, data }) => {
  switch (type) {
    case 'heading1':
    case 'heading2':
    case 'heading3':
    case 'text': {
      return (
        <TextBlock
          className={cn(styles.block, styles.textBlock)}
          type={type}
          data={data}
        />
      );
    }
    case 'spoiler': {
      return <SpoilerBlock className={styles.block} data={data} />;
    }
    case 'numberList': {
      return <NumberListBlock className={styles.block} data={data} />;
    }
    case 'markerList': {
      return <MarkerListBlock className={styles.block} data={data} />;
    }
    case 'image': {
      return <ImageBlock className={styles.block} data={data} />;
    }
    case 'video': {
      return <VideoBlock className={styles.block} data={data} />;
    }
    case 'divider': {
      return <DividerBlock className={styles.block} data={data} />;
    }
  }
};

export default React.memo(TheoryBlock);
