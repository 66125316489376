import React from 'react';

import { HomeLayout } from 'components/Layout';

import { StartSection } from './components/StartSection';
import { AdvantagesSection } from './components/AdvantagesSection';
import { PracticeSection } from './components/PracticeSection';
import { WhoSection } from './components/WhoSection';
import { ReviewsSection } from './components/ReviewsSection';
import { CatchySection } from './components/CatchySection';

import './HomePage.styles.scss';

const HomePage = ({ onStartClick }) => (
  <HomeLayout>
    <StartSection onStartClick={onStartClick} />
    <AdvantagesSection />
    <PracticeSection />
    <WhoSection />
    <ReviewsSection />
    <CatchySection onStartClick={onStartClick} />
  </HomeLayout>
);

export default React.memo(HomePage);
