export const prepareLessons = ({ lessons, currentLesson, course }) =>
  lessons.map((lesson) => ({
    ...lesson,
    available: course.available || lesson.free,
    current: currentLesson.id === lesson.id,
    completed: course.completedLessons.indexOf(lesson.id) > -1,
  }));

export const prepareModules = ({ modules, currentLesson, course }) =>
  modules.map((module) => ({
    ...module,
    lessons: module.lessons.map((lesson) => ({
      ...lesson,
      available: course.available || lesson.free,
      current: currentLesson.id === lesson.id,
      completed: course.completedLessons.indexOf(lesson.id) > -1,
    })),
  }));
