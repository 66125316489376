export const SECTIONS_COUNT = 16;
export const HEADER_HEIGHT = 64;
export const MENU_ITEMS = [
  'Information Collection',
  'Use of Information',
  'Sharing of Information',
  'Social Media and Technology Integrations',
  'Analytics and Advertising',
  'Your Rights and Choices',
  'Children’s Privacy',
  'Data Security',
  'International Transfer',
  'Changes to this Privacy Policy',
  'Links to Other Websites and Services',
  'Additional Disclosures for California Residents',
  'Additional Disclosures for Nevada Residents',
  'Additional Disclosures for Data Subjects in Europe',
  'Contact Us',
  'ANNEX 1',
];
