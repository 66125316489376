import React from 'react';
import cn from 'classnames';
import ReactPlayer from 'react-player';

import mainImageUrl from 'assets/animations/landing.mp4';

import styles from './StartSection.styles.scss';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';

import { ProductHuntBadge } from '../ProductHuntBadge';

const StartSection = ({ onStartClick }) => (
  <div className={cn(styles.section, 'section')}>
    <div className={styles.inner}>
      <ProductHuntBadge />
      <Typography className={styles.title} family="prompt" weight="bold">
        Learn design skills through practice
      </Typography>
      <div className={styles.tags}>
        <div className={styles.tag}>
          <Typography className={styles.tagText} weight="semiBold">
            Real-life cases
          </Typography>
        </div>
        <div className={styles.tag}>
          <Typography className={styles.tagText} weight="semiBold">
            Bite-sized practical tasks
          </Typography>
        </div>
        <div className={styles.tag}>
          <Typography className={styles.tagText} weight="semiBold">
            Step-by-step guided tutorials
          </Typography>
        </div>
      </div>
      <button className={styles.button} onClick={onStartClick}>
        Start learning for free
      </button>
      <div className={styles.caption}>
        <Icon className={styles.captionIcon} name="payment" />
        <Typography className={styles.captionText} variant="body3">
          No credit card required
        </Typography>
      </div>
    </div>
    <ReactPlayer
      className={styles.mainImg}
      url={mainImageUrl}
      playing
      muted
      playbackRate={1.5}
      loop
    />
  </div>
);

export default React.memo(StartSection);
