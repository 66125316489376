import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import * as api from 'api/methods';

import { logEvent } from 'helpers/logEvent';
import { getSchoolNames } from 'helpers/getSchoolNames';

import { userActivitySelector, userSelector } from 'store/auth/selectors';
import { courseSelector } from 'store/course/selectors';
import { lessonSelector } from 'store/lesson/selectors';

import { convertDataToApi } from './RateModal.helpers';

import RateModal from './RateModal';

const RateModalContainer = ({ open, onContinueClick, ...restProps }) => {
  const { courseId, lessonId } = useParams();

  const user = useSelector(userSelector);
  const userActivity = useSelector(userActivitySelector);
  const course = useSelector(courseSelector);
  const lesson = useSelector(lessonSelector);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      logEvent('Lesson_Page - Completed_Modal_Viewed', {
        user_email: user.email,
        user_is_pro: userActivity.isProSubscription,
        user_school: getSchoolNames(user.schools),
        course_title: course.title,
        lesson_title: lesson?.title,
      });
    }
  }, [open]);

  const handleContinueClick = async (values) => {
    try {
      setLoading(true);

      await api.rateLesson({
        courseId,
        lessonId,
        data: convertDataToApi(values),
      });
    } catch (error) {
      console.warn(error);
    }

    setLoading(false);

    if (onContinueClick) {
      onContinueClick();
    }
  };

  return (
    <RateModal
      {...restProps}
      open={open}
      loading={loading}
      onContinueClick={handleContinueClick}
    />
  );
};

export default React.memo(RateModalContainer);
