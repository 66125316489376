import React, { useState } from 'react';

import * as api from 'api/methods';

import { useAuthModal } from 'context/authModals';

import { logEvent } from 'helpers/logEvent';

import { convertDataToApi } from './SignUpModal.helpers';

import SignUpModal from './SignUpModal';

const SignUpModalContainer = ({ onClose, ...restProps }) => {
  const [currentStep, setCurrentStep] = useState('ENTER_EMAIL');
  const [currentEmail, setCurrentEmail] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { dispatch } = useAuthModal();

  const handleClose = () => {
    setCurrentStep('ENTER_EMAIL');
    setCurrentEmail(null);

    onClose();
  };

  const handleFormSubmit = async (values, { setFieldError }) => {
    try {
      setSubmitLoading(true);

      const data = convertDataToApi(values);

      await api.checkEmail(data);

      await api.sendCode(data);

      setCurrentStep('ENTER_CODE');

      logEvent({ eventType: 'Landing_Page - Enter_Code_Viewed' });

      setCurrentEmail(data.email);
    } catch (error) {
      console.warn(error);

      setFieldError('email', error.message);
    }

    setSubmitLoading(false);
  };

  const handleBackClick = () => {
    setCurrentStep('ENTER_EMAIL');
  };

  const handleHavePromocodeClick = (values) => {
    setCurrentStep('ENTER_PROMOCODE');

    setCurrentEmail(values.email);
  };

  const handleSignInClick = () => {
    logEvent({ eventType: 'Login_Clicked' });

    dispatch({
      type: 'OPEN_MODAL',
      payload: { currentModal: 'SIGNIN_MODAL', currentModalData: null },
    });
  };

  return (
    <SignUpModal
      {...restProps}
      submitLoading={submitLoading}
      currentStep={currentStep}
      currentEmail={currentEmail}
      setCurrentStep={setCurrentStep}
      setCurrentEmail={setCurrentEmail}
      onClose={handleClose}
      onFormSubmit={handleFormSubmit}
      onBackClick={handleBackClick}
      onHavePromocodeClick={handleHavePromocodeClick}
      onSignInClick={handleSignInClick}
    />
  );
};

export default React.memo(SignUpModalContainer);
