import React from 'react';

import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import styles from './DefaultModal.styles.scss';

const DefaultModal = ({
  open,
  loading,
  data,
  title,
  text,
  confirmText,
  cancelText,
  onConfirmClick,
  onCancelClick,
  onClose,
}) => (
  <Modal className={styles.modal} open={open} centered onClose={onClose}>
    {title && (
      <Typography className={styles.title} variant="heading2">
        {title}
      </Typography>
    )}
    {text && (
      <Typography className={styles.text} variant="body1">
        {text}
      </Typography>
    )}
    <div className={styles.actions}>
      <Button
        className={styles.cancelButton}
        color="secondary"
        size="md"
        onClick={onCancelClick}
      >
        {cancelText}
      </Button>
      <Button
        className={styles.confirmButton}
        size="md"
        startIcon={loading && <Loading />}
        onClick={() => onConfirmClick(data)}
      >
        {confirmText}
      </Button>
    </div>
  </Modal>
);

DefaultModal.defaultProps = {
  confirmText: 'Save',
  cancelText: 'Cancel',
};

export default React.memo(DefaultModal);
