export const ADVANTAGES = [
  {
    order: 1,
    title: 'Get started right',
    text: 'Learn design from the best market practices on real-life cases. Get the most out of Figma through our step-by-step guided tutorials.',
  },
  {
    order: 2,
    title: 'Learn by doing',
    text: 'Do bite-size design exercises in our interactive Figma Plugin and get instant feedback on your results.',
  },
  {
    order: 3,
    title: 'Master your skills',
    text: 'Advance your UX/UI level and keep learning with our weekly design practice and eye-training practices.',
  },
];
