import { ROUTES } from 'constants/routes';

export const getLocationName = (location) => {
  const matchingRoute = Object.entries(ROUTES).find(([_, route]) => {
    const routeRegex = new RegExp(`^${route.replace(/:\w+/g, '\\w+')}$`);
    return routeRegex.test(location);
  });

  return matchingRoute ? matchingRoute[0] : null;
};
