import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import styles from './EmptyState.styles.scss';

const EmptyState = ({
  className,
  withButton,
  buttonText,
  icon,
  title,
  text,
  onClick,
}) => (
  <div className={cn(styles.emptyState, className)}>
    <Icon name={icon} className={styles.icon} />
    <Typography variant="subtitle" weight="semiBold" className={styles.title}>
      {title}
    </Typography>
    <Typography variant="body1" className={styles.text}>
      {text}
    </Typography>
    {withButton && (
      <Button size="md" className={styles.button} onClick={onClick}>
        {buttonText}
      </Button>
    )}
  </div>
);

export default React.memo(EmptyState);
