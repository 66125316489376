import React from 'react';

import { Avatar } from 'components/shared/Avatar';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';
import { Loading } from 'components/shared/Loading';

import styles from './UserSection.styles.scss';

const UserSection = ({
  isProSubscription,
  billingSettingsLoading,
  user,
  onEditIconClick,
  onBillingSettingsClick,
}) => (
  <div className={styles.section}>
    <Avatar size="lg" text={user.name} url={user.avatar} />
    <div className={styles.inner}>
      <div className={styles.fullNameWrapper}>
        <Typography className={styles.fullName} variant="subtitle">
          {user.name}
        </Typography>
        {isProSubscription && (
          <div className={styles.tag}>
            <Typography
              className={styles.tagText}
              variant="body3"
              weight="medium"
              mode="compact"
            >
              PRO
            </Typography>
          </div>
        )}
      </div>
      <Typography className={styles.email} variant="body2" mode="compact">
        {user.email}
      </Typography>
    </div>
    <div className={styles.actions}>
      {isProSubscription && (
        <Button
          className={styles.action}
          variant="outlined"
          size="sm"
          startIcon={billingSettingsLoading && <Loading />}
          onClick={onBillingSettingsClick}
        >
          Billing Settings
        </Button>
      )}
      <Button
        className={styles.action}
        variant="outlined"
        mode="icon"
        size="sm"
        iconName="edit"
        iconColor="#1182F6"
        onClick={onEditIconClick}
      />
    </div>
  </div>
);

export default React.memo(UserSection);
