import React, { createContext, useContext, useReducer } from 'react';

import { SignUpModal } from 'components/SignUpModal';
import { SignInModal } from 'components/SignInModal';

const AUTH_MODALS = {
  SIGNUP_MODAL: 'SIGNUP_MODAL',
  SIGNIN_MODAL: 'SIGNIN_MODAL',
};

const AuthModalContext = createContext();

const authModalReducer = (_, action) => {
  switch (action.type) {
    case 'OPEN_MODAL': {
      return {
        currentModal: action.payload.currentModal,
        currentModalData: action.payload.currentModalData,
      };
    }
    case 'CLOSE_MODAL': {
      return {
        currentModal: null,
        currentModalData: null,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const AuthModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authModalReducer, {
    currentModal: null,
    currentModalData: null,
  });

  const value = { state, dispatch };

  const checkModalOpen = (modalName) => {
    return modalName === state.currentModal;
  };

  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL',
      payload: { currentModal: null, currentModalData: null },
    });
  };

  const openModal = (modalName, modalData) => {
    dispatch({
      type: 'OPEN_MODAL',
      payload: { currentModal: modalName, currentModalData: modalData },
    });
  };

  return (
    <AuthModalContext.Provider value={value}>
      {children}
      <SignUpModal
        {...state.currentModalData}
        open={checkModalOpen(AUTH_MODALS.SIGNUP_MODAL)}
        onClose={closeModal}
        openSignIn={() => openModal(AUTH_MODALS.SIGNIN_MODAL, null)}
      />
      <SignInModal
        {...state.currentModalData}
        open={checkModalOpen(AUTH_MODALS.SIGNIN_MODAL)}
        onClose={closeModal}
        openSignUp={() => openModal(AUTH_MODALS.SIGNUP_MODAL, null)}
      />
    </AuthModalContext.Provider>
  );
};

const useAuthModal = () => {
  const context = useContext(AuthModalContext);

  if (context === undefined) {
    throw new Error('useAuthModal must be used within a AuthModalProvider');
  }

  return context;
};

export { AuthModalProvider, useAuthModal };
