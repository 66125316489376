import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';

import styles from './LessonFooter.styles.scss';

const LessonFooter = ({ className, action, openReportLessonModal }) => (
  <div className={cn(styles.footer, className)}>
    <Button
      variant="ghost"
      startIcon={<Icon name="flag" />}
      onClick={openReportLessonModal}
    >
      Report
    </Button>
    {action}
  </div>
);

export default React.memo(LessonFooter);
