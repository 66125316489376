import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { logEvent } from 'helpers/logEvent';
import { getSchoolNames } from 'helpers/getSchoolNames';

import { userActivitySelector, userSelector } from 'store/auth/selectors';
import { courseSelector } from 'store/course/selectors';
import { lessonSelector } from 'store/lesson/selectors';

import { shuffle } from './TwoColumnsLessonPage.helpers';

import TwoColumnsLessonPage from './TwoColumnsLessonPage';

const TwoColumnsLessonPageContainer = (props) => {
  const user = useSelector(userSelector);
  const userActivity = useSelector(userActivitySelector);
  const course = useSelector(courseSelector);
  const lesson = useSelector(lessonSelector);

  const [draggingItem, setDraggingItem] = useState(null);
  const [leftItems, setLeftItems] = useState([]);
  const [rightItems, setRightItems] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [variants, setVariants] = useState([]);

  useEffect(() => {
    logEvent({
      eventType: 'Lesson_Page - Viewed',
      eventProps: {
        course_title: course.title,
        lesson_title: lesson?.title,
      },
      userProps: {
        user_email: user.email,
        user_is_pro: userActivity.isProSubscription,
        user_school: getSchoolNames(user.schools),
      },
    });

    const nextVariants = shuffle([
      ...lesson.leftVariants.map((variant) => ({ ...variant, side: 'left' })),
      ...lesson.rightVariants.map((variant) => ({ ...variant, side: 'right' })),
    ]);

    setVariants(nextVariants);
  }, []);

  const handleCheckClick = () => {
    setSubmitted(true);
  };

  const handleTryAgainClick = () => {
    setLeftItems([]);
    setRightItems([]);
    setDraggingItem(null);
    setSubmitted(false);
  };

  const handleItemDragStart = (event) => {
    const { active } = event;

    const data = active.data?.current;

    setDraggingItem({ ...data, id: active.id });
  };

  const handleItemDragEnd = (event) => {
    const { over, active } = event;

    const draggingSectionData = active.data?.current;

    if (over.id && over.id === 'leftColumn') {
      setLeftItems((prevItems) => [...prevItems, draggingSectionData]);

      return;
    }

    if (over.id && over.id === 'rightColumn') {
      setRightItems((prevItems) => [...prevItems, draggingSectionData]);

      return;
    }

    setDraggingItem(null);
  };

  const handleItemDragCancel = () => {
    setDraggingItem(null);
  };

  const allChecked = [...leftItems, ...rightItems].length === variants.length;
  const correct =
    allChecked &&
    leftItems.every((item) => item.side === 'left') &&
    rightItems.every((item) => item.side === 'right');

  return (
    <TwoColumnsLessonPage
      {...props}
      correct={correct}
      submitted={submitted}
      allChecked={allChecked}
      variants={variants}
      leftItems={leftItems}
      rightItems={rightItems}
      lesson={lesson}
      draggingItem={draggingItem}
      onItemDragStart={handleItemDragStart}
      onItemDragEnd={handleItemDragEnd}
      onItemDragCancel={handleItemDragCancel}
      onTryAgainClick={handleTryAgainClick}
      onCheckClick={handleCheckClick}
    />
  );
};

export default React.memo(TwoColumnsLessonPageContainer);
