import React from 'react';
import { DndContext } from '@dnd-kit/core';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import { LessonLayout } from 'components/Layout';
import { LessonFooter } from 'components/LessonFooter';

import { Action } from './components/Action';
import { DraggableItem } from './components/DraggableItem';
import { DraggableOverlay } from './components/DraggableOverlay';
import { DropItemArea } from './components/DropItemArea';

import styles from './TwoColumnsLessonPage.styles.scss';

const TwoColumnsLessonPage = ({
  correct,
  submitted,
  allChecked,
  nextLoading,
  isLastLessonInCourse,
  variants,
  leftItems,
  rightItems,
  draggingItem,
  lesson,
  onItemDragStart,
  onItemDragEnd,
  onItemDragCancel,
  onTryAgainClick,
  onNextLessonClick,
  onCheckClick,
  openReportLessonModal,
}) => {
  return (
    <LessonLayout>
      <div className={styles.page}>
        <Typography variant="subtitle" className={styles.subtitle}>
          {lesson.text}
        </Typography>
        <DndContext
          onDragStart={onItemDragStart}
          onDragEnd={onItemDragEnd}
          onItemDragCancel={onItemDragCancel}
        >
          <div className={styles.items}>
            {variants
              .filter(
                (item) =>
                  ![...leftItems, ...rightItems].some(
                    (droppedItem) => droppedItem.id === item.id,
                  ),
              )
              .map((item) => (
                <DraggableItem
                  className={cn(styles.item, {
                    [styles.itemDragging]:
                      draggingItem && draggingItem.id === item.id,
                    [styles.itemDropped]:
                      leftItems.some((leftItem) => leftItem.id === item.id) ||
                      rightItems.some((rightItem) => rightItem.id === item.id),
                  })}
                  key={item.id}
                  data={item}
                />
              ))}
          </div>
          <div className={styles.inner}>
            <div className={styles.column}>
              <Typography
                className={styles.columnTitle}
                variant="body1"
                weight="medium"
                mode="compact"
              >
                {lesson.leftVariantsTitle}
              </Typography>
              <DropItemArea
                id="leftColumn"
                submitted={submitted}
                items={leftItems}
              />
            </div>
            <div className={styles.column}>
              <Typography
                className={styles.columnTitle}
                variant="body1"
                weight="medium"
                mode="compact"
              >
                {lesson.rightVariantsTitle}
              </Typography>
              <DropItemArea
                id="rightColumn"
                correct={correct}
                submitted={submitted}
                items={rightItems}
              />
            </div>
          </div>
          <DraggableOverlay />
        </DndContext>
        <LessonFooter
          openReportLessonModal={openReportLessonModal}
          action={
            <Action
              correct={correct}
              submitted={submitted}
              allChecked={allChecked}
              nextLoading={nextLoading}
              isLastLessonInCourse={isLastLessonInCourse}
              onTryAgainClick={onTryAgainClick}
              onNextLessonClick={onNextLessonClick}
              onCheckClick={onCheckClick}
            />
          }
        />
      </div>
    </LessonLayout>
  );
};

export default React.memo(TwoColumnsLessonPage);
