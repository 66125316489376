import React from 'react';
import { useNavigate } from 'react-router-dom';

import * as api from 'api/methods';

import { useFetch } from 'hooks/useFetch';

import ProfilePage from './ProfilePage';

const ProfilePageContainer = () => {
  const navigate = useNavigate();

  const { loading: loadingMyCourses, data: myCourses } = useFetch(
    {
      defaultData: [],
      fetcher: api.getProgressCourses,
      immediately: true,
    },
    null,
    [],
  );

  const { loading: loadingFavoritesCourses, data: favoriteCourses } = useFetch(
    {
      defaultData: [],
      fetcher: api.getFavoriteCourses,
      immediately: true,
    },
    null,
    [],
  );

  const handleCourseCardClick = (courseId) => {
    navigate(`/courses/${courseId}`);
  };

  const loading = loadingMyCourses || loadingFavoritesCourses;

  return (
    <ProfilePage
      loading={loading}
      myCourses={myCourses}
      favoriteCourses={favoriteCourses}
      onCourseCardClick={handleCourseCardClick}
    />
  );
};

export default React.memo(ProfilePageContainer);
