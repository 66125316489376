import { useState, useRef, useEffect } from 'react';

export const useTimer = ({ defaultSeconds = 0, defaultStarted = false }) => {
  const [secondsLeft, setSecondsLeft] = useState(defaultSeconds);
  const [started, setStarted] = useState(defaultStarted);

  const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    });

    useEffect(() => {
      const tick = () => {
        savedCallback.current();
      };

      if (delay !== null && started) {
        const id = setInterval(tick, delay);

        return () => clearInterval(id);
      }
    }, [delay, started]);
  };

  useInterval(() => {
    if (secondsLeft > 0) {
      setSecondsLeft(secondsLeft - 1);
    } else {
      setStarted(false);
    }
  }, 1000);

  const startTimer = (seconds) => {
    setSecondsLeft(seconds);
    setStarted(true);
  };

  return [secondsLeft, { startTimer }];
};
