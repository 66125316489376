import React, { useState } from 'react';

import ScrollWrapper from './ScrollWrapper';

const ScrollWrapperContainer = (props) => {
  const [scrolling, setScrolling] = useState(false);

  const handleScrollStart = () => {
    setScrolling(true);
  };

  const handleScrollStop = () => {
    setTimeout(() => {
      setScrolling(false);
    }, 300);
  };

  return (
    <ScrollWrapper
      {...props}
      scrolling={scrolling}
      onScrollStart={handleScrollStart}
      onScrollStop={handleScrollStop}
    />
  );
};

export default React.memo(ScrollWrapperContainer);
