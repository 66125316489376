import * as Yup from 'yup';

export const initialValues = {
  tags: [],
  text: '',
};

export const validationSchema = Yup.object().shape({
  tags: Yup.array()
    .test('empty', 'At least one option must be chosen', function (value) {
      return value && value.length >= 1;
    })
    .required('At least one option must be chosen'),
  text: Yup.string().max(300, 'Message cannot be longer than 300 characters.'),
});
