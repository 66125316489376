import { ANSWER_IMAGE_SIZE } from './QuizPage.constants';

export const isAnswersMultiple = (question) =>
  question.answers.filter((answer) => answer.correct).length > 1;

export const isAnswersCorrect = ({
  questions,
  currentAnswers,
  currentQuestionIndex,
}) => {
  const currentQuestion = questions[currentQuestionIndex];

  const correctAnswers = currentQuestion.answers.filter(
    (answer) => answer.correct,
  );

  const correct =
    correctAnswers.every((answer) => currentAnswers.includes(answer.id)) &&
    currentAnswers.length <= correctAnswers.length;

  return correct;
};

export const getAnswersImageSize = (answers) => {
  if (answers.length > 6) {
    return ANSWER_IMAGE_SIZE.SMALL;
  }

  if (answers.length < 7 && answers.length > 4) {
    return ANSWER_IMAGE_SIZE.MEDIUM;
  }

  return ANSWER_IMAGE_SIZE.LARGE;
};

const shuffleArray = (array) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

export const randomizeLessonAnswers = (less) => {
  return {
    ...less,
    questions: less.questions.map((question) => {
      return { ...question, answers: shuffleArray(question.answers) };
    }),
  };
};
