import React from 'react';

import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';
import { Loading } from 'components/shared/Loading';

const Action = ({
  submitted,
  nextLoading,
  isLastLessonInCourse,
  onNextLessonClick,
}) => {
  if (submitted && isLastLessonInCourse) {
    return (
      <Button
        disabled={!submitted}
        startIcon={
          nextLoading && <Loading color="var(--color-white-primary)" />
        }
        endIcon={<Icon name="arrowRight" />}
        onClick={onNextLessonClick}
      >
        Finish course
      </Button>
    );
  }

  return (
    <Button
      disabled={!submitted}
      startIcon={nextLoading && <Loading color="var(--color-white-primary)" />}
      endIcon={<Icon name="arrowRight" />}
      onClick={onNextLessonClick}
    >
      Next lesson
    </Button>
  );
};

export default React.memo(Action);
