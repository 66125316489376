import React from 'react';
import cn from 'classnames';

import { DEFAULT_PROGRESS } from './LinearProgressBar.constants';

import styles from './LinearProgressBar.styles.scss';

const LinearProgressBar = ({
  className,
  trackClassName,
  thumbClassName,
  progress,
  rating,
}) => (
  <div className={cn(styles.bar, { [styles.rating]: rating }, className)}>
    <div
      className={cn(
        styles.thumb,
        { [styles.progressThumb]: progress > DEFAULT_PROGRESS },
        thumbClassName,
      )}
    >
      <div
        className={cn(
          styles.track,
          { [styles.progressTrack]: progress === 100 },
          trackClassName,
        )}
        style={{
          width: `${
            progress > DEFAULT_PROGRESS ? progress : DEFAULT_PROGRESS
          }%`,
        }}
      />
    </div>
  </div>
);

export default React.memo(LinearProgressBar);
