import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { logEvent } from 'helpers/logEvent';
import { getSchoolNames } from 'helpers/getSchoolNames';

import { userActivitySelector, userSelector } from 'store/auth/selectors';
import { courseSelector } from 'store/course/selectors';
import { lessonSelector } from 'store/lesson/selectors';

import TheoryLessonPage from './TheoryLessonPage';

const TheoryLessonPageContainer = (props) => {
  const user = useSelector(userSelector);
  const userActivity = useSelector(userActivitySelector);
  const course = useSelector(courseSelector);
  const lesson = useSelector(lessonSelector);

  useEffect(() => {
    logEvent({
      eventType: 'Lesson_Page - Viewed',
      eventProps: {
        course_title: course.title,
        lesson_title: lesson?.title,
      },
      userProps: {
        user_email: user.email,
        user_is_pro: userActivity.isProSubscription,
        user_school: getSchoolNames(user.schools),
      },
    });
  }, []);

  return <TheoryLessonPage {...props} lesson={lesson} />;
};

export default React.memo(TheoryLessonPageContainer);
