import React from 'react';

import UploadPreview from './UploadPreview/UploadPreview';
import UploadMaterials from './UploadMaterials/UploadMaterials';

const VARIANTS = {
  preview: UploadPreview,
  materials: UploadMaterials,
};

const Upload = ({ variant, ...restProps }) => {
  const UploadComponent = VARIANTS[variant];

  return <UploadComponent {...restProps} />;
};

Upload.defaultProps = {
  variant: 'preview',
  text: 'Drop your image here or',
};

export default React.memo(Upload);
