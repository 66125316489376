import React from 'react';
import cn from 'classnames';
import { Formik, Form, ErrorMessage } from 'formik';

import SuccessIcon from 'assets/img/success.svg';

import { Modal } from 'components/shared/Modal';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import { InputField, SelectTagField } from 'components/FormikFields';

import { REPORT_LESSON_MODAL_TAGS } from './ReportLessonModal.constants';

import {
  initialValues,
  validationSchema,
} from './ReportLessonModal.formConfig';

import styles from './ReportLessonModal.styles.scss';

const ReportLessonModal = ({
  loading,
  open,
  currentStep,
  onClose,
  onSubmitClick,
}) => (
  <Modal className={styles.modal} open={open} onClose={onClose}>
    {currentStep === 1 && (
      <>
        <Typography variant="subtitle">Provide feedback</Typography>
        <Typography variant="body1" className={styles.description}>
          Your feedback helps us create a great experience. Please let us known
          what went wrong
        </Typography>
        <Formik
          validateOnMount
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmitClick}
        >
          {({ values, errors }) => (
            <Form className={styles.form}>
              <div className={styles.tags}>
                {REPORT_LESSON_MODAL_TAGS.map((tag) => (
                  <FormikField
                    key={tag}
                    name="tags"
                    withControl={false}
                    component={SelectTagField}
                    componentProps={{
                      className: styles.tag,
                      type: 'checkbox',
                      text: tag,
                      value: tag,
                    }}
                  />
                ))}
              </div>
              {errors.tags && (
                <ErrorMessage name="tags">
                  {(message) => (
                    <Typography
                      variant="body3"
                      mode="compact"
                      className={styles.error}
                    >
                      {message}
                    </Typography>
                  )}
                </ErrorMessage>
              )}
              <FormikField
                name="text"
                component={InputField}
                componentProps={{
                  endAdornmentClassName: styles.endAdornment,
                  placeholder: 'Describe your issue...',
                  multiline: true,
                  endAdornment: (
                    <Typography
                      variant="caption"
                      className={cn(styles.caption, {
                        [styles.captionError]: values.text.length > 300,
                      })}
                    >
                      {values.text.length}/300
                    </Typography>
                  ),
                }}
                controlProps={{ className: styles.textControl }}
              />

              <div className={styles.actions}>
                <Button
                  className={styles.action}
                  size="md"
                  variant="outlined"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  className={styles.action}
                  size="md"
                  type="submit"
                  startIcon={
                    loading && (
                      <Loading size={18} color="var(--color-white-primary)" />
                    )
                  }
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    )}
    {currentStep === 2 && (
      <div className={styles.success}>
        <SuccessIcon />
        <Typography className={styles.successTitle} variant="heading2">
          Thank you!
        </Typography>
        <Typography className={styles.successText} variant="body1">
          Your feedback is appreciated
        </Typography>
        <Button className={styles.successBtn} size="md" onClick={onClose}>
          Continue
        </Button>
      </div>
    )}
  </Modal>
);

export default React.memo(ReportLessonModal);
