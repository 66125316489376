import React, { useState } from 'react';

import * as api from 'api/methods';

import { useAuthModal } from 'context/authModals';

import { logEvent } from 'helpers/logEvent';

import { convertDataToApi } from './SignInModal.helpers';

import SignInModal from './SignInModal';

const SignInModalContainer = (props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [currentEmail, setCurrentEmail] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { dispatch } = useAuthModal();

  const handleFormSubmit = async (values, { setFieldError }) => {
    try {
      setSubmitLoading(true);

      const data = convertDataToApi(values);

      await api.sendCode(data);

      setCurrentStep(2);

      logEvent({ eventType: 'Landing_Page - Enter_Code_Viewed' });

      setCurrentEmail(data.email);
    } catch (error) {
      console.warn(error);

      setFieldError('email', error.message);
    }

    setSubmitLoading(false);
  };

  const handleBackClick = () => {
    setCurrentStep(1);
  };

  const handleSignUpClick = () => {
    logEvent({ eventType: 'SignUp_Clicked' });

    dispatch({
      type: 'OPEN_MODAL',
      payload: { currentModal: 'SIGNUP_MODAL', currentModalData: null },
    });
  };

  return (
    <SignInModal
      {...props}
      submitLoading={submitLoading}
      currentStep={currentStep}
      currentEmail={currentEmail}
      onFormSubmit={handleFormSubmit}
      onBackClick={handleBackClick}
      onSignUpClick={handleSignUpClick}
    />
  );
};

export default React.memo(SignInModalContainer);
