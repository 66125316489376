import React from 'react';
import { useNavigate } from 'react-router-dom';

import TabsLayout from './TabsLayout';

const TabsLayoutContainer = (props) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/dashboard');
  };

  return <TabsLayout {...props} onBackClick={handleBackClick} />;
};

export default React.memo(TabsLayoutContainer);
