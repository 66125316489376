import React, { useRef, useState } from 'react';

import { MIN_CROP_ZOOM } from './AvatarCrop.constants';

import AvatarCrop from './AvatarCrop';

const AvatarCropContainer = ({ onClose, onSaveClick, ...restProps }) => {
  const cropperRef = useRef();

  const [currentZoom, setCurrentZoom] = useState(MIN_CROP_ZOOM);

  const handleCropperInitialized = (instance) => {
    cropperRef.current = instance;
  };

  const handleZoomChange = (nextZoom) => {
    const zoom = nextZoom.toFixed(4);

    setCurrentZoom(zoom);
  };

  const handleSaveClick = () => {
    cropperRef.current.getImageScaledToCanvas().toBlob(async (blob) => {
      if (onSaveClick) {
        onSaveClick(blob);
      }
    });
  };

  return (
    <AvatarCrop
      {...restProps}
      cropperRef={cropperRef}
      currentZoom={currentZoom}
      onCropperInitialized={handleCropperInitialized}
      onZoomChange={handleZoomChange}
      onClose={onClose}
      onSaveClick={handleSaveClick}
    />
  );
};

export default React.memo(AvatarCropContainer);
